import { gql } from '@eti/schema-types';
export const VERIFY_USER = gql( /* GraphQL */`
  query verifyUser {
    verifyUser {
      email
      loginType
      loginOrderNumber
      sessionOrderNumber
    }
  }
`);