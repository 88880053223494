import findKey from 'lodash/findKey';

const getRoute = (route) => window.spa_variables.routes[route];

export const routes = {
  HOME: getRoute('home.action'),
  START: getRoute('rf.start.action'),
  RESULT: getRoute('rf.air.result.action'),
  YOUR_SELECTED_TRIP: getRoute('rf.your-selected-trip.action'),
  NEW_BRAND_REDIRECTION: getRoute('rf.new-brand-redirection.action'),
  META_ENTRY: getRoute('rf.metaentry.action'), // used when sending meta-traffic straight to `/traveler-details`.
  TRAVELER_DETAILS: getRoute('rf.travelerdetails.action'),
  SEATING: getRoute('rf.seating.action'),
  EXTRA_PRODUCTS: getRoute('rf.extra-products.action'),
  STEP_TWO: getRoute('rf.step.two.action'),
  STEP_THREE: getRoute('rf.step.three.action'),
  STEP_FOUR: getRoute('rf.step.four.action'),
  STEP_FIVE: getRoute('rf.step.five.action'),
  STEP_SIX: getRoute('rf.step.six.action'),
  STEP_SEVEN: getRoute('rf.step.seven.action'),
  CFC_PAGE_STEP_TWO: getRoute('rf.carrier-flight-confirmation-step-two.action'),
  PAYMENT: getRoute('rf.payment.action'),
  ORDER: getRoute('rf.order.action'),
  ORDER_STATUS: getRoute('rf.order-status.action'),
  CHALLENGE: getRoute('rf.challenge.action'),

  POSTBOOKING_AUTHENTICATION: getRoute('postbooking.authentication.action'),
  POSTBOOKING_PRODUCTS: getRoute('postbooking.products.action'),

  PROCESSING_ORDER: getRoute('rf.payment.processing-order.action'),
  PROCESSING_PIX: getRoute('rf.payment.processing-pix.action'),
  PROCESSING_UPI: getRoute('rf.payment.processing-upi.action'),
  PAYMENT_LINK: getRoute('rf.payment-link.action'),
  PAYMENT_CASCADING_TRANSITION: getRoute('rf.payment-transition-page.action'),
  CONSENT: getRoute('consent.action'),
  ANCILLARY_STORE: getRoute('rf.ancillarystore.action'),
  RECEIPT_PDF: getRoute('rf.receiptpdf.action'),
  INVOICE_RECEIPT_PDF: getRoute('rf.invoice-receipt-pdf.action'),
  CORONA: getRoute('rf.corona-virus.action'),
  START_PAGE_IFRAME: getRoute('rf.htmlcontentiframe.action'),
  DESTINATION: getRoute('rf.destination.action'),

  ORDER_AUTHENTICATION: getRoute('rf.order.authentication.action'),
  ORDER_LIST: getRoute('rf.order-list.action'),
  ORDER_DETAILS: getRoute('rf.order-details.action'),
  ORDER_DETAILS_MANAGE_TRIP: getRoute('rf.order-details.manage-trip.action'),
  ORDER_DETAILS_EXTRA_PRODUCTS: getRoute('rf.order-details.extra-products.action'),
  ORDER_DETAILS_CONTACT_US: getRoute('rf.order-details.contact-us.action'),
  ORDER_DETAILS_CHECK_IN: getRoute('rf.order-details.check-in.action'),
  ORDER_DETAILS_TRAVEL_INFORMATION: getRoute('rf.order-details.travel-information.action'),
  CHANGE_TRIP: getRoute('rf.change-trip.action'),

  ABOUT_US: getRoute('rf.about-us.action'),
  COOKIES: getRoute('rf.cookies.action'),
  TERMS_AFTER_BOOKING: getRoute('rf.terms-after-booking.action'),
  REFUNDS: getRoute('rf.refunds.action'),
  TRUSTED_CHOICE: getRoute('rf.trusted-choice.action'),
  IMPRESSUM: getRoute('rf.impressum.action'),
  MOBILE_APP_DOWNLOAD: getRoute('rf.mobile-app-download.action'),
  PACKAGE_TRAVEL_INFO: getRoute('rf.info-about-package-holidays.action'),
  PRICE_DROP_PROTECTION: getRoute('rf.pricedropprotection.action'),
  TRAVEL_INSURANCE: getRoute('rf.travel-insurance.action'),
  SELF_TRANSFER: getRoute('rf.self-transfer.action'),
  SUSTAINABILITY: getRoute('rf.sustainability.action'),
  CANCELLATION_PROTECTION: getRoute('rf.cancellation-protection.action'),
  CONNECTION_GUARANTEE: getRoute('rf.connection-guarantee.action'),
  BAGGAGE_INSURANCE: getRoute('rf.baggage-insurance.action'),
  CLOSED_DOWN_BRAND: getRoute('rf.closed-down-brand.action'),
  DISPUTE_RESOLUTION_PROCEDURE: getRoute('rf.dispute-resolution-procedure.action'),

  CONTACT: getRoute('rf.contact.action'),
  CONTACT_BCOM_BUSINESS: getRoute('rf.bcombusiness.contact-us.action'),

  CARRIER_FLIGHT_CONFIRMATION: getRoute('rf.carrier-flight-confirmation.action'),

  TRAVEL_CONDITIONS: getRoute('rf.travel-conditions.action'),
  TRAVEL_CONDITIONS_BCOM_BUSINESS: getRoute('rf.bcombusiness.travel-conditions.action'),
  TRAVEL_CONDITIONS_EXTERNAL: getRoute('rf.travel-conditions-external.action'),
  CMS_CONTENT: getRoute('rf.cms-content.action'),
  PRIVACY_POLICY: getRoute('rf.privacy-policy.action'),
  PRIVACY_POLICY_EXTERNAL: getRoute('rf.privacy-policy-external.action'),

  MOBILE_TRAVEL_PLAN_LOGIN: getRoute('rf.mobile-travel-plan.login.action'),
  MOBILE_TRAVEL_PLAN_FLIGHT_STATUS: getRoute('rf.mobile-travel-plan.flight-status.action'),
  MOBILE_TRAVEL_PLAN: getRoute('rf.mobile-travel-plan.action'),
};

export const endpoints = {
  CONFIRMATION_GENERATE: '/receipt.generate.confirmation.action',
  CONFIRMATION_RESEND: '/rpc.confirmation.resend.action',
  CONSENT_SEND: '/rpc.sendconsentemail.action',
  DESTINATION_PAGE_GET_TEXTS: '/rpc.ibeclient.destinationpage.texts.action',
  ORDERPAGE_GET_TEXTS: '/rpc.ibeclient.orderpage.texts.action',
  PAYMENT_GET_TEXTS: '/rpc.ibeclient.paymentpage.texts.action',
  MOBILE_TRAVEL_PLAN: '/rpc.mobiletravelplan.action',
  AVAILABLE_FAQS: '/rpc.ibeclient.availableFaqs.action',
  TRAVELER_DETAILS_TEXTS: '/rpc.ibeclient.travelerdetailspage.texts.action',
};

export const showCartInHeaderRoutes = [routes.TRAVELER_DETAILS];

export const getRouteNameByPathName = (pathName) => findKey(routes, (route) => pathName === route);
