import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue } from 'redux-form';
import SecondBagApplySelectionToAll from '../components/SecondBagApplySelectionToAll';
const onChangeAll = (dispatch, _ref) => {
  let {
    form,
    firstTravelerSelectionValue,
    productName,
    setSecondBagValueinCheckedBag,
    setSecondBagValueinSecondBag,
    secondBagTravelers
  } = _ref;
  return checkboxValueAll => {
    dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, checkboxValueAll));
    const selectionValue = checkboxValueAll ? Boolean(firstTravelerSelectionValue) : null;
    secondBagTravelers === null || secondBagTravelers === void 0 || secondBagTravelers.slice(1).forEach(traveler => {
      setSecondBagValueinCheckedBag === null || setSecondBagValueinCheckedBag === void 0 || setSecondBagValueinCheckedBag(form, traveler, selectionValue);
      setSecondBagValueinSecondBag === null || setSecondBagValueinSecondBag === void 0 || setSecondBagValueinSecondBag(form, traveler, selectionValue);
      if (firstTravelerSelectionValue !== null || !checkboxValueAll) {
        dispatch(changeFormValue(form, `${productName}.isSelectedSecondBaggage-traveler-${traveler === null || traveler === void 0 ? void 0 : traveler.id}`, checkboxValueAll ? firstTravelerSelectionValue : false));
      }
    });
  };
};
const mapDispatchToProps = (dispatch, OwnProps) => {
  return {
    onChangeAll: onChangeAll(dispatch, OwnProps)
  };
};
const mapStateToProps = (state, _ref2) => {
  let {
    productName,
    form
  } = _ref2;
  const shouldApplySelectionToAll = formValueSelector(form)(state, `${productName}.shouldApplySelectionToAll`);
  return {
    shouldApplySelectionToAll: shouldApplySelectionToAll !== null && shouldApplySelectionToAll !== void 0 ? shouldApplySelectionToAll : false
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SecondBagApplySelectionToAll);