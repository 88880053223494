/* eslint-disable import/prefer-default-export */
import { MAIL, MAIL_CONFIRM } from './constants';
import { getInitialValues as getInitialValuesCountry } from './country';
import { getInitialValues as getInitialValuesPhone } from './phone';
import { getInitialValues as getInitialValuesNewsletter } from './newsletter';
export const getInitialValues = function (name, _temp) {
  let {
    mail = '',
    mailConfirm = '',
    country = '',
    phone = '',
    newsletter = '',
    phoneCountry = ''
  } = _temp === void 0 ? {} : _temp;
  return {
    [name]: {
      [MAIL]: mail,
      [MAIL_CONFIRM]: mailConfirm,
      ...getInitialValuesCountry(country),
      ...getInitialValuesPhone(phone, phoneCountry),
      ...getInitialValuesNewsletter(newsletter)
    }
  };
};