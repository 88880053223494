import difference from 'lodash/difference';
import { SEATING_PREFERENCE_OPTIONS } from './constants';
const getOrderedTravelers = (travelers, currentTravelerId) => {
  const index = travelers.findIndex(traveler => traveler.id === currentTravelerId);
  return index === -1 ? travelers : [...travelers.slice(index), ...travelers.slice(0, index)];
};
export const isSegmentAvailable = segment => {
  const isSeatPreferenceAvailable = segment.__typename && (segment.__typename === SEATING_PREFERENCE_OPTIONS.SELL_SEATING_BESIDE || segment.__typename === SEATING_PREFERENCE_OPTIONS.SELL_SEATING_PREFERENCE);
  return Boolean(segment.mainDeck || segment.lowerDeck || segment.upperDeck || isSeatPreferenceAvailable);
};
export const getIdOfFirstUnselectedTraveler = (travelers, selection, currentTravelerId) => {
  if (!Array.isArray(travelers) || travelers.length === 0) {
    return null;
  }
  if (!selection) {
    return travelers[0].id;
  }
  const orderedTravelers = getOrderedTravelers(travelers, currentTravelerId);
  const nextTraveler = orderedTravelers.find(traveler => selection.every(s => s.id !== traveler.id));
  return nextTraveler ? nextTraveler.id : null;
};
export const getTravelerIndexInArrayById = (travelers, travelerId) => travelers.findIndex(traveler => traveler.id === travelerId);
export const isFirstTraveler = (travelers, currentTravelerId) => {
  const currentTravelerIndexInArray = getTravelerIndexInArrayById(travelers, currentTravelerId);
  return currentTravelerIndexInArray === 0;
};
export const isLastTraveler = (travelers, currentTravelerId) => {
  const currentTravelerIndexInArray = getTravelerIndexInArrayById(travelers, currentTravelerId);
  return currentTravelerIndexInArray === travelers.length - 1;
};
export const getNextTravelerId = (travelers, currentTravelerId) => {
  if (!Array.isArray(travelers) || travelers.length === 0) {
    return null;
  }
  const currentTravelerIndexInArray = getTravelerIndexInArrayById(travelers, currentTravelerId);
  const nextTravelerIndexInArray = currentTravelerIndexInArray + 1;
  return travelers[nextTravelerIndexInArray] ? travelers[nextTravelerIndexInArray].id : null;
};
export const getPreviousTravelerId = (travelers, currentTravelerId) => {
  if (!Array.isArray(travelers) || travelers.length === 0) {
    return null;
  }
  const currentTravelerIndexInArray = getTravelerIndexInArrayById(travelers, currentTravelerId);
  const previousTravelerIndexInArray = currentTravelerIndexInArray - 1;
  return travelers[previousTravelerIndexInArray] ? travelers[previousTravelerIndexInArray].id : null;
};
export const getSegmentTravelers = (travelers, flattenedBounds, segmentIndex) => {
  if (!Array.isArray(travelers) || !Array.isArray(flattenedBounds)) {
    return [];
  }
  const segment = flattenedBounds.find(bound => bound.boundSegmentIndex === segmentIndex);
  if (!segment || !segment.travelerIds) {
    return [];
  }
  const travelerIdsSet = new Set(segment.travelerIds.map(id => Number(id)));
  const filteredTravelers = travelers.filter(_ref => {
    let {
      id
    } = _ref;
    return travelerIdsSet.has(Number(id));
  });
  return filteredTravelers;
};
export const getNonBookedTravelerSeats = (segment, totalSelectedSeats) => {
  if (!segment || !segment.travelerIds || segment.travelerIds.length === 0) {
    return [];
  }
  if (!totalSelectedSeats) {
    return segment.travelerIds;
  }
  const currentSelectedSegment = totalSelectedSeats.find(selectedSeatsBound => selectedSeatsBound.boundSegmentIndex === segment.boundSegmentIndex);
  const mapCurrentSelectedSegment = (currentSelectedSegment === null || currentSelectedSegment === void 0 ? void 0 : currentSelectedSegment.travelerIds) || [];
  if (!currentSelectedSegment) {
    return segment.travelerIds;
  }
  const travelerIds = segment.travelerIds.map(Number);
  return difference(travelerIds, mapCurrentSelectedSegment);
};