import AllInclusiveProtection, {
  allInclusiveProtection,
} from './manulife/all-inclusive-protection';
import TripCancellationProtection, {
  tripCancellationProtection,
} from './manulife/trip-cancellation-protection';

export const ALL_INCLUSIVE_PROTECTION = {
  ...allInclusiveProtection,
  component: AllInclusiveProtection,
};

export const TRIP_CANCELLATION_PROTECTION = {
  ...tripCancellationProtection,
  component: TripCancellationProtection,
};
