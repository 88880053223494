import ProductModal from './common/components/ProductModal';
import ProductBundle from './ancillary-bundle/containers/ProductBundleContainer';
import AirHelp, { airHelp } from './air-help';
import MutuallyExclusive, { mutuallyExclusive } from './mutually-exclusive';
import CancelForAnyReason, { cancelForAnyReason } from './cancel-for-any-reason';
import BaggageService, { baggageService } from './baggage-service';
import CabinBaggagePerTraveler, { cabinBaggagePerTraveler } from './cabin-baggage-per-traveler';
import secondBaggagePerTraveler from './second-bagagge-per-traveler';
import CancellationGuarantee, { cancellationGuarantee } from './cancellation/guarantee';
import CancellationInsideEu, { cancellationInsideEu } from './cancellation/inside-eu';
import CancellationOutsideEu, { cancellationOutsideEu } from './cancellation/outside-eu';
import { checkInBaggage, CheckInBaggage, CheckInBaggagePersuasionModal, NotAvailableCheckInBaggage } from './check-in-baggage';
import { climateCompensation, ClimateCompensationNotification } from './climate-compensation';
import CancelTwentyFourHours, { cancelTwentyFourHours } from './cancel-twenty-four-hours';
import FlexibleTicket, { flexibleTicket } from './flexible-ticket';
import TravelInsuranceCoverGenius, { travelInsuranceCoverGenius } from './travel-insurance-cover-genius';
import Meal, { meal } from './meal';
import MobileTravelPlan, { mobileTravelPlan } from './mobile-travel-plan';
import OnlineCheckIn, { onlineCheckIn } from './online-check-in';
import { ancillaryBundle } from './ancillary-bundle';
import { ProductCard, ProductCardBlock, ProductCardList } from './product-card';
import ProductIcon from './common/components/ProductIcon';
import ProductModalHeader from './common/components/ProductModalHeader';
import BaggagePerTraveler from './common/components/baggage-per-traveler/components/BaggagePerTraveler';
import SeatingBesides, { seatingBesides } from './seating-besides';
import SeatMap, { seatMap } from './seat-map';
import { seatMap as seatMapDBF } from './seat-map-v2';
import SeatMapContent from './seat-map-v2/containers/SeatMapContentContainer';
import SeatMapDialog from './seat-map/components/SeatMapDialog';
import ServicePackage, { servicePackage } from './service-package';
import SingleChoice, { singleChoice } from './single-choice';
import Sms, { sms } from './sms';
import TravelDocsPost, { travelDocsPost } from './travel-docs-post';
import TravelInsuranceInsideEu, { travelInsuranceInsideEu } from './travel-insurance/inside-eu/index';
import TravelInsuranceOutsideEu, { travelInsuranceOutsideEu } from './travel-insurance/outside-eu/index';
import ConnectionGuarantee, { connectionGuarantee } from './connection-guarantee';
import TripCancellationProtection, { tripCancellationProtection } from './manulife/trip-cancellation-protection';
import AllInclusiveProtection, { allInclusiveProtection } from './manulife/all-inclusive-protection';
import SimpleVisa, { simpleVisa } from './simple-visa';
import Platinum, { platinum } from './platinum';
import SeatMapModalProvider, { useSeatMapModal } from './common/context/SeatMapModalContext';
import Co2Compensation, { co2Compensation } from './co2-compensation';
import * as productItems from './products';
import { getProductPictogramId } from './common/utils/icon/getProductImageryIds';
import BankruptcyInsuranceCoverGenius, { bankruptcyInsuranceCoverGenius } from './bankruptcy-insurance-cover-genius';
import CancellationInsuranceCoverGenius, { cancellationInsuranceCoverGenius } from './cancellation-insurance-cover-genius';
import ComprehensiveInsuranceCoverGenius, { comprehensiveInsuranceCoverGenius } from './comprehensive-insurance-cover-genius';
import FastTrack, { fastTrack } from './fast-track';
import { ProductCardLayoutType } from './common/models/product';
import QPass, { qPass } from './q-pass';
const ClimateCompensation = () => null;
const productIds = {
  ...Object.values({
    ...productItems
  }).reduce((acc, val) => ({
    ...acc,
    [val.id]: val.name
  }), {})
};
export { airHelp, AirHelp, allInclusiveProtection, AllInclusiveProtection, ProductBundle, ancillaryBundle, BaggagePerTraveler, baggageService, BaggageService, bankruptcyInsuranceCoverGenius, BankruptcyInsuranceCoverGenius, cabinBaggagePerTraveler, CabinBaggagePerTraveler, cancelForAnyReason, CancelForAnyReason, cancellationGuarantee, CancellationGuarantee, cancellationInsideEu, CancellationInsideEu, cancellationInsuranceCoverGenius, CancellationInsuranceCoverGenius, cancellationOutsideEu, CancellationOutsideEu, checkInBaggage, CheckInBaggagePersuasionModal, climateCompensation, ClimateCompensation, ClimateCompensationNotification, comprehensiveInsuranceCoverGenius, ComprehensiveInsuranceCoverGenius, connectionGuarantee, ConnectionGuarantee, cancelTwentyFourHours, CancelTwentyFourHours, co2Compensation, Co2Compensation, fastTrack, FastTrack, flexibleTicket, FlexibleTicket, getProductPictogramId, meal, Meal, mobileTravelPlan, MobileTravelPlan, mutuallyExclusive, MutuallyExclusive, NotAvailableCheckInBaggage, onlineCheckIn, OnlineCheckIn, Platinum, platinum, ProductCard, ProductCardBlock, ProductCardList, ProductIcon, productIds, productItems, ProductModalHeader, ProductModal, qPass, QPass, seatingBesides, SeatingBesides, seatMap, SeatMap, seatMapDBF, SeatMapContent, SeatMapDialog, SeatMapModalProvider, secondBaggagePerTraveler, servicePackage, ServicePackage, simpleVisa, SimpleVisa, singleChoice, SingleChoice, sms, Sms, travelDocsPost, TravelDocsPost, travelInsuranceCoverGenius, TravelInsuranceCoverGenius, travelInsuranceInsideEu, TravelInsuranceInsideEu, travelInsuranceOutsideEu, TravelInsuranceOutsideEu, tripCancellationProtection, TripCancellationProtection, useSeatMapModal };
export const AIR_HELP = {
  ...airHelp,
  component: AirHelp
};
export const BAGGAGE_SERVICE = {
  ...baggageService,
  component: BaggageService
};
export const BANKRUPTCY_INSURANCE_COVER_GENIUS = {
  ...bankruptcyInsuranceCoverGenius,
  component: BankruptcyInsuranceCoverGenius
};
export const CABIN_BAGGAGE_PER_TRAVELER = {
  ...cabinBaggagePerTraveler,
  component: CabinBaggagePerTraveler
};
export const CHECK_IN_BAGGAGE = {
  ...checkInBaggage,
  component: CheckInBaggage
};
export const CANCEL_FOR_ANY_REASON = {
  ...cancelForAnyReason,
  component: CancelForAnyReason
};
export const CANCELLATION_GUARANTEE = {
  ...cancellationGuarantee,
  component: CancellationGuarantee
};
export const CANCELLATION_INSIDE_EU = {
  ...cancellationInsideEu,
  component: CancellationInsideEu
};
export const CANCELLATION_INSURANCE_COVER_GENIUS = {
  ...cancellationInsuranceCoverGenius,
  component: CancellationInsuranceCoverGenius
};
export const CANCELLATION_OUTSIDE_EU = {
  ...cancellationOutsideEu,
  component: CancellationOutsideEu
};
export const COMPREHENSIVE_INSURANCE_COVER_GENIUS = {
  ...comprehensiveInsuranceCoverGenius,
  component: ComprehensiveInsuranceCoverGenius
};
export const CANCEL_24_HOURS = {
  ...cancelTwentyFourHours,
  component: CancelTwentyFourHours
};
export const CO2_COMPENSATION = {
  ...co2Compensation,
  component: Co2Compensation
};
export const MOBILE_TRAVEL_PLAN = {
  ...mobileTravelPlan,
  component: MobileTravelPlan
};
export const ONLINE_CHECK_IN = {
  ...onlineCheckIn,
  component: OnlineCheckIn
};
export const SMS = {
  ...sms,
  component: Sms
};
export const TRAVEL_INSURANCE_COVER_GENIUS = {
  ...travelInsuranceCoverGenius,
  component: TravelInsuranceCoverGenius
};
export const TRAVEL_INSURANCE_INSIDE_EU = {
  ...travelInsuranceInsideEu,
  component: TravelInsuranceInsideEu
};
export const TRAVEL_INSURANCE_OUTSIDE_EU = {
  ...travelInsuranceOutsideEu,
  component: TravelInsuranceOutsideEu
};
export const SIMPLE_VISA = {
  ...simpleVisa,
  component: SimpleVisa
};
export const SEATING_BESIDES = {
  ...seatingBesides,
  component: SeatingBesides
};
export const CLIMATE_COMPENSATION = {
  ...climateCompensation,
  component: ClimateCompensation,
  notificationComponent: ClimateCompensationNotification
};
export const TRAVEL_DOCS_POST = {
  ...travelDocsPost,
  component: TravelDocsPost
};
export const SECOND_BAGGAGE_PER_TRAVELER = {
  ...secondBaggagePerTraveler,
  component: () => null
};
export const MUTUALLY_EXCLUSIVE = {
  ...mutuallyExclusive,
  component: MutuallyExclusive
};
export const FAST_TRACK = {
  ...fastTrack,
  component: FastTrack
};
export const ANCILLARY_BUNDLE = {
  ...ancillaryBundle,
  component: ProductBundle
};
export const FLEXIBLE_TICKET = {
  ...flexibleTicket,
  component: FlexibleTicket
};
export const MEAL = {
  ...meal,
  component: Meal
};
export const PLATINUM_SERVICE = {
  ...platinum,
  component: Platinum
};
export const QPASS = {
  ...qPass,
  component: QPass
};
export const SEAT_MAP = {
  ...seatMap,
  component: SeatMap
}; // TODO: "any" to be addressed when seat map is migrated to TypeScript

export const productConstants = {
  AIR_HELP,
  BAGGAGE_SERVICE,
  BANKRUPTCY_INSURANCE_COVER_GENIUS,
  CABIN_BAGGAGE_PER_TRAVELER,
  CANCEL_24_HOURS,
  CANCEL_FOR_ANY_REASON,
  CANCELLATION_GUARANTEE,
  CANCELLATION_INSIDE_EU,
  CANCELLATION_INSURANCE_COVER_GENIUS,
  CANCELLATION_OUTSIDE_EU,
  CHECK_IN_BAGGAGE,
  CLIMATE_COMPENSATION,
  COMPREHENSIVE_INSURANCE_COVER_GENIUS,
  CO2_COMPENSATION,
  FAST_TRACK,
  FLEXIBLE_TICKET,
  MEAL,
  MOBILE_TRAVEL_PLAN,
  ONLINE_CHECK_IN,
  PLATINUM_SERVICE,
  QPASS,
  SEAT_MAP,
  SEATING_BESIDES,
  SECOND_BAGGAGE_PER_TRAVELER,
  SIMPLE_VISA,
  SMS,
  TRAVEL_DOCS_POST,
  TRAVEL_INSURANCE_COVER_GENIUS,
  TRAVEL_INSURANCE_INSIDE_EU,
  TRAVEL_INSURANCE_OUTSIDE_EU
};
export { ProductCardLayoutType };