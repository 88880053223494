export const getTotalSelectedSeatsForTravelers = function (selectedSeats) {
  if (selectedSeats === void 0) {
    selectedSeats = {};
  }
  return Object.entries(selectedSeats).reduce((acc, _ref) => {
    let [key, val] = _ref;
    if (key === 'selected') {
      return acc;
    }
    const travelerIds = Object.values(val).map(traveler => traveler && Number.isInteger(traveler.id) && traveler.id).filter(id => id !== null);
    return [...acc, {
      boundSegmentIndex: key,
      travelerIds
    }];
  }, []);
};