import { getPrice, getSelected, getGraphQlSelection } from '../../single-choice/utils/selection';
import { CANCELLATION_GUARANTEE } from '../../products';
import getIcon from '../common/utils/icon';
import CancellationGuarantee from './components/CancellationGuarantee';
const formSection = CANCELLATION_GUARANTEE.name;
export const cancellationGuarantee = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'none'),
  ...CANCELLATION_GUARANTEE
};
export default CancellationGuarantee;