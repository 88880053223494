import { isSegmentAvailable } from './travelers';
export const flatBounds = function (bounds) {
  if (bounds === void 0) {
    bounds = [];
  }
  return bounds.flatMap(segments => segments.sellSeatMapSegments.map(segment => ({
    ...segment,
    boundIndex: segments.boundIndex,
    boundSegmentId: [segments.boundIndex, segment.boundSegmentIndex],
    isAvailable: isSegmentAvailable(segment)
  })));
};