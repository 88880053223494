import { breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { useEffect, useState } from 'react';
import { useProperty } from '@eti/providers';
import ErrorPage from '../../status-pages/components/ErrorPage';

const iFrameWrapperStyles = css`
  display: flex;
  justify-content: center;
`;

// Recommended maximum width of the iframe: 1028px
// Desktop/mobile breakpoint: 768px
const iFrameStyles = css`
  height: 100vh;
  margin: auto;
  max-width: 1028px;

  @media (max-width: ${breakpoints._768}) {
    width: 768px;
  }
`;

const CFCIframePage = () => {
  // Build the confirmationURL using carrierSessionToken depending on the open mode
  // (iFrame vs popup). carrierSessionToken field provided by EFOE would contain
  // x-session-token. Since redirection would be to the ETG web app the
  // URL would be created on the ETG side.
  // ● Implement iFrame to open the Ryanair page using confirmationURL built in the
  // previous step. The page should be loaded between return from PTC and call to SP
  // ● Implement the endpoint or event handler to handle the telemetry messages from the
  // Ryanair confirmation page.
  // ○ Call SP if success (session state REDIRECT for iFrame or status=200 for popup)
  // ○ Fail transaction in case of failure (if UNLOADED or TIMEOUT)

  const { p } = useProperty();
  const isCFCEnabled = p('IbeClient.CarrierFlightConfirmation.Enabled');

  const [shouldShowIFrame, setShouldShowIFrame] = useState(false);

  // We are waiting for confirmationURL from back-end.
  const confirmationURL = '';
  const shouldRenderIFrame = async () => {
    const response = await fetch(confirmationURL);

    return response.status === 200 ? setShouldShowIFrame(true) : setShouldShowIFrame(false);
  };

  shouldRenderIFrame();

  // Telemetry fro FR documentation for the iframe
  // window.addEventListener('message', (message) => {
  //   if (message.data.type === 'RYANAIR_TELEMETRY') {
  //     // do something
  //   }
  // });
  // data will include type and payload fields following below model:
  // {
  //   type: 'RYANAIR_TELEMETRY',
  //   payload: {
  //   session: string,
  //   state: 'LOADED' | 'SESSION_ERROR' | 'UNLOADED' | 'TIMEOUT' | 'REDIRECT',
  //   status?: 200 | 440
  //   }
  // }

  // Maybe we need to add 'session: string' as argument before state
  const handleRyanairTelemetryEvent = (state: string, status?: number) => {
    // Loaded event will be called once the page is opened and flights data loaded successfully. Payload will contain the following parameters:
    if (state === 'LOADED') {
      // Do we need this?
    }

    // Session error event will be called once the page is opened and flights data loaded unsuccessfully
    // or page opened without session query param. Payload will contain parameters session and state('SESSION_ERROR').
    if (state === 'SESSION_ERROR') {
      // waiting response from Lina and Felix on how to handle this
    }

    // Redirect(confirm flight) event will be called after Confirm flights CTA will be clicked
    // and user will be redirected to Success page.
    // Payload will contain parameters: session, state('REDIRECT'), status(200 number)
    if (state === 'REDIRECT' && status === 200) {
      // Send user to pending or confirmation page
    }

    // Unloaded event will be called if the iframe is closed. Payload will contain the parameters: state('UNLOADED')
    if (state === 'UNLOADED') {
      // Do we need this?
    }

    // Session timeout event will be called if the session timeout on the flights confirmation page.
    // Payload will contain parameters: session, state('TIMEOUT)
    if (state === 'TIMEOUT') {
      // Cancel payment and redirect user to Result page.
      // Waiting Lina and Felix if we need to display the error component in-between
    }

    // Redirect event will be called after CTA will be clicked on the session timeout popup
    // and user will be redirected to Session timeout page.
    // Payload will contain the parameters: session, state('REDIRECT'), status(440 number)
    if (state === 'REDIRECT' && status === 440) {
      // Do we need this?
    }
  };

  useEffect(() => {
    const eventListener = (message: MessageEvent<any>) => {
      if (message.data.type === 'RYANAIR_TELEMETRY') {
        handleRyanairTelemetryEvent(message.data.payload.state, message.data.payload.status);
      }
    };

    window.addEventListener('message', eventListener);

    return () => {
      window.removeEventListener('message', eventListener);
    };
  }, []);

  return isCFCEnabled && shouldShowIFrame ? (
    <div className={iFrameWrapperStyles}>
      <iframe className={iFrameStyles} src={confirmationURL} title="carrier flight confirmation" />
    </div>
  ) : (
    <ErrorPage />
  );
};

export default CFCIframePage;
