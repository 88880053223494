import { useEffect, useLayoutEffect, useRef, useState, MutableRefObject } from 'react';
import { breakpoints, Button, Dialog, Input, Stack, vars } from '@etg/wings';
import { useProperty, useTranslation, useValidation } from '@eti/providers';
import { css, cx } from '@eti/styles';
import { Field as ReduxFormField, WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { TripType } from '@eti/schema-types';
import Media from 'react-media';
import format from 'date-fns/format';
import { useOnClickOutside } from '../../../common/custom-hooks/useOnClickOutside';
import { DEPARTURE_DATE, RETURN_DATE, SINGLE_BOUND } from '../constants/formFieldNames';
import { formatGetDate, formatSetDate } from '../utils/format';
import {
  isDepartureDateValid,
  isReturnDateValid,
  generateDurationText,
} from '../utils/calendarDateUtils';
import scrollToElementBottom from '../../../utils/scrollToElementBottom';
import Calendar from './Calendar';
import MobileCalendar from './MobileCalendar';

const datesWrapperStyles = css`
  flex: 1;
  position: relative;
`;

const calendarWrapperStyles = (isMultiStop: boolean) => css`
  margin-top: -1px;
  ${isMultiStop && 'inset-inline-end: 0'};
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 9;

  @media (min-width: ${breakpoints._768}) and (max-width: ${breakpoints._970}) {
    min-width: 320px;
  }
`;

const elevateFocusedInputStyles = css`
  &&:focus-within {
    z-index: 10;
  }
`;

const inputStyles = css`
  && input {
    caret-color: transparent;
    cursor: default;
  }
`;

const dialogStyles = css`
  & > * > :last-child {
    margin-top: auto;
  }
`;

const datesButtonStyles = css`
  align-items: center;
  appearance: none;
  background-color: #fff;
  border-color: ${vars.colors.inputs.border};
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  color: inherit;
  display: flex;
  font-family: inherit;
  font-size: 1rem;
  height: 3rem;
  justify-content: space-between;
  outline: none;
  padding-inline: 12px 10px;
  text-align: start;
  width: 100%;

  &[disabled] {
    background-color: #f8f8f8;
    cursor: not-allowed;
  }

  &:focus {
    border-inline-end: 1px solid ${vars.colors.inputs.main};
    border-radius: 3px;
  }

  & *:not(:last-child) &:focus {
    padding-inline-end: 0;
  }
`;

const buttonsDatesWrapperStyles = css`
  & > *:first-child .calendarTrigger {
    border-end-start-radius: 3px;
    border-start-start-radius: 3px;
  }

  & > *:last-child .calendarTrigger {
    border-end-end-radius: 3px;
    border-start-end-radius: 3px;
  }

  & > *:not(:last-child) .calendarTrigger {
    border-inline-end: none;
    padding-inline-end: 1px;
  }
`;

const buttonWrapperStyles = css`
  display: flex;
  position: relative;

  &::before {
    border-color: ${vars.colors.inputs.focus};
    border-radius: 6px;
    border-style: solid;
    border-width: 3px;
    content: '';
    height: calc(100% + 6px);
    left: -3px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: -3px;
    transition: opacity 0.2s ease-in-out;
    width: calc(100% + 6px);
  }

  &:focus-within {
    z-index: 1;
  }

  &:focus-within::before {
    opacity: 1;
  }
`;

const datesPlaceholderStyles = css`
  color: #737373;
`;

const dialogFooterDividerStyles = css`
  background-color: ${vars.colors.inputs.main};
  height: 1px;
  margin-inline: -16px;
  transform: translateY(-15px);
  width: calc(100% + 32px);
`;

interface DateInputTypes {
  input: WrappedFieldInputProps;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  meta: WrappedFieldMetaProps;
  name: string;
}

const DateInput = ({ input, inputRef, meta, name, ...rest }: DateInputTypes) => {
  const hasError = Boolean(meta.invalid && !meta.active && (input.value !== '' || meta.touched));
  return (
    <Input
      {...rest}
      {...input}
      autoComplete="off"
      data-testid={`${name}-input`}
      hasError={hasError}
      id={name}
      inputMode="none"
      ref={inputRef}
    />
  );
};

interface ConnectedDateInputProps {
  className?: string;
  dateFormat?: string;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  name: string;
  onFocus?: any;
  placeholder?: string;
  validationRuleName: string;
  onClick?: () => void;
}

const ConnectedDateInput = ({
  dateFormat,
  inputRef,
  name,
  onFocus,
  validationRuleName,
  ...rest
}: ConnectedDateInputProps) => {
  const validate = useValidation(validationRuleName);

  return (
    <ReduxFormField
      component={DateInput}
      format={formatGetDate(dateFormat)}
      inputRef={inputRef}
      name={name}
      normalize={formatSetDate}
      onFocus={onFocus}
      props={{ name } as any}
      validate={validate}
      {...rest}
    />
  );
};

export interface DatesProps {
  dateFormat?: string;
  daysFromTodayForValidDepartureDate?: number;
  departureDate?: Date | string;
  departureDateName: string;
  previousBoundDepartureDate?: Date | string;
  returnDate?: Date | string;
  selectedTripType?: TripType;
  setDepartureDate?: (value: string) => void;
  setNextBoundsDates?: (...values: any) => void;
  setReturnDate?: (value: string) => void;
  origin?: string;
  destination?: string;
}

const Dates = ({
  departureDate,
  departureDateName,
  dateFormat,
  daysFromTodayForValidDepartureDate,
  returnDate,
  selectedTripType,
  setDepartureDate,
  setNextBoundsDates,
  setReturnDate,
  previousBoundDepartureDate,
  origin,
  destination,
}: DatesProps) => {
  const { t } = useTranslation();
  const [currentField, setCurrentField] = useState<
    typeof DEPARTURE_DATE | typeof RETURN_DATE | null
  >(null);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { p } = useProperty();

  const datesWrapperRef = useRef<HTMLDivElement>(null);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const departureInputRef = useRef<HTMLInputElement>(null);
  const returnInputRef = useRef<HTMLInputElement>(null);
  const mobileCalendarDialogRef = useRef<HTMLDivElement>(null);
  const isMultiStop = selectedTripType === TripType.MultiStop;
  const isPriceCalendarMobileEnabled = p('IbeClient.Price.Calendar.Mobile.Enabled');
  const hasReturnDate = selectedTripType
    ? [
        TripType.Return,
        TripType.OpenJawDouble,
        TripType.OpenJawSingleDestination,
        TripType.OpenJawSingleOrigin,
      ].includes(selectedTripType)
    : false;

  useLayoutEffect(() => {
    if (calendarWrapperRef.current && isCalendarVisible) {
      const elementRectData = calendarWrapperRef.current.getBoundingClientRect();
      const elementBottomPosition = elementRectData.y + elementRectData.height;
      const shouldScrollElementIntoView = elementBottomPosition >= window.innerHeight;
      if (shouldScrollElementIntoView) {
        scrollToElementBottom(elementBottomPosition);
      }
    }
  }, [isCalendarVisible]);

  useEffect(() => {
    if (currentField === DEPARTURE_DATE && departureInputRef.current) {
      departureInputRef.current.focus();
    }
    if (currentField === RETURN_DATE && returnInputRef.current) {
      returnInputRef.current.focus();
    }
  }, [currentField]);

  const hideCalendar = () => {
    setCurrentField(null);
    setIsCalendarVisible(false);
  };

  useEffect(() => {
    if (hasReturnDate && isDepartureDateValid(departureDate, returnDate) && !isMobile) {
      hideCalendar();
    }
  }, [departureDate, selectedTripType, returnDate, hasReturnDate, isMobile]);

  const handleInputFocus = (field: typeof DEPARTURE_DATE | typeof RETURN_DATE) => () => {
    setCurrentField(field);
    setIsCalendarVisible(true);

    if (isMobile && departureDate) {
      setTimeout(() => {
        const captionElem = document.getElementById(
          `daypicker-custom-caption-${format(new Date(departureDate), 'yyyy-MM')}`,
        );
        if (captionElem && captionElem.offsetTop > 100) {
          mobileCalendarDialogRef.current?.parentElement?.scrollTo({
            top: Math.max(captionElem.offsetTop - 72, 0),
          });
        }
      }, 1);
    }
  };

  const handleDepartureDateChange = (day: string) => {
    if (setDepartureDate) {
      setDepartureDate(day);
    }

    if (selectedTripType === TripType.MultiStop && setNextBoundsDates) {
      setNextBoundsDates(day);
    }

    if (hasReturnDate && setReturnDate) {
      setReturnDate('');
      setCurrentField(RETURN_DATE);
    }

    if (!hasReturnDate && !isMobile) {
      hideCalendar();
    }
  };

  const handleReturnDateChange = (day: string) => {
    if (hasReturnDate && setReturnDate) {
      setReturnDate(day);
      setCurrentField(DEPARTURE_DATE);

      if (isReturnDateValid(day, departureDate)) {
        if (!isMobile) {
          hideCalendar();
        }
      } else if (setDepartureDate) {
        setReturnDate('');
        setDepartureDate(day);
        setCurrentField(RETURN_DATE);
      }
    }
  };

  const handleClickOutside = () => {
    if (isCalendarVisible && !isMobile) {
      setCurrentField(null);
      setIsCalendarVisible(false);
    }
  };

  useOnClickOutside(datesWrapperRef, handleClickOutside);

  const dateRange = {
    from: departureDate instanceof Date ? departureDate : undefined,
    to: returnDate instanceof Date ? returnDate : undefined,
  };

  const previousBoundDeparture =
    previousBoundDepartureDate instanceof Date ? previousBoundDepartureDate : undefined;

  return (
    <div className={datesWrapperStyles} ref={datesWrapperRef}>
      <Media
        onChange={(matches) => isPriceCalendarMobileEnabled && setIsMobile(!matches)}
        query={`(min-width: ${breakpoints._768})`}
      >
        {(matches) =>
          matches || !isPriceCalendarMobileEnabled ? (
            <>
              <Stack spacing={4}>
                <Input.Group>
                  <Stack spacing={4} style={{ width: '100%' }}>
                    <Input.Label htmlFor={departureDateName}>
                      {t('Searchform.DepartureDate.Label')}
                    </Input.Label>
                    <ConnectedDateInput
                      className={cx(
                        inputStyles,
                        currentField === DEPARTURE_DATE && elevateFocusedInputStyles,
                      )}
                      dateFormat={dateFormat}
                      inputRef={departureInputRef}
                      name={departureDateName}
                      onFocus={handleInputFocus(DEPARTURE_DATE)}
                      placeholder={t('General.Departure')}
                      validationRuleName={DEPARTURE_DATE}
                    />
                  </Stack>

                  {hasReturnDate && (
                    <Stack spacing={4} style={{ width: '100%' }}>
                      <Input.Label htmlFor={`${SINGLE_BOUND}.${RETURN_DATE}`}>
                        {t('Searchform.ReturnDate.Label')}
                      </Input.Label>
                      <ConnectedDateInput
                        className={cx(
                          inputStyles,
                          currentField === RETURN_DATE && elevateFocusedInputStyles,
                        )}
                        dateFormat={dateFormat}
                        inputRef={returnInputRef}
                        name={`${SINGLE_BOUND}.${RETURN_DATE}`}
                        onFocus={handleInputFocus(RETURN_DATE)}
                        placeholder={t('General.Return')}
                        validationRuleName={RETURN_DATE}
                      />
                    </Stack>
                  )}
                </Input.Group>
              </Stack>

              {isCalendarVisible && (
                <div className={calendarWrapperStyles(isMultiStop)} ref={calendarWrapperRef}>
                  <Calendar
                    currentField={currentField}
                    daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
                    destination={destination}
                    onDepartureDateChange={handleDepartureDateChange}
                    onReturnDateChange={handleReturnDateChange}
                    origin={origin}
                    previousBoundDepartureDate={previousBoundDeparture}
                    range={dateRange}
                    selectedTripType={selectedTripType}
                  />
                </div>
              )}
            </>
          ) : (
            <>
              <Stack spacing={4}>
                <Input.Group className={buttonsDatesWrapperStyles}>
                  <Stack spacing={4} style={{ width: '100%' }}>
                    <Input.Label htmlFor={departureDateName}>
                      {t('Searchform.DepartureDate.Label')}
                    </Input.Label>
                    <div className={buttonWrapperStyles}>
                      <button
                        className={cx(datesButtonStyles, 'calendarTrigger')}
                        onClick={handleInputFocus(DEPARTURE_DATE)}
                        type="button"
                      >
                        {dateRange.from ? (
                          formatGetDate(dateFormat)(dateRange.from)
                        ) : (
                          <span className={datesPlaceholderStyles}>{t('General.Departure')}</span>
                        )}
                      </button>
                    </div>
                  </Stack>

                  {selectedTripType !== TripType.MultiStop &&
                    selectedTripType !== TripType.OneWay && (
                      <Stack spacing={4} style={{ width: '100%' }}>
                        <Input.Label htmlFor={`${SINGLE_BOUND}.${RETURN_DATE}`}>
                          {t('Searchform.ReturnDate.Label')}
                        </Input.Label>
                        <div className={buttonWrapperStyles}>
                          <button
                            className={cx(datesButtonStyles, 'calendarTrigger')}
                            onClick={handleInputFocus(RETURN_DATE)}
                            type="button"
                          >
                            {dateRange.to ? (
                              formatGetDate(dateFormat)(dateRange.to)
                            ) : (
                              <span className={datesPlaceholderStyles}>{t('General.Return')}</span>
                            )}
                          </button>
                        </div>
                      </Stack>
                    )}
                </Input.Group>
              </Stack>

              <Dialog
                className={dialogStyles}
                footer={
                  <>
                    <div className={dialogFooterDividerStyles} />
                    <Stack>
                      <Stack spacing={4}>
                        <Input.Group>
                          <Stack spacing={4} style={{ width: '100%' }}>
                            <Input.Label htmlFor={departureDateName}>
                              {t('Searchform.DepartureDate.Label')}
                            </Input.Label>
                            <ConnectedDateInput
                              className={cx(
                                inputStyles,
                                currentField === DEPARTURE_DATE && elevateFocusedInputStyles,
                              )}
                              dateFormat={dateFormat}
                              inputRef={departureInputRef}
                              name={departureDateName}
                              placeholder={t('General.Departure')}
                              validationRuleName={DEPARTURE_DATE}
                            />
                          </Stack>

                          {selectedTripType !== TripType.MultiStop &&
                            selectedTripType !== TripType.OneWay && (
                              <Stack spacing={4} style={{ width: '100%' }}>
                                <Input.Label htmlFor={`${SINGLE_BOUND}.${RETURN_DATE}`}>
                                  {t('Searchform.ReturnDate.Label')}
                                </Input.Label>
                                <ConnectedDateInput
                                  className={cx(
                                    inputStyles,
                                    currentField === RETURN_DATE && elevateFocusedInputStyles,
                                  )}
                                  dateFormat={dateFormat}
                                  inputRef={returnInputRef}
                                  name={`${SINGLE_BOUND}.${RETURN_DATE}`}
                                  placeholder={t('General.Return')}
                                  validationRuleName={RETURN_DATE}
                                />
                              </Stack>
                            )}
                        </Input.Group>
                      </Stack>
                      <Button
                        data-testid="passengers-confirm"
                        onClick={hideCalendar}
                        variant="primary"
                      >
                        {generateDurationText(
                          dateRange.from,
                          dateRange.to,
                          t('Searchform.Calendar.Confirm.Singular'),
                          t('Searchform.Calendar.Confirm.Plural'),
                        )}
                      </Button>
                    </Stack>
                  </>
                }
                isOpen={isCalendarVisible}
                onDismiss={hideCalendar}
                title={t('Searchform.Calendar.DialogTitle.Text')}
              >
                <div ref={mobileCalendarDialogRef}>
                  <MobileCalendar
                    currentField={currentField}
                    daysFromTodayForValidDepartureDate={daysFromTodayForValidDepartureDate}
                    destination={destination}
                    onDepartureDateChange={handleDepartureDateChange}
                    onReturnDateChange={handleReturnDateChange}
                    origin={origin}
                    previousBoundDepartureDate={previousBoundDeparture}
                    range={dateRange}
                    selectedTripType={selectedTripType}
                  />
                </div>
              </Dialog>
            </>
          )
        }
      </Media>
    </div>
  );
};

export default Dates;
