import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { Text } from '@etg/wings';
import { generateDurationText } from '../utils/calendarDateUtils';

const wrapperStyles = css`
  background: #fff;
  padding-bottom: 16px;
  position: relative;
  text-align: center;
`;

const textStyles = css`
  color: #737373;
`;

interface CalendarFooterProps {
  from?: Date | string | null;
  to?: Date | string | null;
}

const CalendarFooter = ({ from, to }: CalendarFooterProps) => {
  const { t } = useTranslation();
  return (
    <div className={wrapperStyles}>
      <Text as="p" className={textStyles}>
        {generateDurationText(
          from,
          to,
          t('Searchform.Calendar.DaysLeft.Singular'),
          t('Searchform.Calendar.DaysLeft.Plural'),
        )}
      </Text>
    </div>
  );
};

export default CalendarFooter;
