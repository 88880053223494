import { useState, useEffect, useCallback } from 'react';
import { vars } from '@etg/wings';
import { css, cx } from '@eti/styles';
import { useLazyQuery } from '@apollo/client';

import {
  ClassNames,
  DayPicker,
  DayClickEventHandler,
  DayMouseEventHandler,
  DayModifiers,
} from 'react-day-picker';

import { useDirection, useProperty, useSiteContext } from '@eti/providers';
import formatISO from 'date-fns/formatISO';
import getDaysInMonth from 'date-fns/getDaysInMonth';
import setDate from 'date-fns/setDate';
import { GET_PRICES_PER_DAY } from '../queries';
import { tripTypes } from '../../../constants/tripTypesConstants';
import 'react-day-picker/dist/style.css';
import {
  calculateDayRange,
  calculateInitialMonth,
  calculateMask,
  isDisabled,
  isInRange,
  isMasked,
  isSelected,
  getStartOfToday,
} from '../utils/calendarDateUtils';

import { getLocale, Locale, formatWeekdayName } from '../utils/calendarLocales';
import CalendarFooter from './CalendarFooter';
import CustomDayWithPriceIndicator from './CustomDayWithPriceIndicator';

const calendarContainerStyle = css`
  background-color: #fff;
  border: 1px solid ${vars.colors.inputs.border};
  box-shadow:
    0 10px 20px rgb(0 0 0 / 4%),
    0 2px 6px rgb(0 0 0 / 4%),
    0 0 1px rgb(0 0 0 / 4%);
`;

const modifiersStyles = (isPriceCalendarEnabled: boolean) => ({
  disabled: {
    color: '#737373',
    cursor: 'default',
  },

  outside: {
    backgroundColor: '#fff',
    cursor: 'default',
  },

  inRange: {
    color: `${vars.colors.text}`,
  },

  selected: {
    backgroundColor: `${vars.colors.inputs.main}`,
    color: '#fff',
    borderRadius: isPriceCalendarEnabled ? '5px' : '3px',
    outlineOffset: '0',
  },

  masked: {
    backgroundColor: `${vars.colors.inputs.tint}`,
    color: `${vars.colors.text}`,
  },

  today: {
    fontWeight: 100,
  },
});

const classNames: ClassNames = {
  caption: css`
    color: ${vars.colors.text};
    font-size: 0.9375rem;
    position: relative;
    text-align: center;
  `,

  caption_label: css`
    color: ${vars.colors.text};
    font-size: 0.875rem;
    font-weight: 500;
    padding-top: 8px;
  `,

  nav: css`
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -4px;
    width: 100%;
  `,

  nav_button: css`
    align-items: center;
    display: flex;
    justify-content: center;

    && {
      border-radius: 3px;
      cursor: pointer;
      height: 44px;
      outline: none;
      padding: 11px;
      place-items: center;
      width: 44px;
      &&:hover {
        background-color: unset;
      }

      &&:focus,
      &&:focus-visible {
        background-color: unset;
        border: none;
        outline: 2px solid ${vars.colors.inputs.focus};
        outline-width: 2px;
      }
    }
  `,

  nav_icon: css`
    height: 14px;
    width: 14px;
  `,

  table: css`
    margin: 24px auto -8px;
    width: 100%;
  `,

  months: css`
    width: 100%;

    > div {
      margin: 0;
    }
  `,

  head: css`
    margin-bottom: 4px;
  `,

  head_cell: css`
    color: #737373;
    font-weight: 400;
    padding-bottom: 2px;
    text-transform: unset;
  `,

  day: cx(
    'notranslate',
    css`
      && {
        color: ${vars.colors.text};
        font-size: 0.875rem;
        height: 100%;
        height: var(--rdp-cell-size);
        max-width: unset;
        width: 100%;

        &&:hover {
          background-color: ${vars.colors.inputs.hover};
        }

        &&[disabled] {
          opacity: unset;
        }

        &&[disabled]:hover {
          background-color: unset;
        }

        &&:focus,
        &&:focus-visible {
          background-color: unset;
          border: none;
          border-radius: 3px;
          outline: 2px solid ${vars.colors.focusRing};
          z-index: 999;
        }
      }
    `,
  ),
};

const priceCalendarClassNames: ClassNames = {
  ...classNames,
  root: css`
    margin: 16px 4px;
  `,

  nav: cx(
    classNames.nav,
    css`
      padding: 0 6px;
    `,
  ),

  table: cx(
    classNames.table,
    css`
      border-collapse: separate;
      border-spacing: 4px;
    `,
  ),

  day: cx(
    classNames.day,
    css`
      && {
        background-color: #fafafa;
        border: 2px solid #fff;
        border-radius: 5px;
        padding: 2px;

        &&[disabled]:hover {
          background-color: #fafafa;
        }

        &&:focus,
        &&:focus-visible {
          border: 2px solid ${vars.colors.inputs.focus};
          outline: none;
        }
      }
    `,
  ),
};

export interface CalendarProps {
  range?: { from?: Date; to?: Date };
  currentField?: string | null;
  languageCode?: string;
  daysFromTodayForValidDepartureDate?: number;
  previousBoundDepartureDate?: Date;
  selectedTripType?: string;
  onDepartureDateChange: (day: string) => void;
  onReturnDateChange: (day: string) => void;
  origin?: string;
  destination?: string;
}

const Calendar = ({
  range = {},
  currentField,
  daysFromTodayForValidDepartureDate,
  previousBoundDepartureDate,
  selectedTripType,
  onDepartureDateChange,
  onReturnDateChange,
  origin,
  destination,
}: CalendarProps) => {
  const { direction } = useDirection();

  const { language } = useSiteContext();

  const { p } = useProperty();

  const isPriceCalendarEnabled = p('IbeClient.Price.Calendar.Enabled');

  const selectedMonth = calculateInitialMonth(
    getStartOfToday(),
    daysFromTodayForValidDepartureDate,
    selectedTripType === tripTypes.MULTI_STOP && !range.from
      ? previousBoundDepartureDate
      : range.from,
    range.to,
  );

  const [displayMonth, setDisplayMonth] = useState(setDate(selectedMonth, 1));

  const [getPriceData, { data, loading }] = useLazyQuery(GET_PRICES_PER_DAY);

  const [mask, setMask] = useState<{ from: Date | null; to: Date | null }>({
    from: range.from ?? null,
    to: range.to ?? null,
  });

  const [locale, setLocale] = useState<Locale>();

  useEffect(() => {
    const loadLocal = async () => {
      if (language?.locale) {
        const tempLocale = await getLocale(language.locale);
        setLocale(tempLocale);
      }
    };
    loadLocal();
  }, [language?.locale]);

  useEffect(() => {
    if (selectedTripType === tripTypes.ONE_WAY) {
      setMask({ from: range.from ?? null, to: null });
    }
  }, [range.from, selectedTripType]);

  const handleMonthChange = useCallback(
    (month: Date) => {
      if (!origin || !destination || !isPriceCalendarEnabled) {
        return;
      }
      setDisplayMonth(month);

      getPriceData({
        variables: {
          startDate: formatISO(month, { representation: 'date' }),
          origin,
          destination,
          numberOfDays: getDaysInMonth(month),
        },
      });
    },
    [destination, getPriceData, isPriceCalendarEnabled, origin],
  );

  useEffect(() => {
    handleMonthChange(displayMonth);
  }, [displayMonth, handleMonthChange]);

  const handleDayClick: DayClickEventHandler = (day, { disabled }) => {
    if (!disabled) {
      calculateDayRange(
        currentField,
        range.from,
        range.to,
        day,
        onDepartureDateChange,
        onReturnDateChange,
      );
    }
  };

  const handleDayMouseEnter: DayMouseEventHandler = (day, { disabled }) => {
    if (!disabled) {
      const newMask = calculateMask(currentField, range.from, range.to, day);
      setMask(newMask);
    }
  };

  const handleDayMouseLeave: DayMouseEventHandler = () => {
    setMask({ from: range.from ?? null, to: range.to ?? null });
  };

  const modifiers: DayModifiers = {
    selected: (day) => isSelected(day, range.from, range.to),
    inRange: (day) => isInRange(day, range.from, range.to, mask),
    disabled: (day) =>
      isDisabled(
        day,
        daysFromTodayForValidDepartureDate,
        previousBoundDepartureDate,
        selectedTripType,
      ),
    masked: (day) => isMasked(day, mask, selectedTripType, range.from, range.to),
  };

  return (
    <div className={calendarContainerStyle}>
      <DayPicker
        classNames={isPriceCalendarEnabled ? priceCalendarClassNames : classNames}
        components={{
          DayContent: isPriceCalendarEnabled
            ? (props) => (
                <CustomDayWithPriceIndicator
                  loading={loading}
                  priceCalendarData={data?.bestPricesPerDay?.bestPricesPerDay ?? undefined}
                  {...props}
                />
              )
            : undefined,
        }}
        defaultMonth={selectedMonth}
        dir={direction}
        formatters={{ formatWeekdayName }}
        locale={locale}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles(isPriceCalendarEnabled)}
        onDayClick={handleDayClick}
        onDayMouseEnter={handleDayMouseEnter}
        onDayMouseLeave={handleDayMouseLeave}
        onMonthChange={handleMonthChange}
      />
      {selectedTripType === tripTypes.RETURN && (range.from || range.to) && (
        <CalendarFooter {...mask} />
      )}
    </div>
  );
};

export default Calendar;
