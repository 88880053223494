import { useDirection, useSiteContext } from '@eti/providers';
import { constants } from '@eti/utils';
import { useEffect, useState } from 'react';
import { getProductIllustrationId, getProductPictogramId } from './icon/getProductImageryIds';
const useAsset = (productName, assetType) => {
  const {
    isRTL
  } = useDirection();
  const {
    brand: {
      code: brand
    }
  } = useSiteContext();
  const [assetUrl, setAssetUrl] = useState('');
  useEffect(() => {
    const importAsset = async () => {
      const {
        FLIGHTNETWORK,
        GOTOGATE,
        MYTRIP
      } = constants.brands;
      const brandFolder = brand === FLIGHTNETWORK || brand === GOTOGATE || brand === MYTRIP ? brand : 'default';
      const assetCode = assetType === 'illustrations' ? getProductIllustrationId(productName, isRTL) : getProductPictogramId(productName, isRTL);
      const src = await import(`@eti/assets/${assetType}/${brandFolder}/${assetCode}.svg`).catch(err =>
      // eslint-disable-next-line no-console
      console.warn(`Couldn't resolve ${assetType} path for product ${productName}. Error message: ${err}`));
      if (src) {
        setAssetUrl(src.default);
      }
    };
    importAsset();
  }, [brand, productName, isRTL, assetType]);
  return assetUrl;
};
export const useIllustration = productName => useAsset(productName, 'illustrations');
export const usePictogram = productName => useAsset(productName, 'pictograms');