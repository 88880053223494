export const TRAVELER_FORM_SECTION_NAME = 'traveler-section';
export const GENDER = 'traveler-gender';
export const FIRST_NAME = 'traveler-firstName';
export const LAST_NAME = 'traveler-lastName';
export const DATE_OF_BIRTH = 'traveler-date-of-birth-form';
export const SELF_SERVICE_REBOOKING_GENDER = 'gender';
export const SELF_SERVICE_REBOOKING_FIRST_NAME = 'firstName';
export const SELF_SERVICE_REBOOKING_LAST_NAME = 'lastName';
export const SELF_SERVICE_REBOOKING_DATE_OF_BIRTH = 'birthDate';
export const TRAVEL_DOCUMENT_ISSUING_COUNTRY = 'travelDocument-issuingCountry';
export const TRAVEL_DOCUMENT_TYPE = 'travelDocument-type';
export const TRAVEL_DOCUMENT_NUMBER = 'traveler-travel-document-number';
export const TRAVEL_CPF_NUMBER = 'traveler-travel-cpf-number';
export const TRAVEL_DOCUMENT_EXPIRY_DATE = 'traveler-travel-document-expiry-date';
export const FREQUENT_FLYER = 'frequentFlyer';
export const FREQUENT_FLYER_CARD_NUMBER = 'cardNumber';
export const FREQUENT_FLYER_CARD_TYPE = 'cardType';
export const OPTIONAL = 'OPTIONAL';
export const REQUIRED = 'REQUIRED';
export const NOT_REQUIRED = 'NOT_REQUIRED';
export const TAX_ID_INPUT = 'taxIdInput';
export const COUNTRY_CODES = {
  BR: 'BR',
  RU: 'RU'
};