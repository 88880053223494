import { useSiteContext } from '@eti/providers';
import { analytics } from '@eti/utils';
import { productItems } from '@eti/products';
import { CartData, Event, ProductItem } from '../models';
import { formatPrice, getAirProduct, getBundleProducts } from '../utils';
import { getTravelerPricesTotal } from '../../../../utils/travelers';
import { PRODUCT_CATEGORIES } from '../constants/productCategories';
import { Product } from '../../../../pages/traveler-details/models';

export const usePendingProviderDataLayerEffect = (processingOrderMessage: string) => {
  const { dataLayer, sessionStorage } = window;
  const { siteCurrency } = useSiteContext();
  const cartDataJson = sessionStorage.getItem('cartData');
  if (!cartDataJson) {
    return;
  }
  const cartData: CartData = JSON.parse(cartDataJson);
  let index = 0;
  analytics.pushEntryToDataLayer({ ecommerce: null }, false);
  const airPrice = formatPrice(
    siteCurrency?.exponent,
    getTravelerPricesTotal(cartData.travelerPrices),
  );
  const paymentMethodPrice = cartData?.selectedPaymentMethod?.price?.value
    ? cartData.selectedPaymentMethod.price.value
    : 0;
  const airItem = getAirProduct(airPrice);
  const paymentProduct = {
    item_id: parseInt(cartData?.selectedPaymentMethod?.id, 10),
    item_name: cartData?.selectedPaymentMethod?.__typename,
    index: 1,
    item_category: PRODUCT_CATEGORIES.PAYMENT,
    price: formatPrice(siteCurrency?.exponent, paymentMethodPrice),
    quantity: 1,
  };
  const totalPrice = cartData?.discountAmount
    ? cartData.totalPrice + cartData?.discountAmount
    : cartData.totalPrice;
  const event: Event = {
    event: 'pending_provider',
    ecommerce: {
      transaction_id: cartData?.transactionId,
      value: formatPrice(siteCurrency?.exponent, totalPrice + paymentMethodPrice),
      pending_provider_reason: processingOrderMessage,
      currency: siteCurrency?.code,
      discount: formatPrice(siteCurrency?.exponent, cartData?.discountAmount),
      items: [],
    },
  };
  const bundleProducts = getBundleProducts(cartData.bundles, siteCurrency, index);
  index += bundleProducts?.length;
  const productList: ProductItem[] = [
    airItem,
    paymentProduct,
    ...getBundleProducts(cartData.bundles, siteCurrency, index),
  ];
  index += productList?.length - 1;
  for (const [key, value] of Object.entries(productItems)) {
    const purchasedProduct = cartData?.products?.filter(
      (orderExtraProduct: Product) => orderExtraProduct?.id === value?.id?.toString(),
    )?.[0];

    if (purchasedProduct) {
      index += 1;
      productList.push({
        item_id: value.id,
        item_name: key,
        index,
        item_category: PRODUCT_CATEGORIES.EXTRA,
        price: formatPrice(siteCurrency?.exponent, purchasedProduct?.price),
        quantity: purchasedProduct.numberOfUnits || 1,
      });
    }
  }
  event.ecommerce.items = productList;
  const entryExistsInDataLayer = dataLayer
    ? dataLayer.some((dataLayerEntry: Event) => dataLayerEntry.event === 'pending_provider')
    : false;
  if (!entryExistsInDataLayer) {
    analytics.pushEntryToDataLayer(event, false);
  }
  sessionStorage.removeItem('discountCodeAmount');
  sessionStorage.removeItem('cartData');
};
