import {
  AIR_HELP,
  BAGGAGE_SERVICE,
  BANKRUPTCY_INSURANCE_COVER_GENIUS,
  CABIN_BAGGAGE_PER_TRAVELER,
  CANCEL_24_HOURS,
  CANCEL_FOR_ANY_REASON,
  CANCELLATION_GUARANTEE,
  CANCELLATION_INSIDE_EU,
  CANCELLATION_INSURANCE_COVER_GENIUS,
  CANCELLATION_OUTSIDE_EU,
  CHECK_IN_BAGGAGE,
  CLIMATE_COMPENSATION,
  COMPREHENSIVE_INSURANCE_COVER_GENIUS,
  FAST_TRACK,
  FLEXIBLE_TICKET,
  MEAL,
  CO2_COMPENSATION,
  MOBILE_TRAVEL_PLAN,
  ONLINE_CHECK_IN,
  SEAT_MAP,
  SECOND_BAGGAGE_PER_TRAVELER,
  SIMPLE_VISA,
  SMS,
  TRAVEL_INSURANCE_COVER_GENIUS,
  TRAVEL_INSURANCE_INSIDE_EU,
  TRAVEL_INSURANCE_OUTSIDE_EU,
  PLATINUM_SERVICE,
  QPASS,
} from '@eti/products';

import { ALL_INCLUSIVE_PROTECTION, TRIP_CANCELLATION_PROTECTION } from '../widgets/extra-products';

export const SUPPORTED_PRODUCTS = [
  AIR_HELP,
  ALL_INCLUSIVE_PROTECTION,
  BAGGAGE_SERVICE,
  BANKRUPTCY_INSURANCE_COVER_GENIUS,
  CABIN_BAGGAGE_PER_TRAVELER,
  CANCEL_24_HOURS,
  CANCEL_FOR_ANY_REASON,
  CANCELLATION_GUARANTEE,
  CANCELLATION_INSIDE_EU,
  CANCELLATION_INSURANCE_COVER_GENIUS,
  CANCELLATION_OUTSIDE_EU,
  CHECK_IN_BAGGAGE,
  CLIMATE_COMPENSATION,
  COMPREHENSIVE_INSURANCE_COVER_GENIUS,
  CO2_COMPENSATION,
  FLEXIBLE_TICKET,
  MEAL,
  MOBILE_TRAVEL_PLAN,
  ONLINE_CHECK_IN,
  QPASS,
  SEAT_MAP,
  SECOND_BAGGAGE_PER_TRAVELER,
  SIMPLE_VISA,
  SMS,
  TRAVEL_INSURANCE_COVER_GENIUS,
  TRAVEL_INSURANCE_INSIDE_EU,
  TRAVEL_INSURANCE_OUTSIDE_EU,
  TRIP_CANCELLATION_PROTECTION,
  FAST_TRACK,
  PLATINUM_SERVICE,
];
export const INCLUDED_PRODUCTS = [CABIN_BAGGAGE_PER_TRAVELER, CHECK_IN_BAGGAGE];

export const PRESELECTION_VALUES = {
  none: 'NONE',
  yes: 'YES_SELECTED',
  no: 'NO_SELECTED',
};

export const COVER_GENIUS_PRODUCTS: number[] = [
  BANKRUPTCY_INSURANCE_COVER_GENIUS.id,
  CANCELLATION_INSURANCE_COVER_GENIUS.id,
  COMPREHENSIVE_INSURANCE_COVER_GENIUS.id,
  TRAVEL_INSURANCE_COVER_GENIUS.id,
];
