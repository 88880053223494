import { AncillaryBundle } from '../../../pages/traveler-details/models';
import { PRODUCT_CATEGORIES } from './constants/productCategories';
import { AIR } from './constants/mainProducts';

export const formatPrice = (exponent: number, price = 0) => price / 10 ** exponent;

export const getAirProduct = (airPrice: number) => ({
  item_id: AIR.id,
  item_name: AIR.name,
  price: airPrice,
  item_category: PRODUCT_CATEGORIES.MAIN,
  quantity: 1,
  index: 0,
});
export const getBundleProducts = (
  bundles: AncillaryBundle[],
  siteCurrency: { exponent: number; code: string },
  currentIndex: number = 0,
) => {
  let index = 0;
  if (!bundles) {
    return [];
  }
  return bundles.map((bundle) => {
    index += 1;
    return {
      item_id: Number(bundle.selectedOption),
      item_name: bundle.title,
      index: index + currentIndex,
      item_category: PRODUCT_CATEGORIES.EXTRA,
      price: formatPrice(siteCurrency?.exponent, bundle.price),
      quantity: 1,
    };
  });
};
