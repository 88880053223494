import { css, etiColors } from '@eti/styles';
export const inputClass = css`
  margin-top: 0.25rem;
  width: 100%;

  @media (min-width: 33.125em) {
    max-width: 65%;
  }

  @media (min-width: 48em) {
    max-width: 100%;
  }

  @media (min-width: 62em) {
    max-width: 100%;
  }
`;
export const singleChoiceOptionClass = css`
  width: 50%; /* For our pal IE11. */
`;
const singleChoiceSelectionYesClass = css`
  background-color: ${etiColors.confirmation.light};
`;
const singleChoiceSelectionNoClass = css`
  background-color: ${etiColors.critical.light};

  input:checked ~ .etiCustomRadio::after {
    background-color: ${etiColors.critical.solid};
  }
`;
const singleChoiceSelectionUnselectedClass = css`
  background-color: ${etiColors.white};
`;
export const getHighlightedButtonClass = (selectedValue, option) => {
  if (option === true) {
    return selectedValue === true ? singleChoiceSelectionYesClass : singleChoiceSelectionUnselectedClass;
  }
  if (option === false) {
    return selectedValue === false ? singleChoiceSelectionNoClass : singleChoiceSelectionUnselectedClass;
  }
  return null;
};