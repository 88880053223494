import deepFilter from 'deep-filter';
import { formValueSelector } from 'redux-form';
import isPlainObj from 'is-plain-obj';
import flatMapDeep from 'lodash/flatMapDeep';
const getMarkup = (markup1, markup2) => {
  if (typeof markup1 !== 'number') {
    return markup2;
  }
  if (typeof markup2 !== 'number') {
    return markup1;
  }
  return markup1 + markup2;
};
const addPrice = (_ref, _ref2) => {
  let {
    price: price1,
    markup: markup1
  } = _ref;
  let {
    price: price2,
    markup: markup2
  } = _ref2;
  return {
    price: price1 + price2,
    markup: getMarkup(markup1, markup2)
  };
};
const getPriceRecursive = arr => arr.reduce((total, x) => {
  if (Array.isArray(x)) {
    return addPrice(total, getPriceRecursive(x));
  }
  if (isPlainObj(x) && typeof x.price !== 'number') {
    return addPrice(total, getPriceRecursive(Object.values(x)));
  }
  return addPrice(total, x);
}, {
  price: 0,
  markup: null
});
const filterValues = input => deepFilter(input, value => {
  if (Array.isArray(value) || typeof value === 'string') {
    return value.length > 0;
  }
  if (value && typeof value === 'object') {
    for (const key of Object.keys(value)) {
      return {}.hasOwnProperty.call(value, key);
    }
  }
  return value !== null;
});
export const isSelected = (name, form) => state => formValueSelector(form)(state, `${name}.value.value`) === 'true';
export const getSelection = (name, form) => state => {
  const {
    value,
    ...data
  } = formValueSelector(form)(state, name) || {};
  const getSelectedData = filterValues(Object.values(data));
  return isSelected(name, form)(state) ? getSelectedData : [];
};
export const getSelected = (name, form) => state => boundSegmentIndex => filterValues(formValueSelector(form)(state, `${name}.${boundSegmentIndex}`) || []);
export const getPrice = (name, form) => state => getPriceRecursive(getSelection(name, form)(state));
const getAllAvailableSeatsForDeck = deck => {
  const deckSeats = [];
  if (deck) {
    for (const cabin of deck.cabins) {
      for (const row of cabin.rows) {
        for (const seat of row.sellSeatMapSeats) {
          if (seat.isBookable && typeof seat.price === 'number') {
            deckSeats.push(seat);
          }
        }
      }
    }
  }
  return deckSeats;
};
const collectAllSeats = bounds => {
  let allSeats = [];
  for (const bound of bounds) {
    for (const segment of bound.sellSeatMapSegments) {
      allSeats = [...allSeats, ...getAllAvailableSeatsForDeck(segment.upperDeck), ...getAllAvailableSeatsForDeck(segment.mainDeck), ...getAllAvailableSeatsForDeck(segment.lowerDeck)];
    }
  }
  return allSeats;
};
export const getMinPrice = function (bounds) {
  if (bounds === void 0) {
    bounds = [];
  }
  const seats = collectAllSeats(bounds);
  if (seats.length === 0) {
    return 0;
  }
  return seats.reduce((cheapestSeat, currentSeat) => currentSeat.price < cheapestSeat.price ? currentSeat : cheapestSeat).price;
};
export const processSeatSelection = seatSelection => {
  const flattenArray = flatMapDeep(seatSelection, bound => Object.values(bound)).filter(selection => selection !== null);
  return flattenArray.slice(1);
};