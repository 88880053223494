import { SEAT_MAP } from '../products';
import getIcon from './utils/icon';
import { getPrice, getSelected, getSelection, isSelected } from './utils/selection';
import { getNameInitials } from './utils/getNameInitials';
import { SEATING_PREFERENCE_VALUES } from './utils/constants';
import getGraphQlSelection from './utils/getGraphQlSelection';
const formSection = SEAT_MAP.name;
export const seatMap = {
  getGraphQlSelection,
  getIcon,
  getPrice: form => state => getPrice(formSection, form)(state).price,
  getMarkup: form => state => getPrice(formSection, form)(state).markup,
  getNameInitials,
  getSelected: getSelected.bind(null, formSection),
  getSelection: getSelection.bind(null, formSection),
  isSelected: isSelected.bind(null, formSection),
  formSection,
  seatingPreferenceValues: SEATING_PREFERENCE_VALUES,
  ...SEAT_MAP
};