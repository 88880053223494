export const getPrice = function (_ref, type, pricePerTraveler) {
  let {
    sellSpecification
  } = _ref;
  if (type === void 0) {
    type = 'none';
  }
  if (type === 'none') {
    var _sellSpecification$pr, _sellSpecification$pr2;
    return (_sellSpecification$pr = sellSpecification === null || sellSpecification === void 0 || (_sellSpecification$pr2 = sellSpecification.price) === null || _sellSpecification$pr2 === void 0 || (_sellSpecification$pr2 = _sellSpecification$pr2.price) === null || _sellSpecification$pr2 === void 0 ? void 0 : _sellSpecification$pr2.value) !== null && _sellSpecification$pr !== void 0 ? _sellSpecification$pr : 0;
  }
  if (pricePerTraveler) {
    var _sellSpecification$se, _sellSpecification$se2;
    return (_sellSpecification$se = sellSpecification === null || sellSpecification === void 0 || (_sellSpecification$se2 = sellSpecification.sellPriceTravelers) === null || _sellSpecification$se2 === void 0 || (_sellSpecification$se2 = _sellSpecification$se2[0]) === null || _sellSpecification$se2 === void 0 || (_sellSpecification$se2 = _sellSpecification$se2.price) === null || _sellSpecification$se2 === void 0 || (_sellSpecification$se2 = _sellSpecification$se2.price) === null || _sellSpecification$se2 === void 0 ? void 0 : _sellSpecification$se2.value) !== null && _sellSpecification$se !== void 0 ? _sellSpecification$se : 0;
  }
  return sellSpecification.sellPriceTravelers.reduce((total, traveler) => total + traveler.price.price.value, 0);
};
export const getProduct = function (id, order, type) {
  var _order$bounds$length, _order$bounds, _product$sellSpecific, _product$configuratio;
  if (type === void 0) {
    type = 'none';
  }
  const numberOfBounds = (_order$bounds$length = order === null || order === void 0 || (_order$bounds = order.bounds) === null || _order$bounds === void 0 ? void 0 : _order$bounds.length) !== null && _order$bounds$length !== void 0 ? _order$bounds$length : 0;
  const product = order !== null && order !== void 0 && order.availableExtraProducts ? order.availableExtraProducts.find(extraProduct => extraProduct.id === id) : order;
  if (!product) {
    return null;
  }
  const travelerIds = (_product$sellSpecific = product.sellSpecification) === null || _product$sellSpecific === void 0 || (_product$sellSpecific = _product$sellSpecific.sellPriceTravelers) === null || _product$sellSpecific === void 0 ? void 0 : _product$sellSpecific.map(_ref2 => {
    let {
      travelerId
    } = _ref2;
    return travelerId;
  });
  const priceRaw = getPrice(product, type);
  const {
    highlightProduct,
    showPricePerBound,
    showPricePerTraveler
  } = ((_product$configuratio = product.configuration) === null || _product$configuratio === void 0 ? void 0 : _product$configuratio.travelerDetails) || {};
  return {
    numberOfBounds,
    priceRaw,
    isProductHighlighted: highlightProduct,
    shouldShowPricePerBound: showPricePerBound,
    shouldShowPricePerTraveler: showPricePerTraveler,
    ...(showPricePerTraveler ? {
      pricePerTravelerRaw: getPrice(product, type, true)
    } : {}),
    readMore: {
      isEnabled: true
    },
    travelerIds
  };
};
export const getSelection = function (name, id, form, travelers, _temp) {
  var _form$name, _sellPriceTravelers$m;
  let {
    sellPriceTravelers
  } = _temp === void 0 ? {} : _temp;
  return ((_form$name = form[name]) === null || _form$name === void 0 ? void 0 : _form$name.value.value) === true ? {
    productId: id,
    ...(sellPriceTravelers ? {
      selectionTraveler: (_sellPriceTravelers$m = sellPriceTravelers === null || sellPriceTravelers === void 0 ? void 0 : sellPriceTravelers.map(_ref3 => {
        let {
          travelerId
        } = _ref3;
        return Number(travelerId);
      })) !== null && _sellPriceTravelers$m !== void 0 ? _sellPriceTravelers$m : {}
    } : {
      selectionNone: true
    })
  } : undefined;
};