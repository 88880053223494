import { css, etiColors } from '@eti/styles';
import variants from '../../utils/variants';
const notificationVariants = {
  [variants.INFORMATION]: css`
    background-color: ${etiColors.info.light};
    border: 1px solid ${etiColors.info.solid};
  `,
  [variants.CONFIRMATION]: css`
    background-color: ${etiColors.confirmation.light};
    border: 1px solid ${etiColors.confirmation.solid};
  `,
  [variants.WARNING]: css`
    background: ${etiColors.warning.light};
    border: 1px solid ${etiColors.warning.solid};
  `,
  [variants.CRITICAL]: css`
    background: ${etiColors.critical.light};
    border: 1px solid ${etiColors.critical.solid};
  `
};
export const variantStyles = variant => notificationVariants[variant] || notificationVariants[variants.INFORMATION];