import { formValueSelector } from 'redux-form';
import uniqBy from 'lodash/uniqBy';
import { BASIC, BUNDLE_PRODUCT, PREMIUM, RECOMMENDED } from './constants';
export const getSelected = (name, form) => state => {
  var _formData$value;
  const formData = formValueSelector(form)(state, name);
  return (_formData$value = formData === null || formData === void 0 ? void 0 : formData.value) !== null && _formData$value !== void 0 ? _formData$value : null;
};
export const getGraphQlSelection = _ref => {
  let {
    state,
    form
  } = _ref;
  return getSelected(BUNDLE_PRODUCT, form)(state);
};
const getPrice = function (value, options) {
  if (options === void 0) {
    options = [];
  }
  return (options.find(_ref2 => {
    let {
      type
    } = _ref2;
    return type === value;
  }) || {}).priceRaw || 0;
};
const getId = function (value, options) {
  if (options === void 0) {
    options = [];
  }
  return (options.find(_ref3 => {
    let {
      type
    } = _ref3;
    return type === value;
  }) || {}).id || null;
};
export const formatSet = options => value => ({
  value: value || null,
  price: getPrice(value, options),
  id: getId(value, options)
});
export const formatGet = bundle => bundle ? bundle.value : null;
export const orderBundles = type => {
  switch (type) {
    case BASIC:
      return {
        desktopOrder: 1,
        mobileOrder: 3
      };
    case RECOMMENDED:
    case PREMIUM:
    default:
      return {
        desktopOrder: 2,
        mobileOrder: 1
      };
  }
};
export const getBundleName = item => `${BUNDLE_PRODUCT}.${item.id}`;
export const getNewActiveBundle = function (options, selectedBundleToOpen) {
  if (options === void 0) {
    options = [];
  }
  return options.filter(currentBundle => {
    const bundleName = getBundleName(currentBundle);
    return bundleName === selectedBundleToOpen;
  })[0];
};
export const getUniqFeatures = options => uniqBy(options.reduce((acc, _ref4) => {
  let {
    features
  } = _ref4;
  return [...acc, ...features];
}, []), 'name');
export const getRowsData = options => getUniqFeatures(options).map((_ref5, rowIndex) => {
  let {
    description,
    name,
    pieces,
    weight,
    weightUnit,
    productTypeId
  } = _ref5;
  return {
    productTypeId,
    description,
    name,
    optionColumns: options.map(_ref6 => {
      let {
        features,
        type,
        id
      } = _ref6;
      return {
        included: Boolean(features.find(_ref7 => {
          let {
            name: optionName
          } = _ref7;
          return optionName === name;
        })),
        isRecommended: type === RECOMMENDED,
        optionId: id
      };
    }),
    pieces,
    rowIndex,
    weight,
    weightUnit
  };
});
export const getPriorities = recommendedPriority => ({
  BASIC: 0,
  RECOMMENDED: recommendedPriority,
  PREMIUM: recommendedPriority === 1 ? 2 : 1,
  DELUXE: 3
});
export const sortOptions = function (options) {
  var _options;
  if (options === void 0) {
    options = [];
  }
  const recommendedPriority = Math.max(((_options = options) === null || _options === void 0 ? void 0 : _options.length) - 2, 1);
  const priorities = getPriorities(recommendedPriority);
  return [...options].sort((a, b) => priorities[a.type] - priorities[b.type]);
};
export const stylesVariables = {
  firstColumnWidth: 'calc(100% / 3)',
  recommendedBackground: '#fafafa',
  rowsHeight: '56px',
  originalPriceColor: '#737373'
};