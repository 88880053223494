import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';
import { isEmergencyPhoneError, isPhoneError, getSelectedPhone, getSelectedEmergencyPhone } from '../utils/phone';
import { getSelected as getSelectedMail } from '../utils/mail';
import { FIRST_TRAVELER, MAIL, PHONE, EMERGENCY_PHONE, SELECTED_TRAVELER_CONTACT_TYPE, TRAVELER } from '../utils/constants';
import ContactForm from '../components/ContactForm';
const mapStateToProps = (state, _ref) => {
  let {
    asyncValidate,
    countries,
    form,
    shouldShowEmergencyContactInformation
  } = _ref;
  return {
    asyncValidate,
    countries,
    emailAddress: getSelectedMail(form)(state),
    emergencyPhoneNumber: getSelectedEmergencyPhone(form)(state)[EMERGENCY_PHONE],
    hasAsyncPhoneError: isPhoneError(form)(state),
    hasAsyncEmergencyPhoneError: isEmergencyPhoneError(form)(state),
    phoneNumber: getSelectedPhone(form)(state)[PHONE],
    selectedTravelerContactType: formValueSelector(form)(state, `${FIRST_TRAVELER}.${SELECTED_TRAVELER_CONTACT_TYPE}`),
    shouldShowEmergencyContactInformation
  };
};
const mapDispatchToProps = (dispatch, _ref2) => {
  let {
    form,
    asyncValidate
  } = _ref2;
  return {
    onCountryChange(phoneType) {
      dispatch(change(form, `${FIRST_TRAVELER}.${phoneType}`, ''));
    },
    triggerAsyncValidation(phoneNumber, phoneType) {
      return asyncValidate(`${FIRST_TRAVELER}.${phoneType}`, phoneNumber, 'blur');
    },
    triggerEmailAddressAsyncValidation(emailAddress) {
      return asyncValidate(`${FIRST_TRAVELER}.${MAIL}`, emailAddress, 'blur');
    },
    initializeSelectedTravelerContactType: () => {
      dispatch(change(form, `${FIRST_TRAVELER}.${SELECTED_TRAVELER_CONTACT_TYPE}`, `${TRAVELER}`));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);