import { connect } from 'react-redux';
import { setChoiceValueForTraveler } from '../../common/components/baggage-per-traveler/utils/baggagePerTravelerUtil';
import { CABIN_BAGGAGE_PER_TRAVELER } from '../../products';
import CabinBaggagePrimaryFlow from '../components/CabinBaggagePrimaryFlow';
import { getSameAsFirstTravelerForCurrentTraveler, getSelectedForTraveler, getTravelersWithDefaultChoice } from '../../common/utils/baggage/units';
const productName = CABIN_BAGGAGE_PER_TRAVELER.name;
const mapStateToProps = (state, _ref) => {
  var _order$travelers;
  let {
    form,
    travelerNumber,
    order
  } = _ref;
  const firstTravelerId = order === null || order === void 0 || (_order$travelers = order.travelers) === null || _order$travelers === void 0 || (_order$travelers = _order$travelers[0]) === null || _order$travelers === void 0 ? void 0 : _order$travelers.id;
  return {
    firstTravelerSelectionValue: getSelectedForTraveler(productName, form, true)(state, firstTravelerId),
    hasSameChoiceAsFirstTraveler: getSameAsFirstTravelerForCurrentTraveler(productName, form)(state, String(travelerNumber)),
    travelersWithDefaultChoice: getTravelersWithDefaultChoice(productName, form)(state)
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  setChoiceValue: setChoiceValueForTraveler(dispatch, productName, props)
});
export default connect(mapStateToProps, mapDispatchToProps)(CabinBaggagePrimaryFlow);