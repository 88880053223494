import PropTypes from 'prop-types';
import { BoundNew, ProductPreSelection } from '@eti/types';

export const orderPropTypes = {
  bounds: PropTypes.arrayOf(BoundNew),
  code: PropTypes.string,
  configuration: PropTypes.shape({
    productPreSelection: PropTypes.string,
    travelerDetails: PropTypes.shape({
      // eslint-disable-next-line
      highlightProduct: PropTypes.bool,
      // eslint-disable-next-line
      persuasionEnabled: PropTypes.bool,
      shouldShowPricePerBound: PropTypes.bool,
      // eslint-disable-next-line
      showPricePerTraveler: PropTypes.bool,
    }),
  }),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  persuasion: PropTypes.shape({ isEnabled: PropTypes.bool }),
  productPreSelection: ProductPreSelection,
  productSpecificTexts: PropTypes.shape({
    seatMapSegmentTexts: PropTypes.arrayOf(
      PropTypes.shape({
        boundIndex: PropTypes.number,
        segmentIndex: PropTypes.number,
        text: PropTypes.string,
      }),
    ),
  }),
  // eslint-disable-next-line
  selectedWithTrip: PropTypes.bool,
  sellSpecification: PropTypes.shape({
    price: PropTypes.shape({
      markup: PropTypes.shape({
        currency: PropTypes.shape({
          code: PropTypes.string,
        }),
        value: PropTypes.number,
      }),
      price: PropTypes.shape({
        currency: PropTypes.shape({
          code: PropTypes.string,
        }),
        value: PropTypes.number,
      }),
      vat: PropTypes.shape({
        currency: PropTypes.shape({
          code: PropTypes.string,
        }),
        value: PropTypes.number,
      }),
    }),
  }),
  shouldShowPricePerBound: PropTypes.bool,
  travelers: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number,
      ageType: PropTypes.string,
      countries: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          label: PropTypes.string,
          phoneCountryId: PropTypes.string,
          phoneCountryPrefix: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      dateOfBirth: PropTypes.shape({
        hasValueFromSelfServiceRebookingOrder: PropTypes.bool,
        shouldShowFormInput: PropTypes.bool,
      }),
      frequentFlyerCardTypes: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
      id: PropTypes.string,
      shouldShowFrequentFlyerCard: PropTypes.bool,
      showDateOfBirth: PropTypes.bool,
      travelDocument: PropTypes.shape({
        requireTravelDocumentExpiryDate: PropTypes.oneOf(['OPTIONAL', 'REQUIRED', 'NOT_REQUIRED']),
        shouldShowTravelDocumentDateOfBirth: PropTypes.bool,
        shouldShowTravelDocumentIssueCity: PropTypes.bool,
        shouldShowTravelDocumentIssueDate: PropTypes.bool,
        shouldShowTravelDocumentNationality: PropTypes.bool,
        shouldShowTravelDocumentPassportNumber: PropTypes.bool,
      }),
    }),
  ),
};
