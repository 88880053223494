import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { breakpoints, Button, Dialog, Inline, Stack } from '@etg/wings';
import { Pictogram } from '@eti/components';
import { useSiteContext } from '@eti/providers';
import { css } from '@eti/styles';
import { analytics } from '@eti/utils';

const buttonStyles = css`
  @media (min-width: ${breakpoints._970}) {
    min-width: 200px;
  }
`;

const ErrorDialog = ({
  content,
  dataLayerEvent,
  'data-testid': dataTestId,
  isOpen,
  onPrimaryAction,
  onSecondaryAction,
  pictogramId = 'generic-error',
  primaryButtonLabel,
  secondaryButtonLabel,
  title,
}) => {
  const { brand } = useSiteContext();
  useEffect(() => {
    if (isOpen && dataLayerEvent) {
      analytics.pushToDataLayer(dataLayerEvent);
    }
  }, [isOpen, dataLayerEvent]);

  return (
    <Dialog data-testid={dataTestId} isOpen={isOpen} title={title}>
      <Stack>
        <Inline align="center">
          <Pictogram brand={brand.code} id={pictogramId} />
        </Inline>
        <div>{content}</div>
        <Inline align="end" collapseBelow={breakpoints._560}>
          <Button
            className={buttonStyles}
            data-testid="error-modal-primary-button"
            onClick={onPrimaryAction}
            variant="primary"
          >
            {primaryButtonLabel}
          </Button>
          {onSecondaryAction && (
            <Button
              className={buttonStyles}
              data-testid="error-modal-secondary-button"
              onClick={onSecondaryAction}
            >
              {secondaryButtonLabel}
            </Button>
          )}
        </Inline>
      </Stack>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  content: PropTypes.string,
  'data-testid': PropTypes.string,
  dataLayerEvent: PropTypes.shape({
    event: PropTypes.string,
  }),
  isOpen: PropTypes.bool,
  onPrimaryAction: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  pictogramId: PropTypes.string,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonLabel: PropTypes.string,
  title: PropTypes.string,
};

export default ErrorDialog;
