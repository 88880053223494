import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
export const isActive = (showSummary, boundSegmentId, active) => showSummary ? false : isEqual(boundSegmentId, [boundSegmentId[0], active[0]]);
export const findIndexOfSegment = (segments, boundSegmentIndex) => segments.findIndex(b => b.boundSegmentIndex === boundSegmentIndex);
export const findNextAvailableSegment = function (segments, activeSegmentIndex) {
  if (segments === void 0) {
    segments = [];
  }
  const activeIndex = findIndexOfSegment(segments, activeSegmentIndex);
  const succeedingSegments = segments.slice(activeIndex + 1);
  const [nextSegment] = succeedingSegments;
  return nextSegment;
};
export const findPreviousAvailableSegment = function (segments, activeSegmentIndex) {
  if (segments === void 0) {
    segments = [];
  }
  const activeIndex = findIndexOfSegment(segments, activeSegmentIndex);
  const precedingSegments = segments.slice(0, activeIndex);
  const [previousSegment] = precedingSegments.reverse();
  return previousSegment;
};
export const isFirstAvailableSegment = (segments, boundSegmentIndex) => {
  const first = segments[0];
  return first.boundSegmentIndex === boundSegmentIndex;
};
export const isLastAvailableSegment = (segments, boundSegmentIndex) => {
  const last = segments[segments.length - 1];
  return last.boundSegmentIndex === boundSegmentIndex;
};
export const getBestPriceCharacteristics = function (segments, activeBoundSegmentIndex) {
  if (segments === void 0) {
    segments = [];
  }
  const hasSegments = segments.some(_ref => {
    let {
      segmentIndex
    } = _ref;
    return Number.isInteger(segmentIndex);
  });
  if (!hasSegments) {
    return [];
  }
  const segment = segments.find(_ref2 => {
    let {
      boundSegmentIndex
    } = _ref2;
    return boundSegmentIndex === activeBoundSegmentIndex;
  });
  return segment.bestPriceCharacteristics;
};
export const getSegment = function (segments, activeSegment) {
  if (segments === void 0) {
    segments = [];
  }
  const hasSegments = segments.some(_ref3 => {
    let {
      segmentIndex
    } = _ref3;
    return Number.isInteger(segmentIndex);
  });
  if (!hasSegments) {
    return null;
  }
  return segments.find(_ref4 => {
    let {
      boundSegmentIndex
    } = _ref4;
    return boundSegmentIndex === activeSegment;
  });
};
export const getNonSelectedAvailableSegments = function (flattenedBounds, totalSelectedSeats) {
  if (flattenedBounds === void 0) {
    flattenedBounds = [];
  }
  if (!totalSelectedSeats) {
    return [];
  }
  return flattenedBounds.reduce((acc, val) => {
    if (val.isAvailable) {
      const currentSelectedSegment = totalSelectedSeats.find(x => x.boundSegmentIndex === val.boundSegmentIndex);
      const areAllSeatsSelected = currentSelectedSegment ? difference(val.travelerIds, currentSelectedSegment.travelerIds).length === 0 : false;
      return [...acc, ...(!areAllSeatsSelected ? [val] : [])];
    }
    return acc;
  }, []);
};