import { Button, Stack } from '@etg/wings';
import { useHistory } from 'react-router-dom';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { ConnectedInput } from '@eti/form';
import { css, cx } from '@eti/styles';
import { useSiteContext, useTranslation } from '@eti/providers';
import { useAuth } from '@eti/authentication';
import { Dispatch, SetStateAction } from 'react';
import { AuthenticationType, LoginSource } from '@eti/schema-types';
import { MAIL, MAX_CHARS, ORDER_NUMBER } from '../utils/constants';
import { LOGIN } from '../../../constants/formNamesConstants';
import { routes } from '../../../constants/routesAndApisConstants';
import { withValidationProvider } from '../higher-order-components/WithValidationProvider';
import { CRITICAL } from '../utils/messageTypes';
import { scrollToElement } from '../../../utils/scrollToElement';
import { redirectToActualUrl } from '../../utils/utils';
import { handleWrongOrderNumberInputs } from '../utils/loginFormContainerUtils';
import { SourceOfLogin } from './models';

const formStyles = css`
  margin-top: 24px;
`;

const submitButtonStyles = css`
  display: block;
  margin: 0 auto;
  width: 296px;

  span {
    justify-content: center;
  }
`;

interface LoginFormContainerProps {
  classNames: Record<string, any>;
  clearForm: () => void;
  handleLoginSuccess: (email?: string, orderNumber?: string) => void;
  setNotification: Dispatch<SetStateAction<{ message: string; type: string; dataTestId: string }>>;
  source: SourceOfLogin;
}

interface LoginFormProps {
  email: string;
  orderNumber: string;
}
const LoginFormContainer = ({
  classNames = {},
  clearForm,
  handleLoginSuccess,
  handleSubmit,
  setNotification,
  source,
}: LoginFormContainerProps & InjectedFormProps<LoginFormProps, LoginFormContainerProps>) => {
  const { t } = useTranslation();
  const { brand } = useSiteContext();
  const history = useHistory();
  const { authenticateUser } = useAuth();

  const authenticate = async (email: string, orderNumber: string) => {
    const loginSourceMapping = {
      [SourceOfLogin.ORDER]: LoginSource.OrderLogin,
      [SourceOfLogin.MODAL]: LoginSource.OrderLogin,
      [SourceOfLogin.CONTACTUS]: LoginSource.ContactUsLogin,
      [SourceOfLogin.POSTBOOKING]: LoginSource.PostbookingLogin,
    };

    const authRequest = {
      email,
      orderNumber,
      authenticationType: AuthenticationType.OrderInfo,
      token: null,
      loginSource: loginSourceMapping[source],
    };
    const { data } = await authenticateUser(authRequest);
    const authenticateUserData = data?.authenticateUser;

    return {
      success: authenticateUserData?.authenticated,
      pageUrlForActualSiteWithLoadRef: authenticateUserData?.orderDetailsUrlForActualSite,
      brandCodeForActualSite: authenticateUserData?.brandCodeForActualSite,
      brandNameForActualSite: authenticateUserData?.brandNameForActualSite,
      hostNameForActualSite: authenticateUserData?.urlForActualSite,
    };
  };

  const showNotificationInvalidCredentials = () => {
    setNotification({
      message: t('Postbooking.Login.Invalid.Email.OrderNumber.Combination.Error'),
      type: CRITICAL,
      dataTestId: 'invalid-email-order-combo-error-message',
    });
    scrollToElement('#loginMessage');
  };

  const showNotificationInternalError = () => {
    setNotification({
      message: t('Postbooking.Login.Internal.Error'),
      type: CRITICAL,
      dataTestId: 'login-service-error-message',
    });
    scrollToElement('#loginMessage');
  };

  const handleRedirectionFromSource = (
    values: LoginFormProps,
    brandCodeForActualSite?: string | null,
    brandNameForActualSite?: string | null,
    pageUrlForActualSiteWithLoadRef?: string | null,
  ) => {
    if (handleLoginSuccess) {
      handleLoginSuccess(values.email, values.orderNumber);
    }
    if (pageUrlForActualSiteWithLoadRef) {
      redirectToActualUrl(
        brand,
        history,
        source === SourceOfLogin.ORDER
          ? `${pageUrlForActualSiteWithLoadRef}?source=order-details-login`
          : pageUrlForActualSiteWithLoadRef,
        brandCodeForActualSite,
        brandNameForActualSite,
      );
    }
  };

  const handleRedirectionWhenPartiallyLoggedIn = (
    values: LoginFormProps,
    brandCodeForActualSite?: string | null,
    brandNameForActualSite?: string | null,
    pageUrlForActualSiteWithLoadRef?: string | null,
  ) => {
    /** Only the order login and the modal login are landing to the Welcome page.
     Postbooking and contact-us logins redirect to the corresponding pages in the actual site * */
    if (source === SourceOfLogin.MODAL || source === SourceOfLogin.ORDER) {
      if (handleLoginSuccess) {
        handleLoginSuccess(values.email, values.orderNumber);
      }
      history.push(routes.ORDER_LIST);
    } else {
      handleRedirectionFromSource(
        values,
        brandCodeForActualSite,
        brandNameForActualSite,
        pageUrlForActualSiteWithLoadRef,
      );
    }
  };

  const handleActualSubmit = async (values: LoginFormProps) => {
    try {
      const { email, orderNumber } = values;
      const {
        success,
        pageUrlForActualSiteWithLoadRef,
        brandCodeForActualSite,
        brandNameForActualSite,
      } = await authenticate(email, orderNumber);

      const isPartiallyLoggedIn = Boolean(pageUrlForActualSiteWithLoadRef);

      if (success) {
        if (handleLoginSuccess) {
          handleLoginSuccess(values.email, values.orderNumber);
        }
      } else {
        if (isPartiallyLoggedIn) {
          handleRedirectionWhenPartiallyLoggedIn(
            values,
            brandCodeForActualSite,
            brandNameForActualSite,
            pageUrlForActualSiteWithLoadRef,
          );
        } else {
          showNotificationInvalidCredentials();
        }
        clearForm();
      }
    } catch (e) {
      clearForm();
      showNotificationInternalError();
    }
  };

  return (
    <form
      className={cx(formStyles, classNames.formStyles)}
      method="POST"
      onSubmit={handleSubmit(handleActualSubmit)}
    >
      <Stack spacing={24}>
        <ConnectedInput
          autoCapitalize="off"
          autoCorrect="off"
          data-testid="login-email"
          description={t('Postbooking.Login.Email.Helper.Text')}
          label={t('Postbooking.Login.Email')}
          maxLength={MAX_CHARS}
          name="email"
          placeholder={t('Postbooking.Login.Email.Placeholder.Text')}
          shouldMarkInputAsRequired
          spellCheck="false"
          type="email"
          validationRuleName={MAIL}
        />
        <ConnectedInput
          autoCapitalize="off"
          autoCorrect="off"
          data-testid="login-order-number"
          description={t('Postbooking.Login.Order.Helper.Text')}
          label={t('Postbooking.Login.Order')}
          maxLength={MAX_CHARS}
          name="orderNumber"
          normalize={handleWrongOrderNumberInputs}
          placeholder={t('Postbooking.Login.Order.Placeholder.Text')}
          shouldMarkInputAsRequired
          spellCheck="false"
          validationRuleName={ORDER_NUMBER}
        />
        <Button
          className={submitButtonStyles}
          data-testid="login-button"
          type="submit"
          variant="primary"
        >
          {t('Postbooking.Login.SubmitButton')}
        </Button>
      </Stack>
    </form>
  );
};

const connectFormToState = reduxForm<LoginFormProps, LoginFormContainerProps>({
  form: LOGIN,
});

export default compose(withValidationProvider, connectFormToState)(LoginFormContainer);
