import { connect } from 'react-redux';
import { formValueSelector, change as changeFormValue } from 'redux-form';
import PurchasableBaggage from '../components/PurchasableBaggage';
const onChangeAll = (dispatch, _ref) => {
  let {
    form,
    firstTravelerSelectionValue,
    productName,
    setChoiceValue,
    travelers,
    secondBagTravelers,
    setCombinedChoiceValue
  } = _ref;
  return checkboxValue => {
    dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, checkboxValue));
    travelers === null || travelers === void 0 || travelers.slice(1).forEach(traveler => {
      if (firstTravelerSelectionValue === 'combined') {
        return setCombinedChoiceValue && setCombinedChoiceValue(form, traveler, secondBagTravelers === null || secondBagTravelers === void 0 ? void 0 : secondBagTravelers.find(secongBagTraveler => secongBagTraveler.id === (traveler === null || traveler === void 0 ? void 0 : traveler.id)), checkboxValue ? firstTravelerSelectionValue : null);
      }
      return setChoiceValue && setChoiceValue(form, traveler, checkboxValue ? firstTravelerSelectionValue : null);
    });
  };
};
const onChange = (dispatch, _ref2) => {
  let {
    form,
    setChoiceValue,
    traveler,
    travelers,
    isFirstTraveler,
    productName,
    firstTravelerSelectionValue,
    shouldApplySelectionToAll,
    setPersuasionIsOpen,
    setPersuasionOnConfirm,
    setPersuasionOnDecline,
    secondBagTraveler,
    secondBagTravelers,
    setCombinedChoiceValue
  } = _ref2;
  return (selectionValue, yesOptionRef) => {
    const showPersuasion = traveler === null || traveler === void 0 ? void 0 : traveler.persuasionEnabled;
    const triggerChange = val => {
      const applySelectionToAll = isFirstTraveler && shouldApplySelectionToAll;
      const handleCombinedChoice = () => {
        if (setCombinedChoiceValue) {
          if (applySelectionToAll) {
            const getFirstSecondBagTraveler = trav => secondBagTravelers === null || secondBagTravelers === void 0 ? void 0 : secondBagTravelers.filter(secongBagTraveler => secongBagTraveler.id === trav.id)[0];
            travelers === null || travelers === void 0 || travelers.forEach(trav => setCombinedChoiceValue(form, trav, getFirstSecondBagTraveler(trav), val));
          }
          setCombinedChoiceValue(form, traveler, secondBagTraveler, val);
        }
      };
      const handleIndividualChoice = () => {
        if (setChoiceValue) {
          if (applySelectionToAll) {
            travelers === null || travelers === void 0 || travelers.forEach(trav => setChoiceValue(form, trav, val));
          } else {
            setChoiceValue(form, traveler, val);
          }
        }
      };
      if (val === 'combined') {
        handleCombinedChoice();
      } else {
        handleIndividualChoice();
      }
      if (!isFirstTraveler && val !== firstTravelerSelectionValue) {
        dispatch(changeFormValue(form, `${productName}.shouldApplySelectionToAll`, false));
      }
    };
    if (showPersuasion && selectionValue === 'false') {
      triggerChange('false');
      setPersuasionIsOpen(true);
      setPersuasionOnConfirm(() => () => {
        setPersuasionIsOpen(false);
        setTimeout(() => {
          triggerChange('true');
          yesOptionRef === null || yesOptionRef === void 0 || yesOptionRef.focus();
        }, 530);
      });
      setPersuasionOnDecline(() => () => {
        triggerChange('false');
        setPersuasionIsOpen(false);
      });
    } else {
      triggerChange(selectionValue);
    }
  };
};
const mapStateToProps = (state, _ref3) => {
  let {
    productName,
    form
  } = _ref3;
  const shouldApplySelectionToAll = formValueSelector(form)(state, `${productName}.shouldApplySelectionToAll`);
  return {
    shouldApplySelectionToAll: shouldApplySelectionToAll !== null && shouldApplySelectionToAll !== void 0 ? shouldApplySelectionToAll : true
  };
};
const mergeProps = (_ref4, _ref5, ownProps) => {
  let {
    shouldApplySelectionToAll
  } = _ref4;
  let {
    dispatch,
    ...dispatchProps
  } = _ref5;
  return {
    ...dispatchProps,
    ...ownProps,
    onChange: onChange(dispatch, {
      ...ownProps,
      shouldApplySelectionToAll
    }),
    onChangeAll: onChangeAll(dispatch, ownProps),
    shouldApplySelectionToAll
  };
};
export default connect(mapStateToProps, null, mergeProps)(PurchasableBaggage);