import { gql } from '@eti/schema-types';

export const GET_ANCILLARY_ITEM = gql(/* GraphQL */ `
  query getAncillary($selection: [AncillaryInput!], $productId: ID!) {
    ancillaryItem(additionalInfo: $selection, productId: $productId) {
      name
      sellSpecification {
        ... on SellSpecificationTravelerChoiceNew {
          __typename
          availableChoices {
            id
            name
          }
          pricePerTravelers {
            travelerId
            price {
              markup {
                value
                currency {
                  code
                }
              }
              price {
                value
                currency {
                  code
                }
              }
              vat {
                value
                currency {
                  code
                }
              }
            }
          }
        }
      }
    }
  }
`);
