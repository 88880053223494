import { OVERRIDE_LOGOS } from '../constants/carrierConstants';
const fetchCarrierLogo = async carrierIataId => {
  if (carrierIataId) {
    const id = carrierIataId.toUpperCase();
    const isHighDensityScreen = window.matchMedia('(-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi)').matches;
    const overridePath = OVERRIDE_LOGOS.includes(id) ? '/overrides' : '';
    const imageSizePath = isHighDensityScreen ? '80x80' : '40x40';
    try {
      const carrierLogo = await import( /* webpackMode: 'lazy-once' */`@eti/assets/logos/carriers${overridePath}/${imageSizePath}/${id}.png`);
      return carrierLogo.default;
    } catch {
      const fallback = await import(`@eti/assets/logos/carriers/${imageSizePath}/fallback.png`);
      return fallback.default;
    }
  }
  return null;
};
export default fetchCarrierLogo;