import { SearchData, useDateFormat, useSearch, useSiteContext, Route } from '@eti/providers';
import { etiColors, css, mediaQueries, cx } from '@eti/styles';
import { Inline, Button, vars, Text, breakpoints } from '@etg/wings';
import { constants, date as dateUtil, useScrollTo } from '@eti/utils';
import { TripType } from '@eti/schema-types';
import {
  CaretDown,
  CaretUp,
  User,
  CalendarBlank,
  ArrowRight as OneWayTripArrow,
  ArrowsLeftRight as ReturnTripArrow,
} from '@phosphor-icons/react';
import { Fragment } from 'react';

const titleStyles = css`
  margin: auto 0;
  position: relative;

  @media ${mediaQueries.medium.up} {
    align-items: center;
  }
`;

const titleTextStyles = css`
  font-size: 0.875rem;
  font-weight: 700;

  @media (max-width: ${breakpoints._560}) {
    min-width: 80%;
  }
`;

const separatorStyles = css`
  display: inline-block;
  padding: 0 0.2rem;
`;

const dividerStyles = css`
  background: #e5e5e5;
  height: 21px;
  width: 1px;

  @media (max-width: ${breakpoints._560}) {
    display: none;
  }
`;

const detailsStyles = css`
  align-items: center;
  color: ${etiColors.black};
  display: flex;
  gap: 16px;
`;

const buttonStyles = (isMyTrip: boolean) => css`
  border-radius: ${isMyTrip ? '8px' : vars.card.borderRadius};
  height: 32px;
  width: 32px;

  @media (max-width: ${breakpoints._560}) {
    background: none;
    border: none;
    color: #262626;
    position: absolute;
    right: 0;
    top: 5px;
  }
`;

const detailsItemStyles = css`
  align-items: center;
  display: flex;
  gap: 4px;
  height: 16px;
`;

const tripDetailsTitleWrapperStyles = css`
  background: ${vars.colors.pageBackdrop};
  border-radius: ${vars.card.borderRadius};
  cursor: pointer;
  padding: 8px 16px;

  @media (min-width: ${breakpoints._560}) {
    width: fit-content;
  }

  @media (min-width: ${breakpoints._768}) {
    max-width: 450px;
  }

  @media (min-width: ${breakpoints._970}) {
    max-width: 665px;
  }

  @media (min-width: 1170px) {
    max-width: 785px;
  }
`;

const tripDateStyles = css`
  align-items: center;
  display: flex;
  margin-top: 2px;
`;

const multiStopContainerStyles = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    + svg {
      margin: -1.2px 8px;
    }
  }

  @media (max-width: ${breakpoints._560}) {
    padding-right: 40px;
  }

  @media (min-width: ${breakpoints._560}) {
    max-width: 114px;
  }

  @media (min-width: ${breakpoints._768}) {
    max-width: 96px;
  }

  @media (min-width: ${breakpoints._970}) {
    max-width: 275px;
  }
`;

const tripLocationStyles = css`
  line-height: 20px;

  span {
    &.originCity,
    &.destinationCity {
      display: none;

      @media (min-width: ${breakpoints._970}) {
        display: initial;
        max-width: 70px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @media (min-width: 1170px) {
        max-width: 150px;
      }
    }

    &:nth-of-type(2) {
      @media (min-width: 970px) {
        &::before {
          content: '(';
        }

        &::after {
          content: ')';
        }
      }
    }
  }
`;

const timeTextStyle = css`
  @media (max-width: ${breakpoints._560}) {
    font-size: 0.75rem;
  }
`;

const getMultiCityRoutes = (routes?: Route[]) => {
  return routes?.reduce<string[]>(
    (acc, { originCityCode, destinationCity, destinationCityCode }, index, arr) => {
      if (originCityCode) {
        acc.push(originCityCode);
      }

      if (
        index < arr.length &&
        destinationCity !== arr[index === arr.length - 1 ? index : index + 1].originCity
      ) {
        acc.push(destinationCityCode!);
      }
      return acc;
    },
    [],
  );
};

const TripDetailsTitle = () => {
  const { brand } = useSiteContext();
  const {
    currentSearchData,
    isSearchComplete: isDisabled,
    isSearchFormOpen,
    setIsSearchFormOpen,
  } = useSearch();
  const { scrollVertical } = useScrollTo();
  const { day } = useDateFormat();

  const { date, travelers, tripType, routes = [] } = currentSearchData as SearchData;
  const numberOfTravelers = travelers && travelers?.adults + travelers?.childAges.length;
  const TripTypeIcon = tripType === TripType.Return ? ReturnTripArrow : OneWayTripArrow;
  const { MYTRIP } = constants.brands;
  const isMyTripBrand = brand.code === MYTRIP;

  const scrollToTop = () => scrollVertical('smooth', 0);

  const handleClick = () => {
    setIsSearchFormOpen!(!isSearchFormOpen);

    if (!isSearchFormOpen) {
      scrollToTop();
    }
  };

  const multiCityRoutes = getMultiCityRoutes(routes);
  const { destinationCity, destinationCityCode, originCityCode, originCity } = routes[0];

  const tripRoutes = (() => {
    switch (tripType) {
      case TripType.MultiStop:
        return (
          <div className={multiStopContainerStyles}>
            {multiCityRoutes?.map((city, index) => {
              return (
                <Fragment key={`${city}-${index}`}>
                  <span data-testid={`city-${index}-name`}>{city}</span>
                  {index !== multiCityRoutes.length - 1 && (
                    <TripTypeIcon data-testid={`${tripType}-icon`} size={12} weight="fill" />
                  )}
                </Fragment>
              );
            })}
          </div>
        );
      case TripType.OneWay:
      case TripType.Return:
        return (
          <>
            <Inline className={tripLocationStyles} spacing={4}>
              <span className="originCity" data-testid="origin-city-name">
                {originCity}
              </span>
              <span data-testid="origin-city-code">{originCityCode}</span>
            </Inline>
            <TripTypeIcon data-testid={`${tripType}-icon`} weight="fill" />
            <Inline className={tripLocationStyles} spacing={4}>
              <span className="destinationCity" data-testid="destination-city-name">
                {destinationCity}
              </span>
              <span data-testid="destination-city-code">{destinationCityCode}</span>
            </Inline>
          </>
        );
      default:
        return null;
    }
  })();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={tripDetailsTitleWrapperStyles}
      data-testid="trip-details-title-wrapper"
      onClick={handleClick}
    >
      <Inline
        className={titleStyles}
        spacing={{
          [breakpoints._0]: 4,
          [breakpoints._560]: 16,
        }}
      >
        <Inline
          alignY="center"
          className={titleTextStyles}
          data-testid="tripDetails-title-TitleText"
          spacing={8}
        >
          {tripRoutes}
        </Inline>
        <div className={detailsStyles}>
          <div className={dividerStyles} />
          <div className={detailsItemStyles}>
            <CalendarBlank />
            <div className={tripDateStyles} data-testid="tripDetails-title-date-desktop">
              <Text className={timeTextStyle} variant="14">
                <time dateTime={date?.departure}>{dateUtil.format(date?.departure, day)}</time>
              </Text>
              {tripType !== TripType.OneWay && (
                <>
                  <span
                    className={separatorStyles}
                    data-testid="tripDetails-title-separator-desktop"
                  >
                    –
                  </span>
                  <Text className={timeTextStyle} variant="14">
                    <time dateTime={date?.return}>{dateUtil.format(date?.return, day)}</time>
                  </Text>
                </>
              )}
            </div>
          </div>
          <div className={dividerStyles} />
          <div className={detailsItemStyles}>
            <User size={16} />
            <Text
              className={timeTextStyle}
              data-testid="tripDetails-number-of-travelers"
              variant="14"
            >
              {numberOfTravelers}
            </Text>
          </div>
          <Button
            className={cx(buttonStyles(isMyTripBrand))}
            data-testid="toggleButton"
            isDisabled={!isDisabled}
            size="small"
            variant="primary"
          >
            {isSearchFormOpen ? (
              <CaretUp data-testid="caret-up-icon" size={20} weight="bold" />
            ) : (
              <CaretDown data-testid="caret-down-icon" size={20} weight="bold" />
            )}
          </Button>
        </div>
      </Inline>
    </div>
  );
};

export default TripDetailsTitle;
