import { connect } from 'react-redux';
import { change as changeFormValue } from 'redux-form';
import ManulifeTraveler from '../components/ManulifeTraveler';
import { getProvince, getTravelerDateOfBirth, getSelectedTraveler, getValidDateOfBirth, getTravelerField, getTravelerPrice, getIsFetchingPrice, getIsRefetchingPrice } from '../utils/selection';
const mapStateToProps = (state, _ref) => {
  let {
    form,
    id,
    productName
  } = _ref;
  const dateOfBirth = getTravelerDateOfBirth(productName, form, id)(state);
  const isSelected = getSelectedTraveler(productName, form, id)(state);
  const isValidDateOfBirth = getValidDateOfBirth(productName, form, id)(state);
  const province = getProvince(productName, form)(state);
  const price = getTravelerPrice(productName, form, id)(state);
  const isFetchingPrice = getIsFetchingPrice(productName, form, id)(state);
  const isRefetchingPrice = getIsRefetchingPrice(productName, form, id)(state);
  return {
    dateOfBirth,
    isSelected,
    isValidDateOfBirth,
    isFetchingPrice,
    province,
    price,
    isRefetchingPrice
  };
};
const setTravelerFetchingPrice = (dispatch, _ref2) => {
  let {
    form,
    id,
    productName
  } = _ref2;
  return value => {
    const travelerField = getTravelerField(id, productName);
    dispatch(changeFormValue(form, `${travelerField}.isFetchingPrice`, value, true, false));
  };
};
const setTravelerRefetchingPrice = (dispatch, _ref3) => {
  let {
    form,
    id,
    productName
  } = _ref3;
  return value => {
    const travelerField = getTravelerField(id, productName);
    dispatch(changeFormValue(form, `${travelerField}.isRefetchingPrice`, value, true, false));
  };
};
const selectTraveler = (dispatch, _ref4) => {
  let {
    form,
    id,
    productName
  } = _ref4;
  return value => {
    const travelerField = getTravelerField(id, productName);
    dispatch(changeFormValue(form, `${travelerField}.selected`, value, true, false));
    dispatch(changeFormValue(form, `${travelerField}.id`, id, true, false));
    dispatch(changeFormValue(form, `${travelerField}.isFetchingPrice`, false, true, false));
  };
};
const addTravelerPrice = (dispatch, _ref5) => {
  let {
    form,
    id,
    productName
  } = _ref5;
  return value => {
    const travelerField = getTravelerField(id, productName);
    dispatch(changeFormValue(form, `${travelerField}.priceRaw`, value, true, false));
    dispatch(changeFormValue(form, `${travelerField}.isFetchingPrice`, false, true, false));
  };
};
const addTravelerPriceError = (dispatch, _ref6) => {
  let {
    form,
    id,
    productName
  } = _ref6;
  return value => {
    const travelerField = getTravelerField(id, productName);
    dispatch(changeFormValue(form, `${travelerField}.priceError`, value, true, false));
    dispatch(changeFormValue(form, `${travelerField}.isFetchingPrice`, false, true, false));
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  selectTraveler: selectTraveler(dispatch, props),
  addTravelerPrice: addTravelerPrice(dispatch, props),
  addTravelerPriceError: addTravelerPriceError(dispatch, props),
  setTravelerFetchingPrice: setTravelerFetchingPrice(dispatch, props),
  setTravelerRefetchingPrice: setTravelerRefetchingPrice(dispatch, props)
});
export default connect(mapStateToProps, mapDispatchToProps)(ManulifeTraveler);