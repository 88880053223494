import { getSelected, getGraphQlSelection } from './utils/utils';
import ProductBundle from './containers/ProductBundleContainer';
const name = 'ancillaryBundle';
const id = 'ancillary-bundle';
export const ancillaryBundle = {
  formSection: name,
  getGraphQlSelection,
  getSelected,
  id,
  name
};
export default ProductBundle;