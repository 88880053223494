import getTime from 'date-fns/getTime';
import { setTravelerName } from '../../utils/travelerNames';

export const getTravelers = (order, travelerNames = [], travelerLabel) => {
  const travelers = order?.travelers;

  return travelers
    ? travelers.map(({ ageType, id, name, showDateOfBirth } = {}, index) => ({
        ageType,
        id,
        name: name || setTravelerName(id, travelerNames, travelerLabel, index + 1),
        dateOfBirthReadOnly: showDateOfBirth,
      }))
    : [];
};

export const getSelectionVariables = (province, dateOfBirth, id) => ({
  travelerId: Number(id),
  birthDate: Number(getTime(new Date(dateOfBirth))),
  provinceId: Number(province.value),
});

export const getTravelerPrice = (data, id) => {
  const pricePerTravelers = data?.ancillaryItem?.sellSpecification?.pricePerTravelers ?? [];
  const travelerData = pricePerTravelers.find(({ travelerId }) => travelerId === id);
  return travelerData?.price?.price?.value ?? null;
};

export const getProvinces = (sellSpecification) =>
  (sellSpecification?.availableChoices ?? []).map(({ id, name } = {}) => ({
    value: id,
    label: name,
  }));

export const handleFetchPrice =
  (query, productId) =>
  async (province = {}, dateOfBirth, id) => {
    if (!query) {
      return null;
    }

    const { data, error } = await query({
      variables: {
        selection: getSelectionVariables(province, dateOfBirth, id),
        productId,
      },
    });

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        if (error) {
          reject(error);
        } else {
          resolve(getTravelerPrice(data, id));
        }
      }, 300);
    });
  };
