import { price as priceUtils } from '@eti/utils';
import { getPriceDivided as getPricePerBound } from '../../../utils/price';
import { PRESELECTION_VALUES } from './constants';
export const getIconObjectForNoProduct = code => `no-${code}`;
export const getBaggageWeight = (includedBaggage, purchasableWeight, purchasableWeightUnit, t) => {
  const heaviness = purchasableWeight !== null && purchasableWeight !== void 0 ? purchasableWeight : includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.weight;
  const heavinessUnit = purchasableWeightUnit !== null && purchasableWeightUnit !== void 0 ? purchasableWeightUnit : includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.weightUnit;
  return heaviness && heavinessUnit ? t('Product.BaggagePerTraveler.Weight', heaviness, heavinessUnit) : null;
};
export const getSecondBaggageWeight = (weightOfSecondBag, weightUnitOfSecondBag, t) => {
  return weightOfSecondBag && weightUnitOfSecondBag ? t('Product.BaggagePerTraveler.Weight', weightOfSecondBag, weightUnitOfSecondBag) : null;
};
export const getBaggagePieces = (includedBaggage, numberOfPieces) => {
  const includedBaggagePieces = includedBaggage === null || includedBaggage === void 0 ? void 0 : includedBaggage.pieces;
  return numberOfPieces !== null && numberOfPieces !== void 0 ? numberOfPieces : includedBaggagePieces || null;
};
export const getPiecesDescription = (includedBaggage, numberOfPieces, t) => {
  const pieces = getBaggagePieces(includedBaggage, numberOfPieces);
  return pieces ? `${t('Product.BaggagePerTraveler.Pieces', pieces)} x` : null;
};
export const formBaggageDimensions = _ref => {
  let {
    length,
    width,
    height,
    t
  } = _ref;
  return t('Product.CabinBaggagePerTraveler.Dimensions', length, width, height);
};
export const formCombinedPiecesDescription = (includedBaggage, numberOfPieces, piecesOfSecondBag, t) => {
  const pieces = getBaggagePieces(includedBaggage, numberOfPieces);
  return pieces ? `${t('Product.BaggagePerTraveler.Pieces', pieces + (piecesOfSecondBag || 0))} x` : null;
};
export const formBaggageWeightSizeDescription = _ref2 => {
  let {
    includedBaggage,
    weight,
    weightUnit,
    dimensions,
    numberOfPieces,
    t
  } = _ref2;
  const weightDescription = getBaggageWeight(includedBaggage, weight, weightUnit, t);
  const dimensionsDescription = dimensions && formBaggageDimensions({
    ...dimensions,
    t
  });
  const piecesDescription = getPiecesDescription(includedBaggage, numberOfPieces, t);
  if (!weightDescription) {
    const pieces = getBaggagePieces(includedBaggage, numberOfPieces);
    const unAvailableWeightLabel = t('Product.CabinBaggagePerTraveler.Unavailable.Weight.Item.Label');
    return `${pieces} ${unAvailableWeightLabel} ${dimensionsDescription || ''}`;
  }
  const baggageWeightDimensionDescription = `${piecesDescription !== null && piecesDescription !== void 0 ? piecesDescription : ''} ${weightDescription} ${dimensionsDescription !== null && dimensionsDescription !== void 0 ? dimensionsDescription : ''}`;
  return baggageWeightDimensionDescription.trim();
};
export const formCombinedBagWeightSizeDescription = _ref3 => {
  let {
    includedBaggage,
    weight,
    weightUnit,
    numberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  } = _ref3;
  const weightDescription = weight === weightOfSecondBag && weightUnit === weightUnitOfSecondBag && getSecondBaggageWeight(weightOfSecondBag, weightUnitOfSecondBag, t);
  const piecesDescription = weight === weightOfSecondBag && formCombinedPiecesDescription(includedBaggage, numberOfPieces, piecesOfSecondBag, t);
  return `${piecesDescription !== null && piecesDescription !== void 0 ? piecesDescription : ''} ${weightDescription}`;
};
export const formSeparateBagWeightSizeDescription = _ref4 => {
  let {
    weight,
    weightUnit,
    numberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  } = _ref4;
  const checkedBagPieceText = t('Product.BaggagePerTraveler.Pieces', numberOfPieces);
  const checkedBagWeightText = t('Product.BaggagePerTraveler.Weight', weight, weightUnit);
  const secondBagPieceText = t('Product.BaggagePerTraveler.Pieces', piecesOfSecondBag);
  const secondBagWeightText = t('Product.BaggagePerTraveler.Weight', weightOfSecondBag, weightUnitOfSecondBag);
  return `${checkedBagPieceText} x ${checkedBagWeightText} + ${secondBagPieceText} x ${secondBagWeightText}`;
};
export const generateBaggageContent = (titleChoiceNo, baggageDescription, piecesDescription, productPrice, hasSameValueAsFirstTraveler, _ref5) => {
  let {
    id,
    price,
    markup
  } = _ref5;
  return [{
    id: `content-false-${id}`,
    label: titleChoiceNo !== null && titleChoiceNo !== void 0 ? titleChoiceNo : null,
    value: {
      hasSameValueAsFirstTraveler,
      id,
      markup: null,
      priceRaw: 0,
      value: 'false'
    }
  }, {
    id: `content-true-${id}`,
    label: null,
    piecesDescription,
    productDescription: baggageDescription,
    productPrice,
    value: {
      hasSameValueAsFirstTraveler,
      id,
      markup,
      priceRaw: price,
      value: 'true'
    }
  }];
};
export const generateCombinedBaggageContent = _ref6 => {
  var _traveler$markup;
  let {
    titleChoiceNo,
    baggageDescription,
    piecesDescription,
    productPrice,
    hasSameChoiceAsFirstTraveler,
    traveler,
    secondBagTraveler,
    combinedBagPrice,
    combinedBagDescription,
    secondBagProductId
  } = _ref6;
  const travelerId = (traveler === null || traveler === void 0 ? void 0 : traveler.id) || '';
  return [{
    id: `content-false-${travelerId}`,
    label: titleChoiceNo !== null && titleChoiceNo !== void 0 ? titleChoiceNo : null,
    value: {
      hasSameValueAsFirstTraveler: hasSameChoiceAsFirstTraveler,
      id: travelerId,
      markup: null,
      priceRaw: 0,
      value: 'false'
    }
  }, {
    id: `content-singlebag-true-${travelerId}`,
    label: null,
    piecesDescription,
    productDescription: baggageDescription,
    productPrice,
    value: {
      hasSameValueAsFirstTraveler: hasSameChoiceAsFirstTraveler,
      id: travelerId,
      markup: (_traveler$markup = traveler === null || traveler === void 0 ? void 0 : traveler.markup) !== null && _traveler$markup !== void 0 ? _traveler$markup : null,
      priceRaw: (traveler === null || traveler === void 0 ? void 0 : traveler.price) || 0,
      value: 'true'
    }
  }, {
    id: `content-combinedbag-true-${travelerId}`,
    label: null,
    piecesDescription,
    productDescription: combinedBagDescription,
    productPrice: combinedBagPrice,
    secondBagProductId,
    value: {
      hasSameValueAsFirstTraveler: hasSameChoiceAsFirstTraveler,
      id: travelerId,
      markup: ((traveler === null || traveler === void 0 ? void 0 : traveler.markup) || 0) + ((secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.markup) || 0),
      priceRaw: ((traveler === null || traveler === void 0 ? void 0 : traveler.price) || 0) + ((secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.price) || 0),
      value: 'combined'
    }
  }];
};
export const baggagePerTravelerTransformData = _ref7 => {
  let {
    iconName,
    includedBaggage,
    weight,
    weightUnit,
    dimensions,
    numberOfPieces,
    shouldShowPricePerBound,
    numberOfBounds,
    pricePerBoundLabel,
    traveler,
    isBaggageAvailableOrIncluded,
    titleChoiceNo,
    hasSameChoiceAsFirstTraveler,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    secondBagTraveler,
    secondBagProductId,
    shouldShowPricePerBoundSecondBag,
    t
  } = _ref7;
  const icon = iconName ? {
    id: iconName
  } : null;
  const baggageDescription = formBaggageWeightSizeDescription({
    includedBaggage,
    weight,
    weightUnit,
    dimensions,
    numberOfPieces,
    t
  });
  const combinedBagDescription = piecesOfSecondBag > 0 && weight === weightOfSecondBag ? formCombinedBagWeightSizeDescription({
    includedBaggage,
    weight,
    weightUnit,
    numberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  }) : formSeparateBagWeightSizeDescription({
    weight,
    weightUnit,
    numberOfPieces,
    piecesOfSecondBag,
    weightOfSecondBag,
    weightUnitOfSecondBag,
    t
  });
  const piecesDescription = getPiecesDescription(includedBaggage, numberOfPieces, t);
  const perBoundLabel = shouldShowPricePerBound && numberOfBounds > 1 ? pricePerBoundLabel : null;
  const productPrice = priceUtils.formatPrice(perBoundLabel ? getPricePerBound(traveler === null || traveler === void 0 ? void 0 : traveler.price, numberOfBounds) : traveler === null || traveler === void 0 ? void 0 : traveler.price);
  const combinedBagPrice = priceUtils.formatPrice(perBoundLabel ? getPricePerBound(((secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.price) || 0) + ((traveler === null || traveler === void 0 ? void 0 : traveler.price) || 0), numberOfBounds) : ((secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.price) || 0) + ((traveler === null || traveler === void 0 ? void 0 : traveler.price) || 0));
  const baggageRadioButtonContent = piecesOfSecondBag > 0 ? generateCombinedBaggageContent({
    titleChoiceNo,
    baggageDescription,
    piecesDescription,
    productPrice,
    hasSameChoiceAsFirstTraveler,
    traveler,
    secondBagTraveler,
    combinedBagPrice,
    combinedBagDescription,
    secondBagProductId
  }) : generateBaggageContent(titleChoiceNo, baggageDescription, piecesDescription, productPrice, hasSameChoiceAsFirstTraveler, traveler || {});
  const baggageContents = !isBaggageAvailableOrIncluded || includedBaggage ? [] : baggageRadioButtonContent;
  const includedBaggageDescription = includedBaggage && baggageDescription ? baggageDescription : '';
  const SecondBagPricePerBoundLabel = shouldShowPricePerBoundSecondBag && numberOfBounds > 1 ? pricePerBoundLabel : undefined;
  const SecondBagPrice = priceUtils.formatPrice(SecondBagPricePerBoundLabel ? getPricePerBound(secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.price, numberOfBounds) : secondBagTraveler === null || secondBagTraveler === void 0 ? void 0 : secondBagTraveler.price);
  return {
    includedBaggageDescription,
    baggageContents,
    perBoundLabel,
    icon,
    SecondBagPrice,
    SecondBagPricePerBoundLabel
  };
};
export const getPreselected = travelers => {
  return travelers === null || travelers === void 0 ? void 0 : travelers.some(traveler => (traveler === null || traveler === void 0 ? void 0 : traveler.productPreSelection) === PRESELECTION_VALUES.yes);
};