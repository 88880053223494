import { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { observeClickOutside } from 'react-ref-observe';
import Media from 'react-media';
import { vars, Inline, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { populateListWithId } from '../../../utils/idGenerator';
import DropdownMenu from './DropdownMenu';

const listItemStyles = css`
  position: relative;
`;

const buttonStyles = css`
  background-color: transparent;
  border: none;
  border-radius: 3px;
  color: ${vars.colors.header.content};
  cursor: pointer;
  font-size: 0.875rem;
  padding: 8px;

  &:hover {
    background-color: ${vars.colors.header.hover};
    transition: 0.3s;
  }

  @media print {
    display: none;
  }
`;

const linkStyles = css`
  border-radius: 3px;
  color: ${vars.colors.header.content};
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  padding: 0;
  text-decoration: none;

  &:hover {
    background-color: ${vars.colors.header.hover};
    transition: 0.3s;
  }

  @media (min-width: ${breakpoints._768}) {
    padding: 8px;
  }

  @media print {
    display: none;
  }
`;

const DesktopMenu = ({ menu }) => {
  const dropdownRef = useRef(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(null);

  const closeSubMenu = () => {
    if (isSubMenuOpen) {
      setIsSubMenuOpen(null);
    }
  };
  observeClickOutside([dropdownRef], closeSubMenu);

  return (
    <>
      <Inline
        alignY="center"
        as="ul"
        data-testid="desktop-menu"
        noWrap
        ref={dropdownRef}
        spacing={{ [breakpoints._0]: 32, [breakpoints._768]: 16 }}
      >
        <Media query={`(min-width: ${breakpoints._768})`}>
          {(matches) => (
            <>
              {menu.map(({ id, name, subMenu, path }) => (
                <Fragment key={id}>
                  {subMenu?.length > 0 ? (
                    <>
                      {matches && (
                        <li className={listItemStyles}>
                          <button
                            className={buttonStyles}
                            data-testid={`menu-button-${name}`}
                            onClick={() => setIsSubMenuOpen(id)}
                            type="button"
                          >
                            {name}
                          </button>
                          {isSubMenuOpen === id && (
                            <DropdownMenu menu={populateListWithId(subMenu)} />
                          )}
                        </li>
                      )}
                    </>
                  ) : (
                    <li>
                      <a className={linkStyles} data-testid={`menu-link-${name}`} href={path}>
                        {name}
                      </a>
                    </li>
                  )}
                </Fragment>
              ))}
            </>
          )}
        </Media>
      </Inline>
    </>
  );
};

DesktopMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          path: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default DesktopMenu;
