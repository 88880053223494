import { ChangeEvent, useState } from 'react';
import { Input, Stack } from '@etg/wings';
import { css } from '@eti/styles';
import { capitalize } from '../../utils';

const inputLabelStyles = css`
  color: #666;
  font-size: 0.75rem;
  margin-inline-start: 0;
`;

interface InputSelectionProps {
  defaultValue: string;
  id: string;
  label: string;
}

const InputSelection = ({ defaultValue, label, id }: InputSelectionProps) => {
  const valueFromSessionStorage = sessionStorage.getItem(`mocked${capitalize(id)}`);
  const [selectedValue, setSelectedValue] = useState(() =>
    valueFromSessionStorage === null ? defaultValue : valueFromSessionStorage,
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
  };

  return (
    <Stack spacing={8}>
      <Input.Label className={inputLabelStyles} htmlFor={id}>
        {label}
      </Input.Label>
      <Input
        id={id}
        name={id}
        onChange={handleChange}
        placeholder={defaultValue}
        value={selectedValue}
      />
    </Stack>
  );
};

export default InputSelection;
