import { createPortal } from 'react-dom';
import { Tooltip, vars, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { useTranslation } from '@eti/providers';
import { Globe } from '@phosphor-icons/react';
import Media from 'react-media';
import { usePortal } from '../../custom-hooks/portal';
import { useLanguage } from '../custom-hooks/language';

const selectStyles = css`
  appearance: none;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  color: transparent;
  cursor: pointer;
  height: 100%;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9;

  @media (min-width: ${breakpoints._970}) {
    &:hover {
      background-color: ${vars.colors.header.hover};
      transition: 0.3s;
    }
  }
`;

const languageStyles = css`
  align-items: center;
  background-color: transparent;
  display: flex;
  gap: 8px;
  padding: 0;
  position: relative;
  top: 0;

  @media (min-width: ${breakpoints._970}) {
    padding: 8px;
  }
`;

const labelStyles = css`
  color: ${vars.colors.header.content};
  font-size: 0.875rem;
  font-weight: 400;
`;

const selectionWrapperStyles = css`
  display: flex;
  justify-content: center;
  position: relative;

  @media print {
    display: none;
  }
`;
interface LanguageProps {
  id: string;
  code: string;
  name: string;
}

interface LanguageSelectionButtonProps {
  portalId: string;
}
const LanguageSelectionButton = ({ portalId }: LanguageSelectionButtonProps) => {
  const portal = usePortal(portalId);
  const { t } = useTranslation();
  const { availableLanguages, currentLanguage, lessThanTwoAvailableLanguages, changeLanguage } =
    useLanguage();

  if (lessThanTwoAvailableLanguages) {
    return null;
  }

  return createPortal(
    <div className={selectionWrapperStyles}>
      <div className={languageStyles} data-testid="language-selector">
        <Media query={`(min-width: ${breakpoints._970})`}>
          {(matches) => (
            <>
              <Globe
                color={vars.colors.header.content}
                data-testid="language-globe"
                size={matches ? 20 : 28}
                weight="light"
              />
              {matches && (
                <span className={labelStyles} data-testid="language-label">
                  {currentLanguage.code.toUpperCase()}
                </span>
              )}
            </>
          )}
        </Media>
      </div>
      <Tooltip
        content={t(`MultiLanguage.Languages.${currentLanguage.code}`) || currentLanguage.name}
        title={t(`MultiLanguage.Languages.${currentLanguage.code}`) || currentLanguage.name}
      >
        <select
          aria-label={t('Receipt.ReceiptDetails.PrintModal.SelectLanguageHeading')}
          className={selectStyles}
          data-testid="language-selector-dropdown"
          onChange={changeLanguage}
          title={t('Receipt.ReceiptDetails.PrintModal.SelectLanguageHeading')}
          value={currentLanguage.id}
        >
          {availableLanguages?.map((language: LanguageProps) => (
            <option
              key={language.id}
              style={{ color: '#000' }}
              title={language.code}
              value={language.id}
            >
              {t(`MultiLanguage.Languages.${language.code}`) || language.name}
            </option>
          ))}
        </select>
      </Tooltip>
    </div>,
    portal,
  );
};

export default LanguageSelectionButton;
