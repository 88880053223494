import { getPrice, getSelected, getGraphQlSelection } from '../../single-choice/utils/selection';
import getIcon from '../common/utils/icon';
import { CANCELLATION_INSIDE_EU } from '../../products';
import CancellationInsideEu from './components/CancellationInsideEu';
const formSection = CANCELLATION_INSIDE_EU.name;
export const cancellationInsideEu = {
  formSection,
  getIcon,
  getPrice: getPrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getGraphQlSelection: getGraphQlSelection(formSection, 'traveler'),
  ...CANCELLATION_INSIDE_EU
};
export default CancellationInsideEu;