import { useRef, useEffect, useState } from 'react';

export const usePortal = (id) => {
  const ref = useRef(document.createElement('div'));
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    const { current } = ref;
    const parent = document.getElementById(id);

    if (parent) {
      parent.appendChild(current);
    }

    return () => {
      if (parent) {
        parent.removeChild(current);
      }

      setMounted(false);
    };
  }, [id, mounted]);

  return ref.current;
};
