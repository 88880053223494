import { ContentWrapper } from '@eti/components';
import { Columns, Column, breakpoints, Inline } from '@etg/wings';
import { css } from '@eti/styles';
import Media from 'react-media';
import { useAuth } from '@eti/authentication';
import { forwardRef } from 'react';
import TripDetailsTitle from '../../pages/result/components/v3/TripDetailsTitle/TripDetailsTitle';
import { useLanguage } from '../../common/localization/custom-hooks/language';
import SupportMenu from './site_menu/SupportMenu';
import SiteLogo from './SiteLogo';

const contentWrapperStyles = css`
  align-items: center;
  display: flex;
  padding: 8px;

  @media (min-width: ${breakpoints._768}) {
    padding: 16px 15px;
  }
`;

const userLoginStatusStyles = css`
  button[data-testid='myBookings-button-large'] {
    height: 48px;
    padding: 12px 8px;
    width: 64px;

    + div {
      width: auto;
    }
  }

  span {
    gap: 4px;

    > span {
      display: none;
    }
  }
`;

const columnsWrapperStyles = css`
  align-items: center;
`;

const selectLanguageHeaderStyles = css`
  span {
    display: none;
  }
`;

interface SiteHeaderV2Props {
  siteName: string;
  baseUrl: string;
  brand: string;
  shouldShowSupportMenu: boolean;
  supportMenu: any;
}

export const SiteHeaderV2 = forwardRef<HTMLElement, SiteHeaderV2Props>(
  ({ siteName, baseUrl, brand, shouldShowSupportMenu, supportMenu }, ref) => {
    const { lessThanTwoAvailableLanguages } = useLanguage();
    const { user } = useAuth();

    return (
      <ContentWrapper className={contentWrapperStyles}>
        <Columns
          className={columnsWrapperStyles}
          spacing={{
            [breakpoints._0]: 16,
            [breakpoints._970]: 24,
          }}
        >
          <Column data-testid="siteHeader" width={{ [breakpoints._0]: '3/12' }}>
            <SiteLogo baseUrl={baseUrl} brand={brand} ref={ref} siteName={siteName} />
          </Column>

          <Column
            data-testid="trip-details-search-bar"
            width={{ [breakpoints._0]: '9/12', [breakpoints._768]: '8/12' }}
          >
            <TripDetailsTitle />
          </Column>
          <Media query={`(min-width: ${breakpoints._768})`}>
            {(matches) =>
              matches && (
                <Column
                  data-testid="language-and-bookings-button-container"
                  width={{ [breakpoints._768]: '1/12' }}
                >
                  <Inline align="end" alignY="center" noWrap>
                    {!lessThanTwoAvailableLanguages ? (
                      <div
                        className={user ? selectLanguageHeaderStyles : ''}
                        id="select-language-header"
                      />
                    ) : (
                      shouldShowSupportMenu && <SupportMenu menu={supportMenu} />
                    )}
                    {user && (
                      <div className={userLoginStatusStyles} id="my-bookings-button-header" />
                    )}
                  </Inline>
                </Column>
              )
            }
          </Media>
        </Columns>
      </ContentWrapper>
    );
  },
);
