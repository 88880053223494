import { useState, useRef, Fragment } from 'react';
import { observeClickOutside } from 'react-ref-observe';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Question } from '@phosphor-icons/react';
import { vars, breakpoints } from '@etg/wings';
import { css } from '@eti/styles';
import { populateListWithId } from '../../../utils/idGenerator';
import DropdownMenu from './DropdownMenu';

const listItemStyles = css`
  position: relative;
`;

const buttonStyles = css`
  align-items: center;
  background-color: transparent;
  border: none;
  border-radius: 3px;
  color: ${vars.colors.header.content};
  cursor: pointer;
  display: flex;
  gap: 8px;
  padding: 0;

  &:hover {
    background-color: ${vars.colors.header.hover};
    transition: 0.3s;
  }

  @media (min-width: ${breakpoints._970}) {
    padding: 8px;
  }

  @media print {
    display: none;
  }
`;

const labelStyles = css`
  font-size: 0.875rem;
  font-weight: 400;
`;

const SupportMenu = ({ menu }) => {
  const dropdownRef = useRef(null);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(null);

  const openSubMenu = (id) => setIsSubMenuOpen(id);

  const closeSubMenu = () => {
    if (isSubMenuOpen) {
      setIsSubMenuOpen(null);
    }
  };

  observeClickOutside([dropdownRef], closeSubMenu);
  return (
    <ul data-testid="support-menu" ref={dropdownRef}>
      {menu.map(({ id, name, subMenu }) => (
        <Fragment key={id}>
          {subMenu?.length > 0 && (
            <Media query={`(min-width: ${breakpoints._970})`}>
              {(matches) => (
                <li className={listItemStyles}>
                  <button
                    className={buttonStyles}
                    data-testid={`menu-button-${name}`}
                    onClick={() => openSubMenu(id)}
                    title={name}
                    type="button"
                  >
                    <Question
                      color={vars.colors.header.content}
                      size={matches ? 20 : 28}
                      weight="light"
                    />
                    {matches ? <span className={labelStyles}>{name}</span> : null}
                  </button>
                  {isSubMenuOpen === id && <DropdownMenu menu={populateListWithId(subMenu)} />}
                </li>
              )}
            </Media>
          )}
        </Fragment>
      ))}
    </ul>
  );
};

SupportMenu.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      subMenu: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          path: PropTypes.string,
        }),
      ),
    }),
  ),
};

export default SupportMenu;
