import { Redirect, Route, useLocation } from 'react-router-dom';
import { ElementType, useEffect } from 'react';
import { useAuth } from '@eti/authentication';
import { routes } from '../../../constants/routesAndApisConstants';
import { usePageViewDataLayerEvent } from '../../data-layer/dataLayer';

interface LoginRouteProps {
  component: ElementType;
  redirectTo: string;
  setIsMenuVisible: (pathname: { type: string }) => void;
  exact?: boolean;
  path: string;
}

const LoginRoute = ({
  component: Component,
  redirectTo,
  setIsMenuVisible,
  exact,
  path,
}: LoginRouteProps) => {
  const location = useLocation();
  const { user } = useAuth();
  usePageViewDataLayerEvent();
  useEffect(() => {
    setIsMenuVisible({ type: location.pathname });
  }, [setIsMenuVisible, location.pathname]);

  return (
    <Route
      exact={exact}
      path={path}
      render={() =>
        !user ? (
          <Component />
        ) : (
          <Redirect to={{ pathname: user.sessionOrderNumber ? redirectTo : routes.ORDER_LIST }} />
        )
      }
    />
  );
};

export default LoginRoute;
