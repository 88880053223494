import { RouterChildContext } from 'react-router-dom';
import { Route } from '@eti/providers';
import {
  AgeType,
  Brand,
  SelfServiceRebookingTraveler,
  GetSelectedTripOnTravelerDetailsPageQuery,
} from '@eti/schema-types';
import { routes } from '../../constants/routesAndApisConstants';
import {
  AvailableExtraProductType,
  BuySpecificationType,
} from '../../pages/order-details/models/models';
import { TravelerDetailsQuerySelectedTripType } from '../types/models';
import { ExtraProduct, TransformedAndSortedCountriesType } from './models';

export const redirectToActualUrl = (
  brand: Brand,
  history: RouterChildContext['router']['history'],
  actualUrl: string,
  orderBrandCode?: string | null,
  orderBrandName?: string | null,
) => {
  if (brand.code.toLowerCase() === orderBrandCode?.toLowerCase()) {
    window.location.assign(actualUrl);
  } else {
    history.push(routes.NEW_BRAND_REDIRECTION, {
      brand: {
        code: orderBrandCode,
        name: orderBrandName,
        url: actualUrl,
      },
      redirectionType: 'order-login',
    });
  }
};

export const getDomesticInfo = (searchContextRoutes: Route[]) => {
  const domesticRoutes = searchContextRoutes.filter(
    (route) => route.originCountry === route.destinationCountry,
  );
  const isDomestic = searchContextRoutes.length === domesticRoutes?.length;
  const domesticRoute = isDomestic ? domesticRoutes[0] : null;

  return {
    countryCode: domesticRoute?.originCountryCode,
    isDomestic,
  };
};

type sortAccordingToArrayPropsType =
  | {
      buySpecification: BuySpecificationType;
      code: string;
      description: string;
      name: string;
      numberOfIncludedTravelers: number;
      shortDescription: string;
    }
  | (AvailableExtraProductType & { hashId: string });

export const sortAccordingToArray =
  (array: string[]) =>
  (
    a: ExtraProduct | sortAccordingToArrayPropsType,
    b: ExtraProduct | sortAccordingToArrayPropsType,
  ) => {
    const aCode = 'code' in a ? a.code.toLowerCase() : '';
    const bCode = 'code' in b ? b.code.toLowerCase() : '';

    if (array.indexOf(aCode) === -1) {
      return 1;
    }
    if (array.indexOf(bCode) === -1) {
      return -1;
    }
    return array.indexOf(aCode) - array.indexOf(bCode);
  };

type SelectedTripFromTDPageQueryType = TravelerDetailsQuerySelectedTripType;
type FrequentFlyerCardTypesType =
  NonNullable<SelectedTripFromTDPageQueryType>['frequentFlyerCardTypes'];

export const getFrequentFlyerCardTypes = (frequentFlyerCards: FrequentFlyerCardTypesType = []) => {
  return (
    frequentFlyerCards?.map((frequentFlyerCardType) => ({
      label: frequentFlyerCardType?.name ?? '',
      value: frequentFlyerCardType?.id ?? '',
    })) || []
  );
};

export const transformTravelersData = (
  selectedTrip: GetSelectedTripOnTravelerDetailsPageQuery['selectedTrip'],
  travelerDetails: GetSelectedTripOnTravelerDetailsPageQuery['travelerDetails'],
  countries: TransformedAndSortedCountriesType,
  selfServiceRebookingTravelers?: (SelfServiceRebookingTraveler | null)[],
) => {
  const selectedTripTravellers = selectedTrip?.travelers || [];
  const frequentFlyerCardTypes = getFrequentFlyerCardTypes(
    selectedTrip?.frequentFlyerCardTypes ?? [],
  );

  return selectedTripTravellers.map((selectedTripTraveler) => {
    const travelerDetailsTraveler = travelerDetails?.travelers?.find(
      (traveler) => selectedTripTraveler?.id === traveler?.id,
    );

    const { shouldShowTravelDocumentDateOfBirth, ...restTravelDocument } = {
      ...travelerDetailsTraveler?.travelDocument,
    };

    const ssrTraveler = selfServiceRebookingTravelers?.find(
      (selfServiceRebookingTraveler) =>
        selectedTripTraveler?.id === selfServiceRebookingTraveler?.id,
    );

    return {
      age: selectedTripTraveler?.age,
      ageType: selectedTripTraveler?.ageType || AgeType.Adt,
      id: travelerDetailsTraveler?.id,
      shouldShowFrequentFlyerCard: Boolean(travelerDetailsTraveler?.shouldShowFrequentFlyerCard),
      countries,
      frequentFlyerCardTypes,
      showDateOfBirth: shouldShowTravelDocumentDateOfBirth ?? false,
      travelDocument: restTravelDocument,
      dateOfBirth: {
        shouldShowFormInput: Boolean(shouldShowTravelDocumentDateOfBirth),
        hasValueFromSelfServiceRebookingOrder: Boolean(ssrTraveler?.birthDate),
      },
    };
  });
};
