import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from '@eti/providers';
import { BundleProduct, SellSpecificationTravelerChoiceNew, TravelerPrice } from '@eti/types';
import {
  allInclusiveProtection,
  AllInclusiveProtection as EtiAllInclusiveProtection,
} from '@eti/products';
import { getTravelers, getProvinces, handleFetchPrice } from '../../utils/manulifeTransformer';
import { useImperativeQuery } from '../../../../../common/graphql/utils';
import { getTravelerNames } from '../../../utils/travelerNames';
import { GET_ANCILLARY_ITEM } from '../../utils/queries';
import { orderPropTypes as OrderPropTypes } from '../../../utils/orderPropTypes';

const readMore = {
  isEnabled: true,
};

const AllInclusiveProtection = ({
  brand,
  bundleData,
  checkedBaggageSummary,
  className,
  confirmation,
  dispatch,
  form,
  isSeatMapModalOpen,
  isSelectable,
  order,
  productIncludedTexts,
  productSpecificTexts,
  seatMapCallback,
  setIsSeatMapModalOpen,
  sellSpecification,
  shouldShowImage,
  shouldShowMandatoryChoiceText,
  shouldShowPricePerBound,
  travelerNames,
  travelerPrices,
}) => {
  const { t } = useTranslation();
  const travelerLabel = t('Postbooking.Traveler.Description');
  const travelers = getTravelers(order, travelerNames, travelerLabel);
  const fetchPrice = useImperativeQuery(GET_ANCILLARY_ITEM, { fetchPolicy: 'no-cache' });

  return (
    <EtiAllInclusiveProtection
      brand={brand}
      bundleData={bundleData}
      checkedBaggageSummary={checkedBaggageSummary}
      className={className}
      confirmation={confirmation}
      dispatch={dispatch}
      form={form}
      isSeatMapModalOpen={isSeatMapModalOpen}
      isSelectable={isSelectable}
      onFetchPrice={handleFetchPrice(fetchPrice, allInclusiveProtection.id)}
      productIncludedTexts={productIncludedTexts}
      productSpecificTexts={productSpecificTexts}
      provinces={getProvinces(sellSpecification)}
      readMore={readMore}
      seatMapCallback={seatMapCallback}
      setIsSeatMapModalOpen={setIsSeatMapModalOpen}
      shouldShowImage={shouldShowImage}
      shouldShowMandatoryChoiceText={shouldShowMandatoryChoiceText}
      shouldShowPricePerBound={shouldShowPricePerBound}
      travelerPrices={travelerPrices}
      travelers={travelers}
    />
  );
};

AllInclusiveProtection.propTypes = {
  brand: PropTypes.string,
  bundleData: BundleProduct,
  checkedBaggageSummary: PropTypes.shape({
    tripSummaries: PropTypes.arrayOf(
      PropTypes.shape({
        baggageDetails: PropTypes.shape({
          pieces: PropTypes.any,
          weight: PropTypes.number,
          weightUnit: PropTypes.string,
        }),
        baggageStatus: PropTypes.string,
        travelerId: PropTypes.string,
      }),
    ),
  }),
  className: PropTypes.string,
  confirmation: PropTypes.shape({
    isEnabled: PropTypes.bool,
  }),
  dispatch: PropTypes.func,
  form: PropTypes.string,
  isSeatMapModalOpen: PropTypes.bool,
  isSelectable: PropTypes.bool,
  order: PropTypes.shape(OrderPropTypes),
  productIncludedTexts: PropTypes.string,
  productSpecificTexts: PropTypes.string,
  seatMapCallback: PropTypes.func,
  sellSpecification: SellSpecificationTravelerChoiceNew,
  setIsSeatMapModalOpen: PropTypes.func,
  shouldShowImage: PropTypes.bool,
  shouldShowMandatoryChoiceText: PropTypes.bool,
  shouldShowPricePerBound: PropTypes.bool,
  travelerNames: PropTypes.arrayOf(
    PropTypes.shape({
      fullName: PropTypes.string,
      initials: PropTypes.string,
      travelerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ),
  travelerPrices: PropTypes.arrayOf(TravelerPrice),
};

const mapStateToProps = (state, { form, order }) => {
  const travelerNames = getTravelerNames(form, state, order.travelers);

  return { travelerNames };
};

export default connect(mapStateToProps)(AllInclusiveProtection);
