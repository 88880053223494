import { change as changeFormValue } from 'redux-form';
import { connect } from 'react-redux';
import { getSelected } from '../utils/utils';
import ProductBundle from '../components/ProductBundle';
import * as allProducts from '../../products';
const onChange = (dispatch, _ref) => {
  let {
    form,
    options,
    isAncillaryBundlingEnabled
  } = _ref;
  return (_ref2, field) => {
    var _selectedOption$featu;
    let {
      value
    } = _ref2;
    const selectedOption = options === null || options === void 0 ? void 0 : options.find(option => (option === null || option === void 0 ? void 0 : option.id) === value);
    const selectedOptionIds = selectedOption === null || selectedOption === void 0 || (_selectedOption$featu = selectedOption.features) === null || _selectedOption$featu === void 0 ? void 0 : _selectedOption$featu.map(feature => Number(feature === null || feature === void 0 ? void 0 : feature.productTypeId));
    const bundledProducts = Object.entries(allProducts).filter(product => selectedOptionIds === null || selectedOptionIds === void 0 ? void 0 : selectedOptionIds.includes(product[1].id));
    if (isAncillaryBundlingEnabled) {
      const formSections = bundledProducts.map(bundledProduct => bundledProduct[1].name);
      formSections.forEach(formSection => dispatch(changeFormValue(form, `${formSection}.value`, '')));
    }
    dispatch(changeFormValue(form, field, {
      productsInSelection: bundledProducts.map(p => p[1].id),
      value: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.type,
      price: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.priceRaw,
      id: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id
    }));
  };
};
const mapStateToProps = (state, _ref3) => {
  let {
    form
  } = _ref3;
  return {
    selectedValue: getSelected('ancillaryBundle', form)(state)
  };
};
const mapDispatchToProps = (dispatch, props) => ({
  onChange: onChange(dispatch, props)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductBundle);