export const CONTACT_FORM_SECTION_NAME = 'traveler-contact-section';
export const EMERGENCY_COUNTRY = 'traveler-emergency-country';
export const DOCUMENT_ISSUE_COUNTRY = 'traveler-travel-document-issue-country-0';
export const FIRST_TRAVELER = 'traveler-section-0';
export const MAIL = 'traveler-email';
export const MAIL_CONFIRM = 'traveler-email-confirm';
export const MAX_CHARS = 50;
export const MAX_CHARS_LAST_NAME = 40;
export const NEWSLETTER = 'traveler-newsletter';
export const PHONE = 'traveler-phone';
export const EMERGENCY_PHONE = 'traveler-emergency-phone';
export const PHONE_COUNTRY_ID = 'traveler-phone-country-id';
export const EMERGENCY_PHONE_COUNTRY_ID = 'traveler-emergency-phone-country-id';
export const EMERGENCY_LAST_NAME = 'traveler-emergency-last-name';
export const SELECTED_TRAVELER_CONTACT_TYPE = 'selected-traveler-contact-type';
export const TRAVEL_ARRANGER_FIRST_NAME = 'travel-arranger-first-name';
export const TRAVEL_ARRANGER_LAST_NAME = 'travel-arranger-last-name';
export const TRAVELER = 'traveler';