import { formValueSelector } from 'redux-form';
import { isPrimaryFlow } from '../primaryFlow';
export const getFormData = (form, state, name) => formValueSelector(form)(state, name);
export const getSelection = (form, state, name) => {
  return Object.values(getFormData(form, state, name) || {});
};
export const getSelected = (name, form) => state => {
  const selection = getSelection(form, state, name) || [];
  return isPrimaryFlow() ? selection === null || selection === void 0 ? void 0 : selection.filter(_ref => {
    let {
      value
    } = _ref;
    return ['true', 'combined', true].includes(value);
  }) : selection;
};
export const getSelectedPostbooking = formData => {
  var _value$filter;
  const value = formData ? formData.value : null;
  return (_value$filter = value === null || value === void 0 ? void 0 : value.filter(_ref2 => {
    let {
      exclude
    } = _ref2;
    return !exclude;
  })) !== null && _value$filter !== void 0 ? _value$filter : [];
};
export const getNumberOfUnits = (name, form) => state => {
  var _getSelected, _getSelectedPostbooki;
  const formData = getFormData(form, state, name) || {};
  return isPrimaryFlow() ? (_getSelected = getSelected(name, form)(state)) === null || _getSelected === void 0 ? void 0 : _getSelected.length : (_getSelectedPostbooki = getSelectedPostbooking(formData)) === null || _getSelectedPostbooki === void 0 ? void 0 : _getSelectedPostbooki.length;
};
export const getSelectedForTraveler = function (name, form, shouldPrefixFieldWithName) {
  if (shouldPrefixFieldWithName === void 0) {
    shouldPrefixFieldWithName = false;
  }
  return (state, id) => {
    if (!id) {
      return null;
    }
    const prefix = shouldPrefixFieldWithName ? `${name}-` : '';
    const field = `${prefix}traveler-${id}`;
    const formData = getFormData(form, state, name);
    const selectionExists = formData && formData[field];
    return selectionExists ? selectionExists.value : null;
  };
};
export const getSameAsFirstTravelerForCurrentTraveler = (name, form) => (state, id) => {
  const field = `${name}-traveler-${id}`;
  const formData = getFormData(form, state, name);
  const selectionExists = formData && formData[field] && typeof formData[field].hasSameValueAsFirstTraveler === 'boolean';
  return selectionExists ? formData[field].hasSameValueAsFirstTraveler : null;
};
export const getTravelersWithDefaultChoice = (name, form) => state => getFormData(form, state, name);