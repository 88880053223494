import { COLORS } from '../../common/utils/icon/constants';
import { getIcon } from '../../common/utils/icon/icon';
const getStyles = notNeutral => `
  height: auto;
  ${notNeutral && 'margin-top: -1%'};
  width: ${notNeutral ? '63%' : '100%'};
`;
const configuration = {
  colors: [COLORS.blue, COLORS.white],
  getStyles,
  id: 'platinum'
};
export default (type => getIcon({
  ...configuration,
  type
}));