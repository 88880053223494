import { connect } from 'react-redux';
import { change as changeFormvalue } from 'redux-form';
import { getTravelerNames } from '../../common/utils/travelerNames';
import FlexibleTicket from '../components/FlexibleTicket';
const handleModalConfirm = (dispatch, _ref) => {
  let {
    form,
    productName,
    selectedOptions
  } = _ref;
  dispatch(changeFormvalue(form, `${productName}.value`, selectedOptions));
};
const mapStateToProps = (state, _ref2) => {
  let {
    form,
    order
  } = _ref2;
  return {
    travelerNames: getTravelerNames(form, state, order.travelers)
  };
};
const mapDispatchToProps = dispatch => ({
  onModalConfirm: (form, productName, selectedOptions) => handleModalConfirm(dispatch, {
    form,
    productName,
    selectedOptions
  })
});
export default connect(mapStateToProps, mapDispatchToProps)(FlexibleTicket);