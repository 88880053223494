import ContactForm from './containers/ContactFormContainer';
import { getSelected as getSelectedCountry } from './utils/country';
import { getSelected as getSelectedMail } from './utils/mail';
import { getSelectedEmergencyPhone, getSelectedPhone, getSelectedPhoneCountry } from './utils/phone';
import { getSelected as getSelectedNewsletter } from './utils/newsletter';
import { getSelected as getSelectedLastName } from './utils/lastName';
import { getInitialValues } from './utils/options';
import * as fieldNames from './utils/constants';
export const contactForm = {
  ...fieldNames,
  getInitialValues: getInitialValues.bind(null, fieldNames.FIRST_TRAVELER),
  getSelectedCountry,
  getSelectedMail,
  getSelectedPhone,
  getSelectedEmergencyPhone,
  getSelectedPhoneCountry,
  getSelectedNewsletter,
  getSelectedLastName
};
export default ContactForm;