import { Suspense, lazy } from 'react';
import { Inline, SiteFooter, Stack, Heading, vars, Columns, Column } from '@etg/wings';
import { BrandLogo, ContentWrapper, DangerousHtmlContent, PartnerLogo } from '@eti/components';
import { css, mediaQueries } from '@eti/styles';
import { constants, isPartner } from '@eti/utils';
import { useTranslation, useSiteContext, useProperty } from '@eti/providers';
import Media from 'react-media';
import LanguageSelectionDropdown from '../../common/localization/components/LanguageSelectionDropdown';
import { getPartnerSpecificStylesFor } from '../utils/footerUtils';
import Blindado from './Blindado';
import Expand from './Expand';

const SkytraxSeal = lazy(() => import('./SkytraxSeal'));

const { ADR, TRIPSTACK } = constants.brands;

const legalStyles = css`
  font-size: 0.625rem;
  text-align: center;

  @media ${mediaQueries.medium.up} {
    font-size: 0.75rem;
  }
`;

const poweredByStyles = css`
  height: 40px;
  width: auto;
`;

const inlineFooterNavigationStyles = css`
  justify-content: space-evenly;
`;

const expandContentWrapperStyles = css`
  border-bottom: 1px solid ${vars.colors.divider};
  display: flex;
  flex-direction: column;
`;

const headingWrapperStyles = css`
  display: flex;
  justify-content: space-between;
  padding: 0 0 12px;

  @media ${mediaQueries.large.up} {
    padding: 0;
  }
`;

const linkStackStyles = css`
  margin-bottom: 16px;
`;

const linkButtonStyles = css`
  color: ${vars.colors.footer.text};
  font-size: 0.875rem;
`;

const languageDropdownStyles = css`
  max-width: none;

  @media ${mediaQueries.medium.up} {
    max-width: 356px;
  }
`;

const myBookingsButtonStyles = css`
  align-self: stretch;
`;

const stackStyles = css`
  @media ${mediaQueries.small.only} {
    width: 100%;
  }
`;

type IdAndUrlType = { id: string; url: { href: string } }[];

export interface FooterProps {
  brand: string;
  legal: string;
  menus: { mainMenuItems: { name: string; path: string }[] }[];
  paymentProviders: IdAndUrlType;
  trustedPartners: IdAndUrlType;
}

const Footer = ({ brand, legal, paymentProviders, trustedPartners, menus = [] }: FooterProps) => {
  const { t } = useTranslation();
  const { p } = useProperty();
  const { availableLanguages } = useSiteContext();

  const Titles: string[] = [
    t('Footer.ProductsAndServices.Text'),
    t('Footer.Company.Text'),
    t('Footer.Help.Text'),
  ];
  const isFooterMenuEnabled: boolean = p('IbeClient.FooterMenu.Enabled');
  const isBlindadoEnabled = p('IbeClient.Blindado.Enabled');

  return (
    <SiteFooter data-testid="siteFooter">
      <ContentWrapper>
        <Stack spacing={48}>
          {isFooterMenuEnabled && (
            <Columns
              className={inlineFooterNavigationStyles}
              collapseBelow="970px"
              data-testid="footer-navigationMenu-container"
            >
              {menus.map((menu, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Column key={`menu-${index}`} width="auto">
                  <Media query={mediaQueries.large.up}>
                    {(matches) =>
                      !matches ? (
                        <Expand
                          classNames={{
                            content: expandContentWrapperStyles,
                            heading: headingWrapperStyles,
                          }}
                          dataTestId={Titles[index]}
                          title={Titles[index]}
                        >
                          <Stack
                            className={linkStackStyles}
                            data-testid={`expandContentStack-${Titles[index]}`}
                          >
                            {menu.mainMenuItems.map((mainMenuItem) => (
                              <a
                                className={linkButtonStyles}
                                data-testid={`expandLink-${mainMenuItem.path.slice(1)}`}
                                href={mainMenuItem.path}
                                key={`linkbutton-${mainMenuItem.name}`}
                                role="menuitem"
                              >
                                {mainMenuItem.name}
                              </a>
                            ))}
                          </Stack>
                        </Expand>
                      ) : (
                        <Stack>
                          <Heading level={4}>{Titles[index]}</Heading>
                          <Stack role="menu">
                            {menu.mainMenuItems.map((mainMenuItem) => (
                              <a
                                className={linkButtonStyles}
                                href={mainMenuItem.path}
                                key={`linkbutton-${mainMenuItem.name}`}
                                role="menuitem"
                              >
                                {mainMenuItem.name}
                              </a>
                            ))}
                          </Stack>
                        </Stack>
                      )
                    }
                  </Media>
                </Column>
              ))}
              <Column>
                <Stack>
                  <Heading
                    className={headingWrapperStyles}
                    data-testid="login-and-language-header"
                    level={4}
                  >
                    {availableLanguages.length < 2
                      ? t('Footer.Login.Text')
                      : t('Footer.LoginAndLanguage.Text')}
                  </Heading>
                  <Stack align="start">
                    <Stack align="center" className={stackStyles}>
                      <div className={myBookingsButtonStyles} id="my-bookings-button-footer" />
                      {brand === ADR && (
                        <Suspense fallback="">
                          <SkytraxSeal />
                        </Suspense>
                      )}
                    </Stack>
                  </Stack>
                  <LanguageSelectionDropdown
                    isShownInFooter
                    languageDropdownStyles={languageDropdownStyles}
                  />
                  {isBlindadoEnabled && <Blindado />}
                </Stack>
              </Column>
            </Columns>
          )}
          <Stack>
            {isPartner(brand) && brand !== TRIPSTACK && (
              <SiteFooter.PoweredBy>
                <Inline align="center">
                  <BrandLogo brand="poweredbygotogate" className={poweredByStyles} />
                </Inline>
              </SiteFooter.PoweredBy>
            )}
            {paymentProviders && paymentProviders.length > 0 && (
              <SiteFooter.PaymentProviders data-testid="siteFooter-paymentProviders-wrapper">
                <Inline align="center" alignY="center" as="ul" spacing={24}>
                  {paymentProviders.map(({ id, url }) => (
                    <PartnerLogo
                      className={getPartnerSpecificStylesFor(id)}
                      collection="payment-providers"
                      href={url?.href}
                      id={id}
                      key={id}
                    />
                  ))}
                </Inline>
              </SiteFooter.PaymentProviders>
            )}
            {trustedPartners && trustedPartners.length > 0 && (
              <SiteFooter.TrustedPartners data-testid="siteFooter-trustedPartners-wrapper">
                <Inline align="center" alignY="center" as="ul" spacing={24}>
                  {trustedPartners.map(({ id, url }) => (
                    <PartnerLogo
                      className={getPartnerSpecificStylesFor(id)}
                      collection="trusted-partners"
                      href={url?.href}
                      id={id}
                      key={id}
                    />
                  ))}
                </Inline>
              </SiteFooter.TrustedPartners>
            )}
            <Inline align="center">
              <DangerousHtmlContent className={legalStyles} content={legal} />
            </Inline>
          </Stack>
        </Stack>
      </ContentWrapper>
    </SiteFooter>
  );
};

export default Footer;
