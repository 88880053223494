const supportedDecks = ['upperDeck', 'mainDeck', 'lowerDeck'];
const filterDeckType = decks => _ref => {
  let [key, val] = _ref;
  const hasCabins = val && typeof val === 'object' && Array.isArray(val.cabins) && val.cabins.length > 0;
  return decks.some(deck => deck === key && hasCabins);
};
export const getAvailableDecksForSegment = segment => segment ? Object.entries(segment).filter(filterDeckType(supportedDecks)).map(_ref2 => {
  let [key] = _ref2;
  return key;
}) : [];
const getOptionLabel = (deck, upperDeckLabel, mainDeckLabel, lowerDeckLabel) => {
  switch (deck) {
    case 'upperDeck':
      return upperDeckLabel;
    case 'lowerDeck':
      return lowerDeckLabel;
    default:
      return mainDeckLabel;
  }
};
export const getAvailableDeckOptions = (segment, upperDeckLabel, mainDeckLabel, lowerDeckLabel) => segment && mainDeckLabel && lowerDeckLabel && upperDeckLabel ? getAvailableDecksForSegment(segment).map(deck => ({
  label: getOptionLabel(deck, upperDeckLabel, mainDeckLabel, lowerDeckLabel),
  value: deck
})) : [];
export const getCabins = (segment, selectedDeck) => selectedDeck && segment && segment[selectedDeck.value] ? segment[selectedDeck.value].cabins : [];