import { travelerForm } from '@eti/travelers';
import { string, getTravelerInitials } from '@eti/utils';

const getTravelerFirstName = (form, state, travelerIndex) =>
  travelerForm.getSelectedFirstName(form)(state, travelerIndex);

const getTravelerLastName = (form, state, travelerIndex) =>
  travelerForm.getSelectedLastName(form)(state, travelerIndex);

export const getTravelerNames = (form, state, travelers) =>
  travelers.reduce((names, traveler, index) => {
    const firstName = getTravelerFirstName(form, state, index);
    const lastName = getTravelerLastName(form, state, index);
    return firstName && lastName
      ? [
          ...names,
          {
            travelerId: traveler.id,
            fullName: `${firstName} ${lastName}`,
            initials: getTravelerInitials(firstName, lastName),
            firstName,
            lastName,
          },
        ]
      : names;
  }, []);

export const setTravelerName = (
  travelerId,
  travelerNames = [],
  defaultTravelerLabel,
  travelerIndex,
) => {
  const travelerWithName = travelerNames.find((traveler) => traveler.travelerId === travelerId);
  return travelerWithName
    ? travelerWithName.fullName
    : string.insertArgument(defaultTravelerLabel, travelerIndex);
};
