import { constants } from '@eti/utils';
import { change as changeFormValue } from 'redux-form';
export const setChoiceValueForTraveler = (dispatch, productName, _ref) => {
  let {
    sellSpecification
  } = _ref;
  return (form, traveler, value) => {
    var _sellSpecification$se, _sellSpecification$se2;
    const {
      id,
      markup,
      price,
      ageType
    } = traveler;
    if (ageType === constants.ageTypes.INFANT) {
      return;
    }
    const {
      maxWeight: weight,
      numberOfUnits: numberOfPieces,
      weightUnit
    } = (_sellSpecification$se = sellSpecification === null || sellSpecification === void 0 || (_sellSpecification$se2 = sellSpecification.sellPriceBaggage) === null || _sellSpecification$se2 === void 0 ? void 0 : _sellSpecification$se2[0]) !== null && _sellSpecification$se !== void 0 ? _sellSpecification$se : {};
    const formattedValue = value === 'true';
    dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${id}`, {
      value: value !== null && value !== void 0 ? value : null,
      id,
      markup: formattedValue ? markup : null,
      priceRaw: formattedValue ? price !== null && price !== void 0 ? price : 0 : 0,
      numberOfPieces,
      weight,
      weightUnit
    }));
  };
};
export const setCombinedChoiceValueForTraveler = (dispatch, productName, _ref2) => {
  let {
    sellSpecification,
    secondBagProductDetails
  } = _ref2;
  return (form, traveler, secondBagTraveler, value) => {
    var _sellSpecification$se3, _sellSpecification$se4, _secondBagProductDeta, _secondBagProductDeta2;
    const {
      id,
      markup,
      price
    } = traveler;
    const {
      markup: secondbagmarkup,
      price: secondbagprice
    } = secondBagTraveler || {};
    const formattedValue = value === 'combined';
    const {
      maxWeight: weight,
      numberOfUnits: numberOfPieces,
      weightUnit
    } = (_sellSpecification$se3 = sellSpecification === null || sellSpecification === void 0 || (_sellSpecification$se4 = sellSpecification.sellPriceBaggage) === null || _sellSpecification$se4 === void 0 ? void 0 : _sellSpecification$se4[0]) !== null && _sellSpecification$se3 !== void 0 ? _sellSpecification$se3 : {};
    const {
      maxWeight: weightOfSecondBag,
      numberOfUnits: piecesOfSecondBag,
      weightUnit: weightUnitOfSecondBag
    } = (_secondBagProductDeta = (_secondBagProductDeta2 = secondBagProductDetails[0]) === null || _secondBagProductDeta2 === void 0 || (_secondBagProductDeta2 = _secondBagProductDeta2.sellSpecification) === null || _secondBagProductDeta2 === void 0 || (_secondBagProductDeta2 = _secondBagProductDeta2.sellPriceBaggage) === null || _secondBagProductDeta2 === void 0 ? void 0 : _secondBagProductDeta2[0]) !== null && _secondBagProductDeta !== void 0 ? _secondBagProductDeta : {};
    dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${id}`, {
      value: value !== null && value !== void 0 ? value : null,
      id,
      markup: formattedValue ? (secondbagmarkup !== null && secondbagmarkup !== void 0 ? secondbagmarkup : 0) + (markup !== null && markup !== void 0 ? markup : 0) : null,
      priceRaw: formattedValue ? (secondbagprice !== null && secondbagprice !== void 0 ? secondbagprice : 0) + (price !== null && price !== void 0 ? price : 0) : 0,
      numberOfPieces: secondBagTraveler !== null && weight === weightOfSecondBag ? (numberOfPieces || 0) + (piecesOfSecondBag || 0) : numberOfPieces,
      piecesOfSecondBag,
      weight,
      weightOfSecondBag,
      weightUnit,
      weightUnitOfSecondBag
    }));
  };
};
export const setSecondBagValueForTraveler = (dispatch, productName, _ref3) => {
  let {
    secondBagProductDetails
  } = _ref3;
  return (form, secondBagTraveler, checkboxvalue) => {
    var _secondBagProductDeta3, _secondBagProductDeta4;
    const {
      id: secondbagid,
      markup: secondbagmarkup,
      price: secondbagprice
    } = secondBagTraveler || {};
    const {
      maxWeight: weightOfSecondBag,
      numberOfUnits: piecesOfSecondBag,
      weightUnit: weightUnitOfSecondBag
    } = (_secondBagProductDeta3 = (_secondBagProductDeta4 = secondBagProductDetails[0]) === null || _secondBagProductDeta4 === void 0 || (_secondBagProductDeta4 = _secondBagProductDeta4.sellSpecification) === null || _secondBagProductDeta4 === void 0 || (_secondBagProductDeta4 = _secondBagProductDeta4.sellPriceBaggage) === null || _secondBagProductDeta4 === void 0 ? void 0 : _secondBagProductDeta4[0]) !== null && _secondBagProductDeta3 !== void 0 ? _secondBagProductDeta3 : {};
    dispatch(changeFormValue(form, `${productName}.${productName}-traveler-${secondbagid}`, {
      value: checkboxvalue !== null && checkboxvalue !== void 0 ? checkboxvalue : null,
      included: true,
      id: secondbagid,
      markup: checkboxvalue ? secondbagmarkup : null,
      priceRaw: checkboxvalue ? secondbagprice : 0,
      piecesOfSecondBag,
      weightOfSecondBag,
      weightUnitOfSecondBag
    }));
  };
};