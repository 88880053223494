import { formValueSelector, getFormAsyncErrors } from 'redux-form';
import { FIRST_TRAVELER, PHONE, PHONE_COUNTRY_ID, EMERGENCY_PHONE } from './constants';
export const getInitialValues = (phone, phoneCountry) => {
  var _phone$value;
  return {
    [PHONE]: (_phone$value = phone === null || phone === void 0 ? void 0 : phone.value) !== null && _phone$value !== void 0 ? _phone$value : '',
    [PHONE_COUNTRY_ID]: phoneCountry !== null && phoneCountry !== void 0 ? phoneCountry : ''
  };
};
export const getSelectedPhone = form => state => ({
  [PHONE]: formValueSelector(form)(state, `${FIRST_TRAVELER}.${PHONE}`)
});
export const getSelectedEmergencyPhone = form => state => ({
  [EMERGENCY_PHONE]: formValueSelector(form)(state, `${FIRST_TRAVELER}.${EMERGENCY_PHONE}`)
});
export const getSelectedPhoneCountry = form => state => formValueSelector(form)(state, `${FIRST_TRAVELER}.${PHONE_COUNTRY_ID}`) || null;
export const isPhoneError = form => state => {
  const errors = getFormAsyncErrors(form)(state);
  return (errors && errors[FIRST_TRAVELER] && errors[FIRST_TRAVELER][PHONE]) !== undefined;
};
export const isEmergencyPhoneError = form => state => {
  const errors = getFormAsyncErrors(form)(state);
  return (errors && errors[FIRST_TRAVELER] && errors[FIRST_TRAVELER][EMERGENCY_PHONE]) !== undefined;
};
const numericFormat = /^[0-9]*$/;
export const isNumber = x => numericFormat.test(x);