import { gql } from '@eti/schema-types';

export const GET_PRICES_PER_DAY = gql(/* GraphQL */ `
  query getBestPricesPerDay(
    $destination: String!
    $numberOfDays: Int!
    $origin: String!
    $startDate: String!
  ) {
    bestPricesPerDay(
      destination: $destination
      origin: $origin
      numberOfDays: $numberOfDays
      startDate: $startDate
    ) {
      bestPricesPerDay {
        date
        priceCategory
      }
    }
  }
`);
