import { formValueSelector } from 'redux-form';
import { FIRST_TRAVELER, DOCUMENT_ISSUE_COUNTRY } from './constants';
export const getInitialValues = _ref => {
  let {
    options
  } = _ref;
  return {
    [DOCUMENT_ISSUE_COUNTRY]: options ? options && options.find(opt => opt.selected) : ''
  };
};
export const getSelected = form => state => formValueSelector(form)(state, `${FIRST_TRAVELER}.${DOCUMENT_ISSUE_COUNTRY}`);