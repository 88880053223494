import { css } from '@eti/styles';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfWeek from 'date-fns/endOfWeek';
import startOfWeek from 'date-fns/startOfWeek';
import { Text } from '@etg/wings';
import { Locale, formatWeekdayName } from '../utils/calendarLocales';

const calendarHeaderDays = css`
  background-color: #fff;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  inset-block-start: -1px;
  margin-inline: 4px;
  padding-block-end: 8px;
  position: sticky;
  z-index: 10;
`;

const calendarHeaderDay = css`
  color: #737373;
  text-align: center;
`;

interface DaysHeaderProps {
  locale?: Locale;
}

const DaysHeader = ({ locale }: DaysHeaderProps) => {
  const now = new Date();

  const days = eachDayOfInterval({
    start: startOfWeek(now, { locale }),
    end: endOfWeek(now, { locale }),
  }).map((day) => formatWeekdayName(day, { locale }));

  return (
    <div className={calendarHeaderDays} data-testid="calendar-days-header">
      {days.map((day) => (
        <Text
          className={calendarHeaderDay}
          data-testid="calendar-days-header-day"
          key={day?.toString()}
          variant="14"
        >
          {day}
        </Text>
      ))}
    </div>
  );
};

export default DaysHeader;
