import PropTypes from 'prop-types';

export const ID = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

export const BigDecimal = PropTypes.any

export const DateString = PropTypes.any

export const Long = PropTypes.number

export const AddToCartResponseType = PropTypes.oneOf(['SUCCESS','VALIDATION_FAILED']);

export const AgeType = PropTypes.oneOf(['ADT','CNN','INF']);

export const AuthenticationType = PropTypes.oneOf(['ORDER_INFO','FACEBOOK','GOOGLE','AGENCY_ACTIVATION']);

export const BaggageStatus = PropTypes.oneOf(['INFANT_NO_CHECKED_BAGGAGE','INCLUDED_CHECKED_BAGGAGE','NO_CHECKED_BAGGAGE','ADDED_CHECKED_BAGGAGE','NO_CHECKED_BAGGAGE_AVAILABLE']);

export const BestPriceCategory = PropTypes.oneOf(['HIGH','LOW','MEDIUM','NOT_AVAILABLE']);

export const BindingResponse = PropTypes.oneOf(['SUCCESS','UNAUTHORIZED','UNAVAILABLE']);

export const BrandedFareAvailabilityType = PropTypes.oneOf(['INCLUDED','NOT_INCLUDED','SELLABLE','UNKNOWN']);

export const CabinBaggageStatus = PropTypes.oneOf(['INCLUDED_CABIN_BAGGAGE','ADDED_CABIN_BAGGAGE','INCLUDED_AND_ADDED_CABIN_BAGGAGE','NO_CABIN_BAGGAGE','INFANT_NO_CABIN_BAGGAGE','NO_CABIN_BAGGAGE_AVAILABLE']);

export const CabinClass = PropTypes.oneOf(['ECONOMY','PREMIUM_BUSINESS','PREMIUM_ECONOMY','PREMIUM_FIRST','BUSINESS','FIRST']);

export const CancelOrderErrorCode = PropTypes.oneOf(['TIME_EXPIRED','NO_ORDER_IN_SESSION','ORDER_ALREADY_CANCELLED','CANCELLATION_PROCESS_FAILED']);

export const CancellationRefundOfferReason = PropTypes.oneOf(['VOID','CANCEL_FOR_ANY_REASON']);

export const CartState = PropTypes.oneOf(['SHOPPING','ORDER_PENDING','TIMED_OUT','AWAITING_CUSTOMER_PAYMENT','CANCELED','AWAITING_MANUAL_FRAUD_REVIEW','PENDING_PROVIDER_BOOKING','AWAITING_MOP_CART_PAYMENT','PENDING_PROVIDER_REBOOKING','PRICE_CHANGE','AWAITING_PARTNER_PAYMENT','AWAITING_EXTERNAL_PAYMENT_VERIFICATION','MANUAL_BOOKING','AWAITING_BOOKING_DETAILS_UPDATE','EXTERNAL_FLIGHT_VERIFICATION']);

export const CartType = PropTypes.oneOf(['PRIMARY','ADD_ON','MOP_CART']);

export const ChangeReason = PropTypes.oneOf(['CANCEL_PART_OF_ORDER']);

export const CheckInStatusErrorCode = PropTypes.oneOf(['REGISTRATION_NOT_FOUND','GENERIC_ERROR']);

export const EventSegmentType = PropTypes.oneOf(['STOP','CHANGE_OF_AIRPORT','TRANSFER_TO_AIRPORT','TRANSFER_TO_TRAIN','TRANSFER_TO_BUS','OVERNIGHT_STAY','SELF_TRANSFER','SHORT_STOPOVER','ONE_DAY_STOPOVER','ONE_NIGHT_STOPOVER','TWO_NIGHTS_STOPOVER']);

export const FilterOptionType = PropTypes.oneOf(['AMOUNT','DURATION','NUMBER','TEXT','TIME']);

export const FilterType = PropTypes.oneOf(['AIRLINES','CHECKED_BAGGAGE_INCLUDED','EXCLUDE_SELF_TRANSFER','MAX_STOPS','PRICE','STOPOVER_TYPES','TRAVEL_TIME','TRIP_0_DEPARTURE_TIME','TRIP_0_ARRIVAL_TIME','TRIP_1_DEPARTURE_TIME','TRIP_1_ARRIVAL_TIME','TRIP_2_DEPARTURE_TIME','TRIP_2_ARRIVAL_TIME','TRIP_3_DEPARTURE_TIME','TRIP_3_ARRIVAL_TIME','TRIP_4_DEPARTURE_TIME','TRIP_4_ARRIVAL_TIME']);

export const GenderInput = PropTypes.oneOf(['MALE','FEMALE']);

export const LoginSource = PropTypes.oneOf(['ORDER_LOGIN','CONTACT_US_LOGIN','POSTBOOKING_LOGIN']);

export const LogoutUserResponsePayload = PropTypes.oneOf(['SUCCESS','SYSTEM_ERROR']);

export const OrderState = PropTypes.oneOf(['INITIAL','CHANGED','CANCELED','UNCHANGED','UNKNOWN']);

export const OrderStatusState = PropTypes.oneOf(['CONFIRMED','PROCESSING']);

export const PassengerLegStatus = PropTypes.oneOf(['SUCCESS','FAILED','MISSED','PENDING','SUSPENDED']);

export const PaymentMethodType = PropTypes.oneOf(['BANK','CARD','WALLET','APM']);

export const PaymentResponseType = PropTypes.oneOf(['PAYMENT_PAGE_WITH_MESSAGE','PAYMENT_REDIRECT','PAYMENT_PENDING_EXTERNAL_ACTION','RECEIPT','PROCESSING_ORDER','PRICE_CHANGE']);

export const PaymentStatus = PropTypes.oneOf(['COMPLETED','PENDING','ERROR']);

export const PersonalItemStatus = PropTypes.oneOf(['INCLUDED_PERSONAL_ITEM','NO_PERSONAL_ITEM','INFANT_NO_PERSONAL_ITEM']);

export const ProductPreSelection = PropTypes.oneOf(['NONE','NO_SELECTED','YES_SELECTED']);

export const ProviderBookingResult = PropTypes.oneOf(['SUCCESS','REJECTED_OTHER','REJECTED_CHARGE_BACK','REJECTED_BY_AIRLINE','REJECTED_MEDICAL_REASON','REJECTED_REFUND_LOWER_THAN_FEE','REJECTED_AIRLINE_COVID_19_POLICY','REJECTED_NO_RESPONSE_FROM_AIRLINE','REJECTED_AIRLINE_DECLARED_BANKRUPTCY','REJECTED_AIRLINE_DECLARED_FINANCIAL_RECONSTRUCTION','REJECTED_AIRLINE_REFUNDED_CUSTOMER_DIRECTLY','REJECTED_AIRLINE_SUSPENDED']);

export const RebookEligibilityReason = PropTypes.oneOf(['SHORT_DEPARTURE_TRIP','MULTISTOP_NOT_SUPPORTED','CARRIER_NOT_SUPPORTED','SYSTEM_NOT_SUPPORTED','NOT_FULLY_TICKETED','SEGMENT_STATUS_NOT_CONFIRMED','EXCHANGED_BY_AIRLINE','NOT_OPEN_TICKET','FARE_RESTRICTION','ELIGIBLE','PRICE_REVIEW_DEACTIVATED']);

export const RebookEligibilityStatus = PropTypes.oneOf(['CHANGE_PNR_BY_AIRLINE','CHANGE_PNR_BY_CUSTOMER','CHANGE_PNR_BY_ETG','CHANGE_NOT_AVAILABLE']);

export const RefundCaseCancelReason = PropTypes.oneOf(['ANCILLARY_VOLUNTARY','BEFORE_TICKETING','CANCELLATION_GUARANTEE','CANCELLATION_GUARANTEE_PREMIUM','CANCELLATION_FOR_ANY_REASON','CANCELLATION_PROTECTION_ETG','CHARGEBACK','CHARGEBACK_CLAIM','CHARGEBACK_NO_REFUND','CONNECTION_GUARANTEE','COVID_19_NO_CUSTOMER_CONTACT','FRAUD','FRAUD_BEFORE_DEPARTURE','FRAUD_NO_REFUND','FORCE_MAJEURE_REFUND','GDPR_WAIVER','INTERNAL_REFUND','LOCK_YOUR_PRICE','ORPHANED_FUNDS','SCHEDULE_CHANGE_REFUND','TECH_ERROR_ANCILLARY','TECH_ERROR_TICKET','TICKET_RULES_AIRLINE_FEE','TICKET_RULES_NO_REFUND_TO_CUSTOMER','TICKET_RULES_TAXES','TWENTY_FOUR_HOUR_AIRLINE_RULE','VOID_REASON','VOLUNTARY_GDS','UNDEFINED','MANUAL_ESCALATION','UNKNOWN']);

export const RefundCaseStep = PropTypes.oneOf(['CUSTOMER_REQUESTED_REFUND_FROM_PROVIDER','MONEY_PAYED_OUT_TO_CUSTOMER','MONEY_RECEIVED_FROM_PROVIDER','REFUND_REQUEST_RECEIVED_FROM_CUSTOMER','REQUESTED_REFUND_FROM_PROVIDER']);

export const ResponseUsage = PropTypes.oneOf(['CUSTOMER_SALES_PROCESS','PRICE_COMPARISON','ONLINE_MARKETING','CACHE_POPULATION','CUSTOMER_DRIVEN_AUTOMATION','PRICE_ALERT','CROSS_SELL']);

export const SearchLocationType = PropTypes.oneOf(['CITY','AIRPORT']);

export const SeatCharacteristic = PropTypes.oneOf(['BULKHEAD','LEG_SPACE','BASSINET','FRONT_OF_CABIN','ELECTRONIC_CONNECTION','SUITABLE_FOR_ADT_WITH_INF','ECONOMY_COMFORT','NOT_SUITABLE_FOR_CHILDREN','NO_INFANT_ALLOWED','NORMAL']);

export const SeatPreferenceSelectionInput = PropTypes.oneOf(['BESIDE','AISLE','WINDOW']);

export const SendEmailResponse = PropTypes.oneOf(['FAILURE','SUCCESS']);

export const SortType = PropTypes.oneOf(['CARRIER_PROMO','CHEAP_TRIP','SHORT_TRIP','RECOMMENDATION','BEST_VALUE','LONG_STAY']);

export const TaxCategoryType = PropTypes.oneOf(['AIR_COST','FARE','THIRD_PARTY','UNKNOWN']);

export const Tier = PropTypes.oneOf(['BASIC','RECOMMENDED','PREMIUM','DELUXE']);

export const TimeOfDay = PropTypes.oneOf(['ANY','MORNING','AFTERNOON','NOON']);

export const TravelerDetailsTravelDocumentExpirationDate = PropTypes.oneOf(['NOT_REQUIRED','OPTIONAL','REQUIRED']);

export const TripCharacteristics = PropTypes.oneOf(['ONE_WAY_COMBINATION','VALID_WITH_VOUCHER','VIRTUAL_INTERLINING','VIRTUAL_INTERLINING_WITH_SHORT_CONNECTION_TIME']);

export const TripTag = PropTypes.oneOf(['SPONSOR','PRICE','SPEED','STAY','WEIGHTED']);

export const TripType = PropTypes.oneOf(['ONE_WAY','RETURN','MULTI_STOP','OPEN_JAW_SINGLE_ORIGIN','OPEN_JAW_SINGLE_DESTINATION','OPEN_JAW_DOUBLE']);

export const status = PropTypes.oneOf(['SUCCESS','NOT_ACCEPTED','UNKNOWN']);

export const AddToAddonCartResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const AddToCartResponse = PropTypes.shape({
  success: PropTypes.bool,
  type: AddToCartResponseType,
  message: PropTypes.string,
});

export const AirSystemSettings = PropTypes.shape({
  notificationMessageHeadLineAirProducts: PropTypes.string,
  notificationMessageAirProducts: PropTypes.string,
});

export const ApisInfo = PropTypes.shape({
  description: PropTypes.string,
  title: PropTypes.string,
});

export const ApplePayAdditionalData = PropTypes.shape({
  code: PropTypes.string,
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  merchantIdentifier: PropTypes.string,
  merchantCapabilities: PropTypes.arrayOf(PropTypes.string),
  supportedNetworks: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  totalAmount: PropTypes.string,
});

export const AvailablePaymentInstalmentOption = PropTypes.shape({
  installmentOptionId: Long,
  numberOfInstalments: PropTypes.number,
  ratePercentage: BigDecimal,
  baseCostPerInstalment: BigDecimal,
});

export const BankDataAdyen = PropTypes.shape({
  brandCode: PropTypes.string,
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  issuerId: PropTypes.string,
  merchantAccount: PropTypes.string,
  merchantReference: PropTypes.string,
  merchantReturnData: PropTypes.string,
  merchantSig: PropTypes.string,
  paymentAmount: PropTypes.string,
  resURL: PropTypes.string,
  sessionValidity: PropTypes.string,
  shipBeforeDate: PropTypes.string,
  shopperLocale: PropTypes.string,
  skinCode: PropTypes.string,
});

export const BankDataDummy = PropTypes.shape({
  bankRedirectURL: PropTypes.string,
});

export const BankDataOgone = PropTypes.shape({
  bankRedirectURL: PropTypes.string,
});

export const BankData = PropTypes.oneOfType([BankDataAdyen,BankDataOgone,BankDataDummy]);

export const BankPaymentMethodTexts = PropTypes.shape({
  descriptionText: PropTypes.string,
});

export const BestPriceBound = PropTypes.shape({
  origin: PropTypes.string,
  destination: PropTypes.string,
  date: PropTypes.string,
});

export const BestPriceCharacteristic = PropTypes.shape({
  characteristic: PropTypes.string,
  price: Long,
});

export const BestPricePerDay = PropTypes.shape({
  price: PropTypes.number,
  priceCategory: BestPriceCategory,
  date: PropTypes.string,
});

export const BestPricePerPeriod = PropTypes.shape({
  price: PropTypes.number,
  period: PropTypes.number,
  bounds: PropTypes.arrayOf(BestPriceBound),
});

export const BestPricesPerDay = PropTypes.shape({
  bestPricesPerDay: PropTypes.arrayOf(BestPricePerDay),
});

export const BestPricesPerPeriod = PropTypes.shape({
  bestPricesPerPeriod: PropTypes.arrayOf(BestPricePerPeriod),
});

export const BindOrderToSessionResponse = PropTypes.shape({
  response: BindingResponse,
  hostNameForRealSite: PropTypes.string,
  brandCodeForRealSite: PropTypes.string,
  brandNameForRealSite: PropTypes.string,
});

export const BoundInformation = PropTypes.shape({
  origin: PropTypes.string,
  originCityCode: PropTypes.string,
  destination: PropTypes.string,
  destinationCityCode: PropTypes.string,
  arrivedAt: PropTypes.string,
  departuredAt: PropTypes.string,
});

export const Brand = PropTypes.shape({
  name: PropTypes.string,
  code: PropTypes.string,
  id: Long,
});

export const BrandInformationResponse = PropTypes.shape({
  urlForActualSite: PropTypes.string,
  brandNameForActualSite: PropTypes.string,
});

export const BrandedFareFeature = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  group: PropTypes.string,
  availability: BrandedFareAvailabilityType,
});

export const BrandedFareInfo = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  features: PropTypes.arrayOf(BrandedFareFeature),
});

export const Cadillac = PropTypes.shape({
  siteKey: PropTypes.string,
  url: PropTypes.string,
  filename: PropTypes.string,
});

export const CancelMakeOrderResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const CancelOrderResponse = PropTypes.shape({
  success: PropTypes.bool,
  errorCode: CancelOrderErrorCode,
});

export const CancelOrderWithRefundOfferResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const CancellationRefundOfferProduct = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  code: PropTypes.string,
  amount: Long,
});

export const CarrierBookingNumber = PropTypes.shape({
  bookingNumber: PropTypes.string,
  carrierCode: PropTypes.string,
  carrierName: PropTypes.string,
});

export const CarrierConditions = PropTypes.shape({
  url: PropTypes.string,
  description: PropTypes.string,
});

export const CarrierNew = PropTypes.shape({
  name: PropTypes.string,
  code: PropTypes.string,
  conditions: CarrierConditions,
});

export const CarrierInfo = PropTypes.shape({
  carrier: CarrierNew,
  description: PropTypes.string,
  title: PropTypes.string,
});

export const CarrierSettings = PropTypes.shape({
  notificationHeadlineMarketingCarrier: PropTypes.string,
  notificationHeadlineOperatingCarrier: PropTypes.string,
  notificationMessageMarketingCarrier: PropTypes.string,
  notificationMessageOperatingCarrier: PropTypes.string,
  carrierSpecificUrl: PropTypes.string,
});

export const CheckedBaggage = PropTypes.shape({
  pieces: PropTypes.number,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const ClientSideEncryption3DSecureData = PropTypes.shape({
  paReq: PropTypes.string,
  md: PropTypes.string,
  termUrl: PropTypes.string,
});

export const ClientSideEncryptionPaymentFlow = PropTypes.shape({
  type: PropTypes.string,
  publicKey: PropTypes.string,
  generationTime: PropTypes.string,
});

export const ConfigurationContext = PropTypes.shape({
  persuasionEnabled: PropTypes.bool,
  showPricePerBound: PropTypes.bool,
  showPricePerTraveler: PropTypes.bool,
  highlightProduct: PropTypes.bool,
});

export const Configuration = PropTypes.shape({
  travelerDetails: ConfigurationContext,
  postbookingPayment: ConfigurationContext,
  postbookingAncillaryStore: ConfigurationContext,
  productPreSelection: ProductPreSelection,
});

export const ContactUsDetails = PropTypes.shape({
  scheduleDescription: PropTypes.string,
  englishScheduleTitle: PropTypes.string,
  englishSchedule: PropTypes.string,
  localScheduleTitle: PropTypes.string,
  localSchedule: PropTypes.string,
  scheduleException: PropTypes.string,
  phoneNumber: PropTypes.string,
  operatorClarification: PropTypes.string,
});

export const Currency = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  exponent: PropTypes.number,
});

export const CurrencyAmount = PropTypes.shape({
  value: Long,
  currency: Currency,
});

export const Bank = PropTypes.shape({
  id: ID,
  code: PropTypes.string,
  name: PropTypes.string,
  price: CurrencyAmount,
  serviceFee: CurrencyAmount,
});

export const BankPaymentMethod = PropTypes.shape({
  type: PropTypes.string,
  paymentServiceProvider: PropTypes.string,
  banks: PropTypes.arrayOf(Bank),
  texts: BankPaymentMethodTexts,
});

export const CancellationRefundOffer = PropTypes.shape({
  refundOfferId: Long,
  refundOfferReason: CancellationRefundOfferReason,
  refundableAmount: Long,
  refundableAmountInChargingCurrency: CurrencyAmount,
  handlingFee: Long,
  nonRefundableProducts: PropTypes.arrayOf(CancellationRefundOfferProduct),
  totalAmountForNonRefundableProducts: Long,
  expires: Long,
});

export const CreditCard = PropTypes.shape({
  code: PropTypes.string,
  id: Long,
  name: PropTypes.string,
  price: CurrencyAmount,
  serviceFee: CurrencyAmount,
  availablePaymentInstallmentOptions: PropTypes.arrayOf(AvailablePaymentInstalmentOption),
});

export const DateFormat = PropTypes.shape({
  date: PropTypes.string,
  datetime: PropTypes.string,
  dayDdmmmTime: PropTypes.string,
  dayDdmmm: PropTypes.string,
  time: PropTypes.string,
  day: PropTypes.string,
  dayYear: PropTypes.string,
  dayTime: PropTypes.string,
  weekday: PropTypes.string,
  shortWeekday: PropTypes.string,
  shortWeekdayYear: PropTypes.string,
  shortWeekdayFullYear: PropTypes.string,
  weekdayYear: PropTypes.string,
  monthYear: PropTypes.string,
  longMonthYear: PropTypes.string,
  timeWeekdayYear: PropTypes.string,
  shortDayShortMonth: PropTypes.string,
  dayName: PropTypes.string,
});

export const Descriptions = PropTypes.shape({
  text: PropTypes.string,
  type: PropTypes.string,
});

export const DiscountErrorMessage = PropTypes.shape({
  headerText: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.string),
});

export const DynamicBookingFlowStepIndicator = PropTypes.shape({
  stepNumber: PropTypes.number,
  name: PropTypes.string,
  completed: PropTypes.bool,
});

export const ETicket = PropTypes.shape({
  travelerId: ID,
  ticketNumber: PropTypes.string,
});

export const EmailValidationResponse = PropTypes.shape({
  valid: PropTypes.bool,
  message: PropTypes.string,
});

export const EmptyAddOnCart = PropTypes.shape({
  success: PropTypes.bool,
  state: PropTypes.string,
  expectedState: PropTypes.string,
  noAccessReason: PropTypes.arrayOf(PropTypes.string),
  failReason: PropTypes.string,
});

export const EventSegment = PropTypes.shape({
  id: ID,
  types: PropTypes.arrayOf(EventSegmentType),
  description: PropTypes.string,
  duration: Long,
  departureTime: PropTypes.string,
  departuredAt: Long,
  arrivalTime: PropTypes.string,
  arrivedAt: Long,
});

export const ExtraProductTexts = PropTypes.shape({
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  readMoreText: PropTypes.string,
  receiptText: PropTypes.string,
  salesAbstract: PropTypes.string,
  legalInformation: PropTypes.string,
  upsellTextWhenBooked: PropTypes.string,
});

export const FareRule = PropTypes.shape({
  header: PropTypes.string,
  content: PropTypes.string,
});

export const FilterOption = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.string,
});

export const FlightInformation = PropTypes.shape({
  url: PropTypes.string,
  pnr: PropTypes.string,
  loginEmail: PropTypes.string,
});

export const FooterIconUrl = PropTypes.shape({
  href: PropTypes.string,
});

export const FooterIcon = PropTypes.shape({
  id: PropTypes.string,
  url: FooterIconUrl,
});

export const FrequentFlyerCardType = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
});

export const GeoPoint = PropTypes.shape({
  latitude: PropTypes.number,
  longitude: PropTypes.number,
});

export const GooglePayAdditionalData = PropTypes.shape({
  code: PropTypes.string,
  merchantId: PropTypes.string,
  gatewayMerchantId: PropTypes.string,
  gateway: PropTypes.string,
  merchantCapabilities: PropTypes.arrayOf(PropTypes.string),
  allowedCardNetworks: PropTypes.arrayOf(PropTypes.string),
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  totalAmount: PropTypes.string,
});

export const IncludedCheckedBaggage = PropTypes.shape({
  pieces: PropTypes.number,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
});

export const IncludedExtraProduct = PropTypes.shape({
  id: ID,
  texts: ExtraProductTexts,
});

export const InvoicePdf = PropTypes.shape({
  pdfData: PropTypes.string,
});

export const Language = PropTypes.shape({
  name: PropTypes.string,
  code: PropTypes.string,
  id: Long,
  locale: PropTypes.string,
});

export const Links = PropTypes.shape({
  baggageInformation: PropTypes.string,
});

export const Localization = PropTypes.shape({
  availableLanguages: PropTypes.arrayOf(Language),
});

export const LogoutUserResponse = PropTypes.shape({
  response: LogoutUserResponsePayload,
});

export const MutuallyExclusive = PropTypes.shape({
  productTypes: PropTypes.arrayOf(Long),
});

export const Notification = PropTypes.shape({
  header: PropTypes.string,
  isImportant: PropTypes.bool,
  text: PropTypes.string,
});

export const OrderCreationSiteInfo = PropTypes.shape({
  brandCode: PropTypes.string,
  brandName: PropTypes.string,
  hostName: PropTypes.string,
  marketCode: PropTypes.string,
  orderUrl: PropTypes.string,
});

export const OrderInfo = PropTypes.shape({
  orderNumber: PropTypes.string,
  orderState: OrderState,
  isUpsellAllowed: PropTypes.bool,
  numberOfTravelers: Long,
  journeyType: TripType,
  bounds: PropTypes.arrayOf(BoundInformation),
  email: PropTypes.string,
  originalSite: OrderCreationSiteInfo,
});

export const OrderInformation = PropTypes.shape({
  header: PropTypes.string,
  text: PropTypes.string,
});

export const Passenger = PropTypes.shape({
  id: Long,
  barcodeData: PropTypes.string,
  barcodeFormat: PropTypes.string,
});

export const AncillaryProduct = PropTypes.shape({
  passengers: PropTypes.arrayOf(Passenger),
  name: PropTypes.string,
  status: PropTypes.string,
});

export const PassengerInformation = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  passengerId: PropTypes.string,
});

export const PassengerLegInformation = PropTypes.shape({
  passengerId: PropTypes.string,
  status: PassengerLegStatus,
});

export const LegInformation = PropTypes.shape({
  passengerLegs: PropTypes.arrayOf(PassengerLegInformation),
  airlineLocator: PropTypes.string,
  arrivalAirport: PropTypes.string,
  departureAirport: PropTypes.string,
  arrivalCity: PropTypes.string,
  departureCity: PropTypes.string,
  departureDate: PropTypes.string,
  departureTime: PropTypes.string,
});

export const CheckInStatus = PropTypes.shape({
  legs: PropTypes.arrayOf(LegInformation),
  passengers: PropTypes.arrayOf(PassengerInformation),
  errorCode: CheckInStatusErrorCode,
});

export const PayInternalPaymentMethod = PropTypes.shape({
  type: PropTypes.string,
  paymentServiceProvider: PropTypes.string,
});

export const Payment3DSecureData = PropTypes.shape({
  paReq: PropTypes.string,
  md: PropTypes.string,
  termUrl: PropTypes.string,
});

export const PaymentAdditionalData = PropTypes.shape({
  code: PropTypes.string,
});

export const PaymentFlow = PropTypes.shape({
  type: PropTypes.string,
});

export const PaymentInstalmentOption = PropTypes.shape({
  installmentOptionId: Long,
  numberOfInstalments: PropTypes.number,
  ratePercentage: BigDecimal,
  isCostPaidByCustomer: PropTypes.bool,
  baseCostPerInstalment: BigDecimal,
  feePerInstalment: BigDecimal,
  totalCostPerInstalment: BigDecimal,
  totalCostAfterFees: BigDecimal,
  totalFees: BigDecimal,
  paymentProvider: PropTypes.string,
  isEtgCharges: PropTypes.bool,
});

export const InstalmentOptions = PropTypes.shape({
  paymentInstalmentOptions: PropTypes.arrayOf(PaymentInstalmentOption),
  shouldAddInstallmentFeeInTotalAmount: PropTypes.bool,
});

export const PaymentMethod = PropTypes.shape({
  type: PropTypes.string,
  paymentServiceProvider: PropTypes.string,
});

export const PaymentMethodIssuer = PropTypes.shape({
  id: Long,
  name: PropTypes.string,
});

export const PaymentMethodPrice = PropTypes.shape({
  code: PropTypes.string,
  id: ID,
  name: PropTypes.string,
  price: CurrencyAmount,
  type: PaymentMethodType,
});

export const PaymentStatusResponse = PropTypes.shape({
  status: PaymentStatus,
});

export const PhoneNumberValidationResponse = PropTypes.shape({
  valid: PropTypes.bool,
  message: PropTypes.string,
});

export const PhonePrefix = PropTypes.shape({
  id: ID,
  prefix: PropTypes.string,
});

export const PostArg = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.string,
});

export const AddonPaymentResponse = PropTypes.shape({
  success: PropTypes.bool,
  type: PaymentResponseType,
  message: PropTypes.string,
  redirectURL: PropTypes.string,
  makeOrderProcessId: PropTypes.string,
  postArgs: PropTypes.arrayOf(PostArg),
});

export const Price = PropTypes.shape({
  markup: CurrencyAmount,
  price: CurrencyAmount,
  vat: CurrencyAmount,
});

export const PaymentProduct = PropTypes.shape({
  id: ID,
  code: PropTypes.string,
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  price: Price,
  type: PropTypes.string,
  cardSubType: PropTypes.string,
  maskedCardNumber: PropTypes.string,
});

export const PriceFormat = PropTypes.shape({
  currencySymbol: PropTypes.string,
  decimalSymbol: PropTypes.string,
  groupingSeparator: PropTypes.string,
  pattern: PropTypes.string,
  currencyExponent: PropTypes.number,
});

export const ChargingCurrency = PropTypes.shape({
  currency: Currency,
  value: PropTypes.number,
  priceFormat: PriceFormat,
});

export const PriceRange = PropTypes.shape({
  min: Long,
  max: Long,
});

export const Product = PropTypes.shape({
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  code: PropTypes.string,
  amount: Long,
});

export const ProviderBookingBound = PropTypes.shape({
  origin: PropTypes.string,
  destination: PropTypes.string,
});

export const ProviderBooking = PropTypes.shape({
  reference: PropTypes.string,
  lastUpdatedUTC: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  carrier: PropTypes.string,
  carrierCode: PropTypes.string,
  bounds: PropTypes.arrayOf(ProviderBookingBound),
  journeyType: TripType,
});

export const ProviderBookingStepInformation = PropTypes.shape({
  step: RefundCaseStep,
  completed: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
});

export const PspCascadingTransitionData = PropTypes.shape({
  pspCascadingRedirectUrl: PropTypes.string,
  paReq: PropTypes.string,
  md: PropTypes.string,
  termUrl: PropTypes.string,
});

export const PaymentData = PropTypes.shape({
  transactionId: PropTypes.string,
  cadillac: Cadillac,
  availablePaymentMethods: PropTypes.arrayOf(PaymentMethod),
  pspCascadingTransition: PspCascadingTransitionData,
});

export const QPassText = PropTypes.shape({
  flightNumber: PropTypes.string,
  departureAirport: PropTypes.string,
  arrivalAirport: PropTypes.string,
  departureDateTime: PropTypes.string,
});

export const QPassTexts = PropTypes.shape({
  QPassText: PropTypes.arrayOf(QPassText),
});

export const QuickSortPrices = PropTypes.shape({
  carrierPromo: CurrencyAmount,
  cheapTrip: CurrencyAmount,
  recommendation: CurrencyAmount,
  shortTrip: CurrencyAmount,
  bestValue: CurrencyAmount,
  longStay: CurrencyAmount,
});

export const RangeFilter = PropTypes.shape({
  code: PropTypes.string,
  label: PropTypes.string,
  group: PropTypes.string,
  type: FilterOptionType,
  min: Long,
  max: Long,
});

export const Rebook = PropTypes.shape({
  message: PropTypes.string,
  success: PropTypes.bool,
});

export const RebookEligibilityPnrInfo = PropTypes.shape({
  pnr: PropTypes.string,
  hasAirlineAncillaries: PropTypes.bool,
  flexibleTicketRemainsApplicable: PropTypes.bool,
});

export const PnrEligibility = PropTypes.shape({
  info: RebookEligibilityPnrInfo,
  status: RebookEligibilityStatus,
  reason: RebookEligibilityReason,
});

export const RebookEligibilityPerPnrResponse = PropTypes.shape({
  pnrEligibilityList: PropTypes.arrayOf(PnrEligibility),
  hasFlexibleTicket: PropTypes.bool,
});

export const RebookEligibilityResponse = PropTypes.shape({
  rebookFlow: PropTypes.string,
  message: PropTypes.string,
});

export const RebookingPrice = PropTypes.shape({
  fareDifference: CurrencyAmount,
  taxDifference: CurrencyAmount,
  airlinePenalty: CurrencyAmount,
  airlineFees: CurrencyAmount,
  serviceFee: CurrencyAmount,
  totalDifference: CurrencyAmount,
});

export const RebookingTravelerPrice = PropTypes.shape({
  id: ID,
  travelerId: ID,
  rebookingPrice: RebookingPrice,
});

export const ReceiptPdf = PropTypes.shape({
  pdf: PropTypes.string,
});

export const RefundCaseProviderBooking = PropTypes.shape({
  reference: PropTypes.string,
  lastUpdatedUTC: PropTypes.string,
  carrier: PropTypes.string,
  carrierCode: PropTypes.string,
  steps: PropTypes.arrayOf(ProviderBookingStepInformation),
  providerBookingResult: ProviderBookingResult,
  hasAcceptedVoucher: PropTypes.bool,
  cc2Carrier: PropTypes.bool,
  payoutDelayed: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  bounds: PropTypes.arrayOf(ProviderBookingBound),
  journeyType: TripType,
});

export const RefundCaseRelatedProviderBooking = PropTypes.shape({
  reference: PropTypes.string,
  lastUpdatedUTC: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  carrier: PropTypes.string,
  carrierCode: PropTypes.string,
  bounds: PropTypes.arrayOf(ProviderBookingBound),
  journeyType: TripType,
});

export const RefundOffer = PropTypes.shape({
  refundableAmount: Long,
  refundableAmountInChargingCurrency: CurrencyAmount,
  handlingFee: Long,
  nonRefundableProducts: PropTypes.arrayOf(Product),
  totalAmountForNonRefundableProducts: Long,
  expires: PropTypes.number,
});

export const RefundEligibility = PropTypes.shape({
  orderRefundReason: PropTypes.string,
  cancellable: PropTypes.bool,
  refundOffer: RefundOffer,
});

export const RefundPaymentMethod = PropTypes.shape({
  paymentType: PropTypes.string,
  paymentSubType: PropTypes.string,
  maskedCardNumber: PropTypes.string,
});

export const RefundedProduct = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  refundedAmount: CurrencyAmount,
});

export const RefundedProviderBooking = PropTypes.shape({
  bounds: PropTypes.arrayOf(ProviderBookingBound),
  journeyType: TripType,
  reference: PropTypes.string,
  refundedAmount: CurrencyAmount,
});

export const RefundPayout = PropTypes.shape({
  totalRefundedAmount: CurrencyAmount,
  refundHandlingFee: CurrencyAmount,
  refundNonBankAddition: CurrencyAmount,
  refundPaymentMethod: RefundPaymentMethod,
  manuallyCalculatedRefundAmount: CurrencyAmount,
  refundedProviderBookings: PropTypes.arrayOf(RefundedProviderBooking),
  refundedProducts: PropTypes.arrayOf(RefundedProduct),
  refundedProductsTotalAmount: CurrencyAmount,
});

export const RefundCaseInformation = PropTypes.shape({
  providerBookings: PropTypes.arrayOf(ProviderBooking),
  closed: PropTypes.bool,
  cancelReason: RefundCaseCancelReason,
  refundPayouts: PropTypes.arrayOf(RefundPayout),
});

export const RefundStatusInformation = PropTypes.shape({
  cases: PropTypes.arrayOf(RefundCaseInformation),
  hasChargeback: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  refundedCasesTotalAmount: CurrencyAmount,
});

export const RegionNew = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
  airportCode: PropTypes.string,
  airportName: PropTypes.string,
  cityCode: PropTypes.string,
  cityName: PropTypes.string,
  continentCode: PropTypes.string,
  continentName: PropTypes.string,
  countryCode: PropTypes.string,
  countryName: PropTypes.string,
  isAirport: PropTypes.bool,
  geoPoint: GeoPoint,
  timezoneId: PropTypes.string,
});

export const OriginalSegment = PropTypes.shape({
  origin: RegionNew,
  departuredAt: PropTypes.string,
  destination: RegionNew,
  arrivedAt: PropTypes.string,
  marketingCarrier: CarrierNew,
  flightNumber: PropTypes.string,
});

export const OriginalVersion = PropTypes.shape({
  originalSegments: PropTypes.arrayOf(OriginalSegment),
  addedSegments: PropTypes.arrayOf(PropTypes.number),
  removedSegments: PropTypes.arrayOf(PropTypes.number),
});

export const RegionSettings = PropTypes.shape({
  origin: PropTypes.string,
  destination: PropTypes.string,
  originHeadline: PropTypes.string,
  destinationHeadline: PropTypes.string,
});

export const NotificationMessage = PropTypes.shape({
  regionSettings: RegionSettings,
  carrierSettings: CarrierSettings,
  airSystemSettings: AirSystemSettings,
});

export const Reissue = PropTypes.shape({
  message: PropTypes.string,
  success: PropTypes.bool,
});

export const RejectPriceChangeResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const RemoveOrderFromSessionResponse = PropTypes.shape({
  response: BindingResponse,
});

export const ResultFilter = PropTypes.shape({
  code: PropTypes.string,
  label: PropTypes.string,
  group: PropTypes.string,
  type: FilterOptionType,
});

export const Rule = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
});

export const SearchLocation = PropTypes.shape({
  id: Long,
  iataCode: PropTypes.string,
  type: SearchLocationType,
  name: PropTypes.string,
  city: PropTypes.string,
  cityCode: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  continent: PropTypes.string,
  continentCode: PropTypes.string,
  state: PropTypes.string,
  multipleAirports: PropTypes.bool,
  geoPoint: GeoPoint,
  timeZoneId: PropTypes.string,
});

export const SearchMetaData = PropTypes.shape({
  tripId: PropTypes.number,
  selectionKey: PropTypes.string,
  searchPath: PropTypes.string,
});

export const SearchParameters = PropTypes.shape({
  destinationImageUrl: PropTypes.string,
});

export const SearchTraveler = PropTypes.shape({
  age: PropTypes.number,
  ageType: AgeType,
});

export const SeatMapSegmentTexts = PropTypes.shape({
  text: PropTypes.string,
  segmentIndex: PropTypes.number,
  boundIndex: PropTypes.number,
});

export const SeatMapTexts = PropTypes.shape({
  seatMapSegmentTexts: PropTypes.arrayOf(SeatMapSegmentTexts),
});

export const SecondBagSummary = PropTypes.shape({
  travelerId: PropTypes.string,
  baggageDetails: CheckedBaggage,
});

export const SecondBagTripSummary = PropTypes.shape({
  secondBagSummaries: PropTypes.arrayOf(SecondBagSummary),
});

export const Segment = PropTypes.shape({
  id: PropTypes.number,
  ancillaryProducts: PropTypes.arrayOf(AncillaryProduct),
});

export const Bound = PropTypes.shape({
  id: PropTypes.number,
  segments: PropTypes.arrayOf(Segment),
});

export const AncillaryPasses = PropTypes.shape({
  bounds: PropTypes.arrayOf(Bound),
});

export const SegmentDetailsNew = PropTypes.shape({
  numberOfSeatsLeft: PropTypes.number,
  paxType: PropTypes.string,
});

export const SelectLanguageResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const SelectionFilter = PropTypes.shape({
  code: PropTypes.string,
  label: PropTypes.string,
  group: PropTypes.string,
  type: FilterOptionType,
  options: PropTypes.arrayOf(FilterOption),
  isMultiChoice: PropTypes.bool,
});

export const SelfServiceRebookingTraveler = PropTypes.shape({
  id: ID,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  birthDate: PropTypes.string,
  ageType: PropTypes.string,
  gender: PropTypes.string,
});

export const SellPriceBaggageNew = PropTypes.shape({
  maxWeight: PropTypes.number,
  numberOfUnits: PropTypes.number,
  price: Price,
  travelerId: ID,
  weightUnit: PropTypes.string,
});

export const SellPriceInsuranceNew = PropTypes.shape({
  id: ID,
  price: Price,
  title: PropTypes.string,
  description: PropTypes.string,
  disclaimer: PropTypes.string,
  persuasionEnabled: PropTypes.bool,
  inclusions: PropTypes.arrayOf(Rule),
});

export const SellPriceTravelerNew = PropTypes.shape({
  price: Price,
  travelerId: ID,
});

export const ChoiceNew = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  pricePerTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
  descriptions: PropTypes.arrayOf(Descriptions),
});

export const SellSeatMapColumnDetailsNew = PropTypes.shape({
  columnName: PropTypes.string,
  type: PropTypes.string,
});

export const SellSeatMapSeatNew = PropTypes.shape({
  column: PropTypes.string,
  isBookable: PropTypes.bool,
  price: Price,
  row: PropTypes.number,
  seat: PropTypes.string,
  type: PropTypes.string,
  characteristics: PropTypes.arrayOf(SeatCharacteristic),
  mainCharacteristic: SeatCharacteristic,
});

export const SellSeatMapRowNew = PropTypes.shape({
  characteristics: PropTypes.arrayOf(PropTypes.string),
  rowNumber: PropTypes.number,
  sellSeatMapSeats: PropTypes.arrayOf(SellSeatMapSeatNew),
});

export const SellSeatMapCabinNew = PropTypes.shape({
  designatedClass: PropTypes.string,
  rows: PropTypes.arrayOf(SellSeatMapRowNew),
  sellSeatMapColumnDetails: PropTypes.arrayOf(SellSeatMapColumnDetailsNew),
});

export const SellSeatMapDeckNew = PropTypes.shape({
  cabins: PropTypes.arrayOf(SellSeatMapCabinNew),
});

export const SellSeatMapSegmentNew = PropTypes.shape({
  lowerDeck: SellSeatMapDeckNew,
  mainDeck: SellSeatMapDeckNew,
  id: ID,
  travelerIds: PropTypes.arrayOf(ID),
  upperDeck: SellSeatMapDeckNew,
  bestPriceCharacteristics: PropTypes.arrayOf(BestPriceCharacteristic),
});

export const SellSeatingBeside = PropTypes.shape({
  price: Price,
  id: ID,
});

export const SellSeatingPreference = PropTypes.shape({
  price: Price,
  id: ID,
});

export const SellSeatMap = PropTypes.oneOfType([SellSeatMapSegmentNew,SellSeatingPreference,SellSeatingBeside]);

export const SellSeatMapBoundNew = PropTypes.shape({
  id: ID,
  sellSeatMapSegments: PropTypes.arrayOf(SellSeatMap),
});

export const SellSpecificationBaggageNew = PropTypes.shape({
  sellPriceBaggage: PropTypes.arrayOf(SellPriceBaggageNew),
});

export const SellSpecificationInsuranceNew = PropTypes.shape({
  numberOfTravelers: PropTypes.number,
  termsUrl: PropTypes.string,
  sellPriceInsurances: PropTypes.arrayOf(SellPriceInsuranceNew),
});

export const SellSpecificationNoneNew = PropTypes.shape({
  price: Price,
});

export const SellSpecificationSeatingSeatMapNew = PropTypes.shape({
  sellSeatMapBounds: PropTypes.arrayOf(SellSeatMapBoundNew),
});

export const SellSpecificationTravelerChoiceNew = PropTypes.shape({
  availableChoices: PropTypes.arrayOf(ChoiceNew),
  pricePerTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
  preselectedChoice: PropTypes.string,
});

export const SellSpecificationTravelerNew = PropTypes.shape({
  sellPriceTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
});

export const SendChallengeEmailResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const Size3d = PropTypes.shape({
  length: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
});

export const CabinBaggageDetails = PropTypes.shape({
  pieces: PropTypes.number,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
  size3d: Size3d,
});

export const CabinBaggageTravelerDetails = PropTypes.shape({
  travelerId: PropTypes.string,
  cabinBaggageDetails: CabinBaggageDetails,
  cabinBaggageStatus: CabinBaggageStatus,
});

export const CabinBaggageSegmentSummary = PropTypes.shape({
  segmentId: ID,
  cabinBaggageTravelerDetails: PropTypes.arrayOf(CabinBaggageTravelerDetails),
});

export const CabinBaggageBoundSummary = PropTypes.shape({
  boundId: ID,
  cabinBaggageTravelerDetails: PropTypes.arrayOf(CabinBaggageTravelerDetails),
  cabinBaggageSegmentSummaries: PropTypes.arrayOf(CabinBaggageSegmentSummary),
});

export const CabinBaggageTripSummary = PropTypes.shape({
  cabinBaggageTravelerDetails: PropTypes.arrayOf(CabinBaggageTravelerDetails),
  cabinBaggageBoundSummaries: PropTypes.arrayOf(CabinBaggageBoundSummary),
});

export const Content = PropTypes.shape({
  productTypeId: ID,
  name: PropTypes.string,
  readMoreText: PropTypes.string,
  description: PropTypes.string,
  termsUrl: PropTypes.string,
  included: PropTypes.bool,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
  pieces: PropTypes.number,
  size3d: Size3d,
  legalInformation: PropTypes.string,
  upsellTextWhenBooked: PropTypes.string,
});

export const IncludedCabinBaggage = PropTypes.shape({
  pieces: PropTypes.number,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
  size3d: Size3d,
  includedPersonalItem: PropTypes.bool,
});

export const Option = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  price: Price,
  tier: Tier,
  content: PropTypes.arrayOf(Content),
  originalPrice: Price,
});

export const BundleProduct = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  options: PropTypes.arrayOf(Option),
  locationRestrictionId: PropTypes.number,
  selectedOption: ID,
  discountPercentage: PropTypes.number,
});

export const PersonalItemDetails = PropTypes.shape({
  size3d: Size3d,
});

export const PersonalItemTravelerDetails = PropTypes.shape({
  travelerId: PropTypes.string,
  personalItemDetails: PersonalItemDetails,
  personalItemStatus: PersonalItemStatus,
});

export const PersonalItemSegmentSummary = PropTypes.shape({
  segmentId: ID,
  personalItemTravelerDetails: PropTypes.arrayOf(PersonalItemTravelerDetails),
});

export const PersonalItemBoundSummary = PropTypes.shape({
  boundId: ID,
  personalItemTravelerDetails: PropTypes.arrayOf(PersonalItemTravelerDetails),
  personalItemSegmentSummaries: PropTypes.arrayOf(PersonalItemSegmentSummary),
});

export const PersonalItemTripSummary = PropTypes.shape({
  personalItemTravelerDetails: PropTypes.arrayOf(PersonalItemTravelerDetails),
  personalItemBoundSummaries: PropTypes.arrayOf(PersonalItemBoundSummary),
});

export const SellCabinBaggageNew = PropTypes.shape({
  sellPriceTravelers: PropTypes.arrayOf(SellPriceTravelerNew),
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
  size3d: Size3d,
  pieces: PropTypes.number,
});

export const SellSpecificationNew = PropTypes.oneOfType([SellSpecificationNoneNew,SellSpecificationTravelerNew,SellSpecificationTravelerChoiceNew,SellSpecificationBaggageNew,SellSpecificationSeatingSeatMapNew,SellSpecificationInsuranceNew,SellCabinBaggageNew]);

export const SortTypeOption = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const State = PropTypes.shape({
  id: ID,
  code: PropTypes.string,
  name: PropTypes.string,
});

export const Country = PropTypes.shape({
  id: Long,
  code: PropTypes.string,
  name: PropTypes.string,
  phonePrefixes: PropTypes.arrayOf(PhonePrefix),
  states: PropTypes.arrayOf(State),
});

export const CustomerDetails = PropTypes.shape({
  name: PropTypes.string,
  street: PropTypes.string,
  city: PropTypes.string,
  zipCode: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  nationality: Country,
});

export const SubMenuItem = PropTypes.shape({
  name: PropTypes.string,
  path: PropTypes.string,
});

export const MainMenuItem = PropTypes.shape({
  name: PropTypes.string,
  path: PropTypes.string,
  menuType: PropTypes.string,
  subMenu: PropTypes.arrayOf(SubMenuItem),
});

export const Header = PropTypes.shape({
  mainMenuItems: PropTypes.arrayOf(MainMenuItem),
});

export const Menu = PropTypes.shape({
  mainMenuItems: PropTypes.arrayOf(MainMenuItem),
});

export const Footer = PropTypes.shape({
  legal: PropTypes.string,
  paymentProviders: PropTypes.arrayOf(FooterIcon),
  trustedPartners: PropTypes.arrayOf(FooterIcon),
  siteFlags: PropTypes.arrayOf(FooterIcon),
  menus: PropTypes.arrayOf(Menu),
});

export const SeoData = PropTypes.shape({
  mainMenuItems: PropTypes.arrayOf(MainMenuItem),
});

export const SiteInformation = PropTypes.shape({
  header: Header,
  footer: Footer,
});

export const Summary = PropTypes.shape({
  travelerId: PropTypes.string,
  baggageDetails: CheckedBaggage,
  baggageStatus: BaggageStatus,
});

export const SegmentSummary = PropTypes.shape({
  segmentId: ID,
  summaries: PropTypes.arrayOf(Summary),
});

export const BoundSummary = PropTypes.shape({
  boundId: ID,
  summaries: PropTypes.arrayOf(Summary),
  segmentSummaries: PropTypes.arrayOf(SegmentSummary),
});

export const CheckedBaggageSummary = PropTypes.shape({
  tripSummaries: PropTypes.arrayOf(Summary),
  boundSummaries: PropTypes.arrayOf(BoundSummary),
});

export const TaxOrFee = PropTypes.shape({
  code: PropTypes.string,
  title: PropTypes.string,
  amount: CurrencyAmount,
});

export const TaxCategory = PropTypes.shape({
  category: TaxCategoryType,
  taxes: PropTypes.arrayOf(TaxOrFee),
});

export const Thunderbird = PropTypes.shape({
  enabled: PropTypes.bool,
  publicKey: PropTypes.string,
});

export const CardPaymentMethod = PropTypes.shape({
  type: PropTypes.string,
  paymentServiceProvider: PropTypes.string,
  creditCards: PropTypes.arrayOf(CreditCard),
  binVerificationType: PropTypes.string,
  paymentFlow: PaymentFlow,
  thunderbird: Thunderbird,
  potentialChargeInDifferentCurrency: PropTypes.bool,
  potentialChargeInCurrencies: PropTypes.arrayOf(PropTypes.string),
});

export const TokenizationPaymentFlow = PropTypes.shape({
  type: PropTypes.string,
  tokenizationUrl: PropTypes.string,
});

export const TransactionBreakdownEntry = PropTypes.shape({
  carrierName: PropTypes.string,
  transactionAmount: Price,
});

export const TravelTimeRange = PropTypes.shape({
  min: PropTypes.number,
  max: PropTypes.number,
});

export const ResultSetMetaData = PropTypes.shape({
  travelTimeRange: TravelTimeRange,
  marketingCarriers: PropTypes.arrayOf(CarrierNew),
  priceRange: PriceRange,
});

export const TravelerDetailsTravelDocument = PropTypes.shape({
  showTravelDocumentPassportNumber: PropTypes.bool,
  showTravelDocumentIssueDate: PropTypes.bool,
  showTravelDocumentIssueCity: PropTypes.bool,
  showTravelDocumentDateOfBirth: PropTypes.bool,
  showTravelDocumentExpiryDate: PropTypes.bool,
  showTravelDocumentCpfNumber: PropTypes.bool,
  showTravelDocumentNationality: PropTypes.bool,
  requireTravelDocumentExpiryDate: TravelerDetailsTravelDocumentExpirationDate,
});

export const TravelerDetailsTraveler = PropTypes.shape({
  id: PropTypes.string,
  ageType: PropTypes.string,
  age: PropTypes.number,
  showFirstNameBeforeLastName: PropTypes.bool,
  showFrequentFlyerCard: PropTypes.bool,
  travelDocument: TravelerDetailsTravelDocument,
});

export const TravelerDetails = PropTypes.shape({
  searchMetaData: SearchMetaData,
  showEmergencyContactInformation: PropTypes.bool,
  travelers: PropTypes.arrayOf(TravelerDetailsTraveler),
});

export const TravelerPrice = PropTypes.shape({
  id: ID,
  travelerId: ID,
  price: Price,
  taxesAndFees: PropTypes.arrayOf(TaxCategory),
});

export const TripCampaign = PropTypes.shape({
  selectionKey: PropTypes.string,
  campaignImageUrlDesktop: PropTypes.string,
  campaignImageUrlMobile: PropTypes.string,
});

export const TripPriceChange = PropTypes.shape({
  id: ID,
  priceOld: Price,
  priceNew: Price,
  priceDiff: Price,
});

export const TripRoute = PropTypes.shape({
  origin: RegionNew,
  destination: RegionNew,
  departureAt: PropTypes.string,
  departureDate: PropTypes.string,
  departureTimeOfDay: TimeOfDay,
});

export const TripScheduleChange = PropTypes.shape({
  id: ID,
  segmentId: ID,
  departuredAtNew: Long,
  departureTimeNew: PropTypes.string,
  departuredAtOld: Long,
  departureTimeOld: PropTypes.string,
  arrivedAtNew: Long,
  arrivalTimeNew: PropTypes.string,
  arrivedAtOld: Long,
  arrivalTimeOld: PropTypes.string,
});

export const TripChange = PropTypes.oneOfType([TripPriceChange,TripScheduleChange]);

export const PaymentResponse = PropTypes.shape({
  success: PropTypes.bool,
  type: PaymentResponseType,
  message: PropTypes.string,
  redirectURL: PropTypes.string,
  bankData: BankData,
  payment3DSecureData: Payment3DSecureData,
  clientSideEncryption3DSecureData: ClientSideEncryption3DSecureData,
  postArgs: PropTypes.arrayOf(PostArg),
  tripChanges: PropTypes.arrayOf(TripChange),
  orderLink: PropTypes.string,
  makeOrderProcessId: PropTypes.string,
});

export const UpdateSelectedTripBeforeReservationResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const User = PropTypes.shape({
  email: PropTypes.string,
  loginType: AuthenticationType,
  loginOrderNumber: PropTypes.string,
  loginOrderContactUsActualUrl: PropTypes.string,
  sessionOrderNumber: PropTypes.string,
});

export const AuthenticateUserResponse = PropTypes.shape({
  authenticated: PropTypes.bool,
  urlForActualSite: PropTypes.string,
  orderDetailsUrlForActualSite: PropTypes.string,
  brandCodeForActualSite: PropTypes.string,
  brandNameForActualSite: PropTypes.string,
  user: User,
});

export const VerifyEmailChallengeResponse = PropTypes.shape({
  response: BindingResponse,
});

export const VirtualCard = PropTypes.shape({
  cvc: PropTypes.string,
  expMonthYear: PropTypes.string,
  lastFourDigits: PropTypes.string,
});

export const TripSegment = PropTypes.shape({
  arrivedAt: PropTypes.string,
  aircraftType: PropTypes.string,
  cabinClass: PropTypes.string,
  carrierBookingNumber: CarrierBookingNumber,
  equipmentCode: PropTypes.string,
  cabinClassName: PropTypes.string,
  fareBasis: PropTypes.string,
  bookingClass: PropTypes.string,
  departuredAt: PropTypes.string,
  destination: RegionNew,
  flightNumber: PropTypes.string,
  id: ID,
  lccSupplier: PropTypes.string,
  marketingCarrier: CarrierNew,
  operatingCarrier: CarrierNew,
  ticketingCarrier: CarrierNew,
  operatingInformation: PropTypes.string,
  origin: RegionNew,
  pnr: PropTypes.string,
  duration: Long,
  numberOfTechnicalStops: PropTypes.number,
  segmentDetails: PropTypes.arrayOf(SegmentDetailsNew),
  includedCheckedBaggage: IncludedCheckedBaggage,
  includedCabinBaggage: IncludedCabinBaggage,
  virtualCard: VirtualCard,
  brandedFareInformation: BrandedFareInfo,
  eTickets: PropTypes.arrayOf(ETicket),
});

export const SegmentNew = PropTypes.oneOfType([TripSegment,EventSegment]);

export const BoundNew = PropTypes.shape({
  id: ID,
  includedCheckedBaggage: IncludedCheckedBaggage,
  includedCabinBaggage: IncludedCabinBaggage,
  segments: PropTypes.arrayOf(SegmentNew),
  originalVersion: OriginalVersion,
});

export const VisaCountry = PropTypes.shape({
  code: PropTypes.string,
  name: PropTypes.string,
});

export const VisaInfo = PropTypes.shape({
  country: VisaCountry,
  description: PropTypes.string,
  title: PropTypes.string,
});

export const TravelDocsInfo = PropTypes.shape({
  visaInfo: PropTypes.arrayOf(VisaInfo),
  apisInfo: ApisInfo,
  carrierInfo: PropTypes.arrayOf(CarrierInfo),
});

export const Wallet = PropTypes.shape({
  id: ID,
  code: PropTypes.string,
  name: PropTypes.string,
  price: CurrencyAmount,
  serviceFee: CurrencyAmount,
  additionalData: PaymentAdditionalData,
});

export const WalletPaymentMethod = PropTypes.shape({
  type: PropTypes.string,
  paymentServiceProvider: PropTypes.string,
  wallets: PropTypes.arrayOf(Wallet),
});

export const addDiscountCodeResponse = PropTypes.shape({
  success: PropTypes.bool,
  discountPrice: Price,
  discountErrorMessage: DiscountErrorMessage,
});

export const cartTravelerBaggagePrice = PropTypes.shape({
  travelerId: ID,
  price: Price,
  boundIndex: PropTypes.number,
  maxWeight: PropTypes.number,
  weightUnit: PropTypes.string,
  pieces: PropTypes.number,
});

export const cartTravelerBaggageTripPrice = PropTypes.shape({
  travelerId: ID,
  price: Price,
  maxWeight: PropTypes.number,
  weightUnit: PropTypes.string,
  pieces: PropTypes.number,
});

export const BuySpecificationCartTravelerBaggage = PropTypes.shape({
  travelerBaggagePrices: PropTypes.arrayOf(cartTravelerBaggagePrice),
  travelerBaggageTripPrices: PropTypes.arrayOf(cartTravelerBaggageTripPrice),
});

export const cartTravelerCabinBaggagePrice = PropTypes.shape({
  travelerId: ID,
  price: Price,
  weight: PropTypes.number,
  weightUnit: PropTypes.string,
  size3d: Size3d,
  pieces: PropTypes.number,
});

export const BuySpecificationCartTravelerCabinBaggage = PropTypes.shape({
  travelerCabinBaggagePrices: PropTypes.arrayOf(cartTravelerCabinBaggagePrice),
});

export const cartTravelerChoicePrice = PropTypes.shape({
  travelerId: ID,
  price: Price,
  choice: PropTypes.string,
});

export const BuySpecificationCartTravelerChoice = PropTypes.shape({
  travelerChoicePrices: PropTypes.arrayOf(cartTravelerChoicePrice),
});

export const cartTravelerPrice = PropTypes.shape({
  travelerId: ID,
  price: Price,
});

export const BuySpecificationCartTraveler = PropTypes.shape({
  travelerPrices: PropTypes.arrayOf(cartTravelerPrice),
});

export const cartTravelerSeatMapPrice = PropTypes.shape({
  travelerId: ID,
  boundIndex: PropTypes.number,
  segmentIndex: PropTypes.number,
  seat: PropTypes.string,
  price: Price,
});

export const BuySpecificationCartTravelerSeatMap = PropTypes.shape({
  travelerSeatMapPrices: PropTypes.arrayOf(cartTravelerSeatMapPrice),
});

export const BuySpecificationCart = PropTypes.oneOfType([BuySpecificationCartTraveler,BuySpecificationCartTravelerBaggage,BuySpecificationCartTravelerChoice,BuySpecificationCartTravelerSeatMap,BuySpecificationCartTravelerCabinBaggage]);

export const ProductNew = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  code: PropTypes.string,
  price: Price,
  tripId: ID,
  buySpecification: BuySpecificationCart,
  readMoreText: PropTypes.string,
  description: PropTypes.string,
  texts: ExtraProductTexts,
});

export const AddOnOrder = PropTypes.shape({
  creationDate: PropTypes.string,
  orderNumber: PropTypes.string,
  customerOrderReference: PropTypes.string,
  price: Price,
  extraProducts: PropTypes.arrayOf(ProductNew),
});

export const CancellationOptions = PropTypes.shape({
  refundOffers: PropTypes.arrayOf(CancellationRefundOffer),
  refundOffersError: PropTypes.string,
  includedCancellationProducts: PropTypes.arrayOf(ProductNew),
});

export const consent = PropTypes.shape({
  reference: PropTypes.string,
  domain: PropTypes.string,
  source: PropTypes.string,
  subject: PropTypes.string,
  optin: PropTypes.bool,
  createdAt: PropTypes.string,
});

export const insuranceProductInformationDocument = PropTypes.shape({
  name: PropTypes.string,
  url: PropTypes.string,
});

export const InsuranceTexts = PropTypes.shape({
  termsUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  disclaimer: PropTypes.string,
  inclusions: PropTypes.arrayOf(Rule),
  ipid: insuranceProductInformationDocument,
});

export const ProductSpecificTexts = PropTypes.oneOfType([InsuranceTexts,SeatMapTexts,QPassTexts]);

export const ExtraProduct = PropTypes.shape({
  id: ID,
  code: PropTypes.string,
  name: PropTypes.string,
  sellSpecification: SellSpecificationNew,
  configuration: Configuration,
  productSpecificTexts: ProductSpecificTexts,
  selectedWithTrip: PropTypes.bool,
  texts: ExtraProductTexts,
});

export const DynamicBookingFlowStepProducts = PropTypes.shape({
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  availableAncillaryBundles: PropTypes.arrayOf(BundleProduct),
  mutuallyExclusiveProducts: PropTypes.arrayOf(MutuallyExclusive),
});

export const DynamicBookingFlow = PropTypes.shape({
  stepIndicators: PropTypes.arrayOf(DynamicBookingFlowStepIndicator),
  stepProducts: DynamicBookingFlowStepProducts,
});

export const localesAndDomains = PropTypes.shape({
  localeCode: PropTypes.string,
  domain: PropTypes.string,
  languageName: PropTypes.string,
  languageId: Long,
});

export const BrandSiteLocale = PropTypes.shape({
  country: PropTypes.string,
  localesAndDomains: PropTypes.arrayOf(localesAndDomains),
  countryCode: PropTypes.string,
  countryId: Long,
});

export const SiteContext = PropTypes.shape({
  brand: Brand,
  entryType: PropTypes.string,
  language: Language,
  market: Country,
  partner: PropTypes.string,
  siteCurrency: Currency,
  siteName: PropTypes.string,
  dateFormat: DateFormat,
  productionWeb: PropTypes.string,
  brandSiteLocales: PropTypes.arrayOf(BrandSiteLocale),
});

export const pageContainer = PropTypes.shape({
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
});

export const pendingProviderBooking = PropTypes.shape({
  departureAt: PropTypes.string,
  origin: PropTypes.string,
  destination: PropTypes.string,
  fullName: PropTypes.string,
  orderNumberToBe: PropTypes.string,
  customerEmail: PropTypes.string,
});

export const removeDiscountsResponse = PropTypes.shape({
  success: PropTypes.bool,
});

export const result = PropTypes.shape({
  outcome: PropTypes.bool,
});

export const results = PropTypes.shape({
  segmentIndex: PropTypes.number,
  boundIndex: PropTypes.number,
  status: status,
});

export const frequentFlyerCard = PropTypes.shape({
  cardType: PropTypes.string,
  cardTypeName: PropTypes.string,
  cardTypeId: ID,
  number: PropTypes.string,
  results: PropTypes.arrayOf(results),
});

export const TravelerNew = PropTypes.shape({
  ageType: AgeType,
  age: PropTypes.number,
  birthDate: PropTypes.string,
  firstName: PropTypes.string,
  gender: GenderInput,
  lastName: PropTypes.string,
  name: PropTypes.string,
  fullName: PropTypes.string,
  passportNumber: PropTypes.string,
  frequentFlyerCard: frequentFlyerCard,
  id: ID,
});

export const BrandedFare = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  features: PropTypes.arrayOf(BrandedFareFeature),
  selectionKey: PropTypes.string,
  travelers: PropTypes.arrayOf(TravelerNew),
  travelerPrices: PropTypes.arrayOf(TravelerPrice),
  checkedBaggageSummary: CheckedBaggageSummary,
  cabinBaggageTripSummary: CabinBaggageTripSummary,
});

export const RebookingTrip = PropTypes.shape({
  id: ID,
  type: TripType,
  selectionKey: PropTypes.string,
  bounds: PropTypes.arrayOf(BoundNew),
  travelers: PropTypes.arrayOf(TravelerNew),
  rebookingTravelerPrices: PropTypes.arrayOf(RebookingTravelerPrice),
  rebookingTravelerPricesWithoutPaymentDiscounts: PropTypes.arrayOf(RebookingTravelerPrice),
  paymentMethodPrices: PropTypes.arrayOf(PaymentMethodPrice),
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  includedCheckedBaggage: IncludedCheckedBaggage,
  includedCabinBaggage: IncludedCabinBaggage,
  tripCharacteristics: PropTypes.arrayOf(TripCharacteristics),
});

export const RebookingSearch = PropTypes.shape({
  type: TripType,
  routes: PropTypes.arrayOf(TripRoute),
  travelers: PropTypes.arrayOf(SearchTraveler),
  flights: PropTypes.arrayOf(RebookingTrip),
  flightsCount: PropTypes.number,
  filteredFlightsCount: PropTypes.number,
  resultSetMetaData: ResultSetMetaData,
  searchPath: PropTypes.string,
  availableFilters: PropTypes.arrayOf(ResultFilter),
  availableSortTypes: PropTypes.arrayOf(SortTypeOption),
  hasAirlineAncillaries: PropTypes.bool,
  rebookFlow: PropTypes.string,
});

export const Trip = PropTypes.shape({
  id: ID,
  tripId: ID,
  bounds: PropTypes.arrayOf(BoundNew),
  travelers: PropTypes.arrayOf(TravelerNew),
  travelerPrices: PropTypes.arrayOf(TravelerPrice),
  travelerPricesWithoutPaymentDiscounts: PropTypes.arrayOf(TravelerPrice),
  brandedFares: PropTypes.arrayOf(BrandedFare),
  paymentMethodPrices: PropTypes.arrayOf(PaymentMethodPrice),
  frequentFlyerCardTypes: PropTypes.arrayOf(FrequentFlyerCardType),
  changes: PropTypes.arrayOf(TripChange),
  extraProducts: PropTypes.arrayOf(ProductNew),
  ancillaryBundles: PropTypes.arrayOf(BundleProduct),
  ancillaryPages: pageContainer,
  availableExtraProducts: PropTypes.arrayOf(ExtraProduct),
  mutuallyExclusiveProducts: PropTypes.arrayOf(MutuallyExclusive),
  availableAncillaryBundles: PropTypes.arrayOf(BundleProduct),
  selectionKey: PropTypes.string,
  fareRules: PropTypes.arrayOf(FareRule),
  includedExtraProducts: PropTypes.arrayOf(IncludedExtraProduct),
  type: TripType,
  isVI: PropTypes.bool,
  tripTags: PropTypes.arrayOf(TripTag),
  systems: PropTypes.arrayOf(PropTypes.string),
  includedCheckedBaggage: IncludedCheckedBaggage,
  checkInReference: PropTypes.string,
  tripCharacteristics: PropTypes.arrayOf(TripCharacteristics),
  voucherAmount: CurrencyAmount,
  checkedBaggageSummary: CheckedBaggageSummary,
  cabinBaggageTripSummary: CabinBaggageTripSummary,
  secondBagTripSummary: SecondBagTripSummary,
  includedCabinBaggage: IncludedCabinBaggage,
  personalItemTripSummary: PersonalItemTripSummary,
  shareableUrl: PropTypes.string,
  numberOfAncillaryPages: PropTypes.number,
  chargingCurrencyExchangeRate: ChargingCurrency,
  requestableBrandedFares: PropTypes.bool,
  dynamicBookingFlow: DynamicBookingFlow,
});

export const Cart = PropTypes.shape({
  id: ID,
  state: CartState,
  trip: Trip,
  trips: PropTypes.arrayOf(Trip),
  price: Price,
  subtotalPrice: Price,
  priceInChargeCurrency: Price,
  chargeCurrencyPriceFormat: PriceFormat,
  products: PropTypes.arrayOf(ProductNew),
  ancillaryBundles: PropTypes.arrayOf(BundleProduct),
  customerEmail: PropTypes.string,
  customerPhone: PropTypes.string,
  discountPrice: Price,
  restoreCartUrl: PropTypes.string,
});

export const OrderNew = PropTypes.shape({
  orderReferenceSerialized: PropTypes.string,
  obfuscatedOrderReference: PropTypes.string,
  oldOrderNumber: PropTypes.string,
  orderNumber: PropTypes.string,
  purchaseDate: PropTypes.string,
  hasBeenChanged: PropTypes.bool,
  trips: PropTypes.arrayOf(Trip),
  flightInformation: PropTypes.arrayOf(FlightInformation),
  consentUrl: PropTypes.string,
  totalPrice: Long,
  totalPriceIncludingAddOnOrders: Long,
  discountPrice: Price,
  priceInChargeCurrency: Price,
  chargeCurrencyPriceFormat: PriceFormat,
  isRefunded: PropTypes.bool,
  customerDetails: CustomerDetails,
  orderInformation: PropTypes.arrayOf(OrderInformation),
  transactionBreakdown: PropTypes.arrayOf(TransactionBreakdownEntry),
  addOnOrders: PropTypes.arrayOf(AddOnOrder),
  paymentProducts: PropTypes.arrayOf(PaymentProduct),
  postBookingLink: PropTypes.string,
  travelDocLink: PropTypes.string,
  refundStatus: RefundStatusInformation,
  orderState: OrderState,
  changeReason: ChangeReason,
  instalmentsTotalFee: Long,
  refundEligibility: RefundEligibility,
  isUpsellAllowed: PropTypes.bool,
  shouldAddInstallmentFeeInTotalAmount: PropTypes.bool,
});

export const OrderStatus = PropTypes.shape({
  orderNumber: PropTypes.string,
  obfuscatedOrderReference: PropTypes.string,
  state: OrderStatusState,
  customerDetails: CustomerDetails,
  isUpsellAllowed: PropTypes.bool,
  orderedAt: PropTypes.string,
  trips: PropTypes.arrayOf(Trip),
});

export const Search = PropTypes.shape({
  type: TripType,
  cabinClass: CabinClass,
  direct: PropTypes.bool,
  validWithVoucher: PropTypes.bool,
  routes: PropTypes.arrayOf(TripRoute),
  carrierCodes: PropTypes.arrayOf(PropTypes.string),
  carrierNames: PropTypes.arrayOf(PropTypes.string),
  travelers: PropTypes.arrayOf(SearchTraveler),
  flights: PropTypes.arrayOf(Trip),
  flightsCount: PropTypes.number,
  quickSortPrices: QuickSortPrices,
  resultSetMetaData: ResultSetMetaData,
  tripCampaigns: PropTypes.arrayOf(TripCampaign),
  filteredFlightsCount: PropTypes.number,
  searchPath: PropTypes.string,
  availableFilters: PropTypes.arrayOf(ResultFilter),
  availableSortTypes: PropTypes.arrayOf(SortTypeOption),
  sponsoredTrips: PropTypes.arrayOf(Trip),
  responseUsage: ResponseUsage,
});

export const SelectTripResponse = PropTypes.shape({
  success: PropTypes.bool,
  selectedTrip: Trip,
});

export const selfServiceRebooking = PropTypes.shape({
  travelers: PropTypes.arrayOf(SelfServiceRebookingTraveler),
  criteria: PropTypes.arrayOf(PropTypes.string),
  validatingCarriers: PropTypes.arrayOf(PropTypes.string),
});

export const sendPostBookingEmailToGenesysResponse = PropTypes.shape({
  response: SendEmailResponse,
});

export const MutationRoot = PropTypes.shape({
  addConsents: result,
  addDiscountCode: addDiscountCodeResponse,
  addToAddonCart: AddToAddonCartResponse,
  addToCart: AddToCartResponse,
  authenticateUser: AuthenticateUserResponse,
  bindOrderToSession: BindOrderToSessionResponse,
  cancelMakeOrder: CancelMakeOrderResponse,
  completeStep: PropTypes.bool,
  continueAddonOrderWithCardPayment: AddonPaymentResponse,
  continueAddonOrderWithWalletPayment: AddonPaymentResponse,
  emptyAddOnCart: EmptyAddOnCart,
  makeAddonOrderWithCardPayment: AddonPaymentResponse,
  makeFreeAddonOrder: AddonPaymentResponse,
  makeOrderWithBankPayment: PaymentResponse,
  makeOrderWithCardPayment: PaymentResponse,
  makeOrderWithPayInternalPayment: PaymentResponse,
  makeOrderWithWalletPayment: PaymentResponse,
  removeDiscounts: removeDiscountsResponse,
  removeOrderFromSession: RemoveOrderFromSessionResponse,
  verifyEmailChallenge: VerifyEmailChallengeResponse,
  sendChallengeEmail: SendChallengeEmailResponse,
  logoutUser: LogoutUserResponse,
  selectLanguage: SelectLanguageResponse,
  selectTrip: SelectTripResponse,
  storeStatistics: PropTypes.bool,
  updateSelectedTripBeforeReservation: UpdateSelectedTripBeforeReservationResponse,
  rejectPriceChange: RejectPriceChangeResponse,
  cancelOrder: CancelOrderResponse,
  cancelOrderWithRefundOffer: CancelOrderWithRefundOfferResponse,
  cancellationOptions: CancellationOptions,
  sendPostBookingEmailToGenesysV2: sendPostBookingEmailToGenesysResponse,
  rebookEligibility: RebookEligibilityResponse,
  rebookEligibilityPerPnr: RebookEligibilityPerPnrResponse,
});

export const traveler = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
});

export const CartProduct = PropTypes.shape({
  id: ID,
  name: PropTypes.string,
  productSummaryAlternativeName: PropTypes.string,
  price: Price,
  text: PropTypes.string,
  travelers: PropTypes.arrayOf(traveler),
});

export const PaymentLinkCart = PropTypes.shape({
  id: ID,
  cartType: CartType,
  products: PropTypes.arrayOf(CartProduct),
  price: Price,
  chargeCurrencyPriceFormat: PriceFormat,
  priceInChargeCurrency: Price,
});

export const QueryRoot = PropTypes.shape({
  ancillaryBundle: PropTypes.arrayOf(BundleProduct),
  ancillaryItem: ExtraProduct,
  ancillaryItems: PropTypes.arrayOf(ExtraProduct),
  brandInformation: BrandInformationResponse,
  cart: Cart,
  checkInStatus: CheckInStatus,
  consents: PropTypes.arrayOf(consent),
  contactUsDetails: ContactUsDetails,
  countries: PropTypes.arrayOf(Country),
  instalmentOptions: InstalmentOptions,
  paymentMethodIssuerOptions: PropTypes.arrayOf(PaymentMethodIssuer),
  invoicePdfV2: InvoicePdf,
  links: Links,
  localization: Localization,
  notifications: PropTypes.arrayOf(Notification),
  orderNew: OrderNew,
  orderList: PropTypes.arrayOf(OrderInfo),
  paymentData: PaymentData,
  paymentLinkCart: PaymentLinkCart,
  receiptPdf: ReceiptPdf,
  rebookingSearch: RebookingSearch,
  rebook: Rebook,
  reissue: Reissue,
  search: Search,
  searchLocations: PropTypes.arrayOf(SearchLocation),
  searchParameters: SearchParameters,
  selectedTrip: Trip,
  selfServiceRebooking: selfServiceRebooking,
  seoData: SeoData,
  siteContext: SiteContext,
  siteInformation: SiteInformation,
  travelerDetails: TravelerDetails,
  validateEmail: EmailValidationResponse,
  validatePhoneNumber: PhoneNumberValidationResponse,
  verifyUser: User,
  notificationMessage: NotificationMessage,
  pendingProviderBooking: pendingProviderBooking,
  paymentStatus: PaymentStatusResponse,
  bestPricesPerPeriod: BestPricesPerPeriod,
  travelDocsInfo: TravelDocsInfo,
  AncillaryPasses: AncillaryPasses,
  bestPricesPerDay: BestPricesPerDay,
  orderStatus: OrderStatus,
});

