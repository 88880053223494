import { m as motion } from 'framer-motion';
import PropTypes from 'prop-types';
import { CaretDown } from '@phosphor-icons/react';
import { css } from '@eti/styles';
import { Heading, Inline } from '@etg/wings';
import { useToggle } from '../../common/custom-hooks/toggle';

const motionVariants = {
  show: {
    height: 'auto',
    transitionEnd: { overflow: 'visible' },
  },
  hide: {
    overflow: 'hidden',
    height: 0,
  },
};

const iconStyles = (isOpen) => css`
  transform: rotate(${isOpen ? '180deg' : '0deg'});
`;

const Expand = ({
  children,
  classNames = {},
  dataTestId,
  isInitiallyExpanded = false,
  isToggleable = true,
  title,
}) => {
  const { isOpen, handleToggle } = useToggle(isInitiallyExpanded);

  return (
    <>
      <Inline
        className={classNames.heading}
        data-testid={`expandHeader-${dataTestId}`}
        onClick={handleToggle}
      >
        <Heading level={4}>{title}</Heading>
        <CaretDown
          className={iconStyles(isOpen)}
          data-testid="expandHeader-Icon"
          size={22}
          weight="light"
        />
      </Inline>
      <motion.div
        animate={!isToggleable || isOpen ? 'show' : 'hide'}
        className={classNames.content}
        data-testid={`expandContent-${dataTestId}`}
        initial={false}
        transition={{ type: 'tween', duration: 0.3 }}
        variants={motionVariants}
      >
        {children}
      </motion.div>
    </>
  );
};

Expand.propTypes = {
  children: PropTypes.node,
  classNames: PropTypes.shape({
    content: PropTypes.string,
    heading: PropTypes.string,
  }),
  dataTestId: PropTypes.string,
  isInitiallyExpanded: PropTypes.bool,
  isToggleable: PropTypes.bool,
  title: PropTypes.string,
};

export default Expand;
