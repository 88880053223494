export const getTravelersPrimaryFlow = order => {
  var _order$sellSpecificat, _order$travelers;
  const baggageSpecification = ((_order$sellSpecificat = order.sellSpecification) === null || _order$sellSpecificat === void 0 ? void 0 : _order$sellSpecificat.sellPriceTravelers) || [];
  return order === null || order === void 0 || (_order$travelers = order.travelers) === null || _order$travelers === void 0 ? void 0 : _order$travelers.map((traveler, index) => {
    var _order$persuasion, _price$markup$value, _price$markup, _price$price;
    const isPersuasionEnabled = index === 0 && (order === null || order === void 0 || (_order$persuasion = order.persuasion) === null || _order$persuasion === void 0 ? void 0 : _order$persuasion.isEnabled);
    const {
      price
    } = (baggageSpecification === null || baggageSpecification === void 0 ? void 0 : baggageSpecification.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      id: (traveler === null || traveler === void 0 ? void 0 : traveler.id) || '',
      persuasionEnabled: isPersuasionEnabled,
      productPreSelection: order.productPreSelection,
      markup: (_price$markup$value = price === null || price === void 0 || (_price$markup = price.markup) === null || _price$markup === void 0 ? void 0 : _price$markup.value) !== null && _price$markup$value !== void 0 ? _price$markup$value : null,
      price: price === null || price === void 0 || (_price$price = price.price) === null || _price$price === void 0 ? void 0 : _price$price.value
    };
  });
};
export const getTravelersPostbooking = (productId, order) => {
  var _order$availableExtra, _baggageProduct$sellS, _baggageProduct$sellS2, _order$travelers2;
  const baggageProduct = order === null || order === void 0 || (_order$availableExtra = order.availableExtraProducts) === null || _order$availableExtra === void 0 ? void 0 : _order$availableExtra.find(product => String(product === null || product === void 0 ? void 0 : product.id) === String(productId));
  const baggageSpecifications = (_baggageProduct$sellS = baggageProduct === null || baggageProduct === void 0 || (_baggageProduct$sellS2 = baggageProduct.sellSpecification) === null || _baggageProduct$sellS2 === void 0 ? void 0 : _baggageProduct$sellS2.sellPriceTravelers) !== null && _baggageProduct$sellS !== void 0 ? _baggageProduct$sellS : [];
  return order === null || order === void 0 || (_order$travelers2 = order.travelers) === null || _order$travelers2 === void 0 ? void 0 : _order$travelers2.map(traveler => {
    var _price$markup$value2, _price$markup2, _price$price2;
    const {
      price
    } = (baggageSpecifications === null || baggageSpecifications === void 0 ? void 0 : baggageSpecifications.find(currTraveler => String(currTraveler === null || currTraveler === void 0 ? void 0 : currTraveler.travelerId) === String(traveler === null || traveler === void 0 ? void 0 : traveler.id))) || {};
    return {
      ...traveler,
      name: (traveler === null || traveler === void 0 ? void 0 : traveler.fullName) || '',
      markup: (_price$markup$value2 = price === null || price === void 0 || (_price$markup2 = price.markup) === null || _price$markup2 === void 0 ? void 0 : _price$markup2.value) !== null && _price$markup$value2 !== void 0 ? _price$markup$value2 : null,
      price: price === null || price === void 0 || (_price$price2 = price.price) === null || _price$price2 === void 0 ? void 0 : _price$price2.value
    };
  });
};
export const baggageInformation = _ref => {
  var _sellSpecification$si, _ref2, _ref3, _ref4;
  let {
    includedBaggage,
    sellSpecification
  } = _ref;
  const {
    pieces: includedPiecesInTrip,
    weight: includedWeightInTrip,
    weightUnit: includedWeightUnitInTrip
  } = includedBaggage !== null && includedBaggage !== void 0 ? includedBaggage : {};
  const {
    pieces: piecesInTrip,
    weight: weightInTrip,
    weightUnit: weightUnitInTrip
  } = sellSpecification !== null && sellSpecification !== void 0 ? sellSpecification : {};
  const baggageDimensions = (_sellSpecification$si = sellSpecification === null || sellSpecification === void 0 ? void 0 : sellSpecification.size3d) !== null && _sellSpecification$si !== void 0 ? _sellSpecification$si : undefined;
  return {
    baggageDimensions,
    pieces: (_ref2 = includedPiecesInTrip || piecesInTrip) !== null && _ref2 !== void 0 ? _ref2 : 0,
    weight: (_ref3 = includedWeightInTrip || weightInTrip) !== null && _ref3 !== void 0 ? _ref3 : 0,
    weightUnit: (_ref4 = includedWeightUnitInTrip || weightUnitInTrip) !== null && _ref4 !== void 0 ? _ref4 : ''
  };
};