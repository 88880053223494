import { usePrevious } from '@eti/utils';
import { useEffect, useMemo, useState } from 'react';
import Container from './SimpleVisaContainer';
export const useSimpleVisaPrice = (updateTotalPrice, priceRaw) => {
  const previousPrice = usePrevious(priceRaw);
  useEffect(() => {
    if (!(priceRaw === null && previousPrice === undefined) && priceRaw !== previousPrice) {
      updateTotalPrice(priceRaw);
    }
  }, [previousPrice, priceRaw, updateTotalPrice]);
};
export const useSimpleVisaCardSelection = (hasAnySelected, cardSelection, selectProduct, selectAllTravelers, travelers) => {
  const [isOpen, setIsOpen] = useState(false);
  const prevHasAnySelected = usePrevious(hasAnySelected);
  const previousCardSelection = usePrevious(cardSelection);
  useEffect(() => {
    if (!hasAnySelected && previousCardSelection && prevHasAnySelected) {
      selectProduct(false);
    }
    if (hasAnySelected && !prevHasAnySelected) {
      selectProduct(true);
    }
  }, [hasAnySelected, selectProduct, prevHasAnySelected, previousCardSelection]);
  useEffect(() => {
    if (cardSelection && previousCardSelection !== cardSelection && !prevHasAnySelected) {
      setIsOpen(true);
      selectAllTravelers(travelers, true);
    }
    if (cardSelection === false && cardSelection !== previousCardSelection) {
      selectAllTravelers(travelers, false);
    }
  }, [selectAllTravelers, cardSelection, prevHasAnySelected, previousCardSelection, travelers]);
  return [isOpen];
};
export const useConnectedSimpleVisa = Component => useMemo(() => {
  return Container(Component);
}, [Component]);