import { CABIN_BAGGAGE_PER_TRAVELER } from '../products';
import { getMinPrice, getMarkup } from '../common/utils/baggage/price';
import { getSelectedForTraveler, getSelected } from '../common/utils/baggage/units';
import { getCabinBaggagePrice } from './utils/price';
import { getNumberOfUnitsCabinBaggage } from './utils/units';
import getIcon from './utils/icon';
import { getGraphQlSelection } from './utils/getGraphQlSelection';
import CabinBaggagePerTraveler from './containers/CabinBaggagePerTravelerContainer';
const {
  id,
  name: formSection
} = CABIN_BAGGAGE_PER_TRAVELER;
export const cabinBaggagePerTraveler = {
  formSection,
  getGraphQlSelection: getGraphQlSelection(String(id), formSection),
  getIcon,
  getMarkup: getMarkup.bind(null, formSection),
  getMinPrice,
  getNumberOfUnits: getNumberOfUnitsCabinBaggage.bind(null, formSection),
  getPrice: getCabinBaggagePrice.bind(null, formSection),
  getSelected: getSelected.bind(null, formSection),
  getSelectedForTraveler: getSelectedForTraveler.bind(null, formSection),
  ...CABIN_BAGGAGE_PER_TRAVELER
};
export default CabinBaggagePerTraveler;