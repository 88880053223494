import difference from 'lodash/difference';
export const findIndexOfSegment = (segments, boundSegmentIndex) => segments.findIndex(b => b.boundSegmentIndex === boundSegmentIndex);
export const findNextAvailableSegment = function (segments, activeSegmentIndex) {
  if (segments === void 0) {
    segments = [];
  }
  const activeIndex = findIndexOfSegment(segments, activeSegmentIndex);
  const succeedingSegments = segments.slice(activeIndex + 1);
  const [nextSegment] = succeedingSegments;
  return nextSegment;
};
export const findPreviousAvailableSegment = function (segments, activeSegmentIndex) {
  if (segments === void 0) {
    segments = [];
  }
  const activeIndex = findIndexOfSegment(segments, activeSegmentIndex);
  const precedingSegments = segments.slice(0, activeIndex);
  const [previousSegment] = precedingSegments.reverse();
  return previousSegment;
};
export const isFirstAvailableSegment = (segments, boundSegmentIndex) => {
  const first = segments[0];
  return first.boundSegmentIndex === boundSegmentIndex;
};
export const isLastAvailableSegment = (segments, boundSegmentIndex) => {
  const last = segments[segments.length - 1];
  return last.boundSegmentIndex === boundSegmentIndex;
};
export const getBestPriceCharacteristics = function (segments, activeBoundSegmentIndex) {
  if (segments === void 0) {
    segments = [];
  }
  const hasSegments = segments.some(_ref => {
    let {
      segmentIndex
    } = _ref;
    return Number.isInteger(segmentIndex);
  });
  if (!hasSegments) {
    return [];
  }
  const segment = segments.find(_ref2 => {
    let {
      boundSegmentIndex
    } = _ref2;
    return boundSegmentIndex === activeBoundSegmentIndex;
  });
  return segment === null || segment === void 0 ? void 0 : segment.bestPriceCharacteristics;
};
export const getSegment = function (segments, activeSegment) {
  if (segments === void 0) {
    segments = [];
  }
  const hasSegments = segments.some(_ref3 => {
    let {
      segmentIndex
    } = _ref3;
    return Number.isInteger(segmentIndex);
  });
  if (!hasSegments) {
    return null;
  }
  return segments.find(_ref4 => {
    let {
      boundSegmentIndex
    } = _ref4;
    return boundSegmentIndex === activeSegment;
  });
};
export const getNonSelectedAvailableSegments = function (segments, totalSelectedSeats) {
  if (segments === void 0) {
    segments = [];
  }
  if (!totalSelectedSeats) {
    return [];
  }
  return segments.reduce((acc, val) => {
    if (val.isAvailable) {
      var _val$travelerIds;
      const currentSelectedSegment = totalSelectedSeats.find(selectedSeatsBound => selectedSeatsBound.boundSegmentIndex === val.boundSegmentIndex);
      const mapCurrentSelectedSegment = (currentSelectedSegment === null || currentSelectedSegment === void 0 ? void 0 : currentSelectedSegment.travelerIds) || [];
      const travelerIds = (_val$travelerIds = val.travelerIds) === null || _val$travelerIds === void 0 ? void 0 : _val$travelerIds.filter(travelerId => travelerId !== null).map(Number);
      const areAllSeatsSelected = currentSelectedSegment ? difference(travelerIds, mapCurrentSelectedSegment).length === 0 : false;
      return [...acc, ...(!areAllSeatsSelected ? [val] : [])];
    }
    return acc;
  }, []);
};